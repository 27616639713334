import React from 'react'
import {Link} from "react-router-dom"

function FloatingButton() {
  return (
    <Link to="/agregar" className='btn-small flotante'><span>Plublicar</span></Link>
  )
}

export default FloatingButton
