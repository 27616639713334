import React from 'react'
import "./bannerAgenciaTienda.css"


function BannerAgenciaTienda({imgAgencia}) {
    return (
        <div className='container-img-profil-agencia'>
            <div className='container-img-profil-agencia-resp'>
                <img src={imgAgencia}/>
            </div>
        </div>
    )
}

export default BannerAgenciaTienda