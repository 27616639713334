import Auth from '../contexts/Auth'
import React , {useContext} from 'react'
import  { Navigate, Redirect ,Route} from 'react-router-dom'
import AppContext from '../services/contextApp'

function Protected({ isAlloweb, children }) {
  const { validar_user } = useContext(AppContext);

  if (!validar_user) {
    return <Navigate to="/iniciar" replace />
  }
  return children
}

export default Protected