import React, { useContext, useEffect, useState } from 'react'
import M from 'materialize-css'
import AppContext from '../../services/contextApp'
import { MoonLoader } from 'react-spinners'
import "./modalEditProduct.css"
import axios from 'axios'
import { SERVER_URL } from '../../contexts/constantesVar'

function EditarProducto({ data }) {
    const { Logins, loginSpinner, respServerUserUndefined, user_id, user_name } = useContext(AppContext)
    const [categoria, setCategoria] = useState("")
    const [subCateg, setSubCateg] = useState("")
    const [subCategTele, setSubCategTele] = useState("")
    const [subCategElectro, setSubCategElectro] = useState("")
    const [subCategCasa, setSubCategCasa] = useState("")
    const [subSubCateg, setSubSubCateg] = useState("")
    const [nombreDelArticulo, setNombreDelArticulo] = useState("")
    const [breveDescripcion, setBreveDescripcion] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [pais, setPais] = useState("")
    const [ciudad, setCiudad] = useState("")
    const [localizacion, setLocalizacion] = useState("")
    const [numeroW, setNumeroW] = useState("")
    const [numeroT, setNumeroT] = useState("")
    const [precio, setPrecio] = useState("")
    const [valid, setValid] = useState(true)
    const [validSub, setValidSub] = useState(true)
    const [precioString, setPrecioString] = useState("")
    const [imagen1, setImagen1] = useState('')
    const [product, setProduct] = useState({})
    const [imagen2, setImagen2] = useState('')
    const [imagen3, setImagen3] = useState('')
    const [imagen4, setImagen4] = useState('')
    const [imagenUpload, setImagenUpload] = useState(false)
    const [preview, setPreview] = useState("");
    const [preview1, setPreview1] = useState("");
    const [preview2, setPreview2] = useState("");
    const [preview3, setPreview3] = useState("");
    const [addData, setVal] = useState('')
    const [probar, setProbar] = useState(false)




    const WIDTH = 500

    //obteniendo imagenes en cada input file del formulario
    const handleChangeImagen1 = e => {
        console.log(e)

        try {
            if (e[0]) {
                setImagenUpload(true)
                const jpG = e[0].name.split(".")[e[0].name.split(".").length - 1] == "jpg"
                const pnG = e[0].name.split(".")[e[0].name.split(".").length - 1] == "png"
                const jpeG = e[0].name.split(".")[e[0].name.split(".").length - 1] == "jpeg"

                if (jpG || pnG || jpeG) {
                    const reader = new FileReader()
                    reader.readAsDataURL(e[0])

                    reader.onload = (event) => {
                        let img_url = event.target.result
                        //console.log(img_url)
                        let image = document.createElement("img")
                        image.src = img_url
                        image.onload = async (e) => {
                            //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                            let canvas = document.createElement("canvas")
                            let ratio = WIDTH / e.target.width
                            canvas.width = WIDTH
                            canvas.height = e.target.height * ratio
                            //crear objeto canvas 
                            const context = canvas.getContext("2d")
                            context.drawImage(image, 0, 0, canvas.width, canvas.height)
                            let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                            //console.log(new_img_url)


                            //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                            const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                            const img_convert_to_blob = await img_fetch.blob("image/png")

                            setImagen1(img_convert_to_blob)
                            //console.log(img_convert_to_blob)

                            CambiarImagenesProduct(img_convert_to_blob)

                        }
                    }
                } else {
                    setImagen1(null)
                }
            } else {
                setImagen1(null)

            }
            if (e[1]) {
                const jpG = e[1].name.split(".")[e[1].name.split(".").length - 1] == "jpg"
                const pnG = e[1].name.split(".")[e[1].name.split(".").length - 1] == "png"
                const jpeG = e[1].name.split(".")[e[1].name.split(".").length - 1] == "jpeg"

                if (jpG || pnG || jpeG) {
                    const reader = new FileReader()
                    reader.readAsDataURL(e[1])

                    reader.onload = (event) => {
                        let img_url = event.target.result
                        //console.log(img_url)
                        let image = document.createElement("img")
                        image.src = img_url
                        image.onload = async (e) => {
                            //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                            let canvas = document.createElement("canvas")
                            let ratio = WIDTH / e.target.width
                            canvas.width = WIDTH
                            canvas.height = e.target.height * ratio
                            //crear objeto canvas 
                            const context = canvas.getContext("2d")
                            context.drawImage(image, 0, 0, canvas.width, canvas.height)
                            let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                            //console.log(new_img_url)


                            //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                            const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                            const img_convert_to_blob = await img_fetch.blob("image/png")

                            setImagen2(img_convert_to_blob)
                            //console.log(img_convert_to_blob)
                            CambiarImagenesProduct1(img_convert_to_blob)


                        }
                    }
                } else {
                    setImagen2(null)
                }
            } else {
                setImagen2(null)
            }
            if (e[2]) {
                //OPTIMIZACION DEL IMAGEN3
                const jpG = e[2].name.split(".")[e[2].name.split(".").length - 1] == "jpg"
                const pnG = e[2].name.split(".")[e[2].name.split(".").length - 1] == "png"
                const jpeG = e[2].name.split(".")[e[2].name.split(".").length - 1] == "jpeg"

                if (jpG || pnG || jpeG) {
                    const reader = new FileReader()
                    reader.readAsDataURL(e[2])

                    reader.onload = (event) => {
                        let img_url = event.target.result
                        //console.log(img_url)
                        let image = document.createElement("img")
                        image.src = img_url
                        image.onload = async (e) => {
                            //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                            let canvas = document.createElement("canvas")
                            let ratio = WIDTH / e.target.width
                            canvas.width = WIDTH
                            canvas.height = e.target.height * ratio
                            //crear objeto canvas 
                            const context = canvas.getContext("2d")
                            context.drawImage(image, 0, 0, canvas.width, canvas.height)
                            let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                            //console.log(new_img_url)


                            //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                            const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                            const img_convert_to_blob = await img_fetch.blob("image/png")

                            setImagen3(img_convert_to_blob)
                            //console.log(img_convert_to_blob)
                            CambiarImagenesProduct2(img_convert_to_blob)


                        }
                    }
                } else {
                    setImagen3(null)
                }
            } else {
                setImagen3(null)
            }
            if (e[3]) {
                //OPTIMIZACION DEL IMAGEN4
                const jpG = e[3].name.split(".")[e[3].name.split(".").length - 1] == "jpg"
                const pnG = e[3].name.split(".")[e[3].name.split(".").length - 1] == "png"
                const jpeG = e[3].name.split(".")[e[3].name.split(".").length - 1] == "jpeg"

                if (jpG || pnG || jpeG) {
                    const reader = new FileReader()
                    reader.readAsDataURL(e[3])

                    reader.onload = (event) => {
                        let img_url = event.target.result
                        //console.log(img_url)
                        let image = document.createElement("img")
                        image.src = img_url
                        image.onload = async (e) => {
                            //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                            let canvas = document.createElement("canvas")
                            let ratio = WIDTH / e.target.width
                            canvas.width = WIDTH
                            canvas.height = e.target.height * ratio
                            //crear objeto canvas 
                            const context = canvas.getContext("2d")
                            context.drawImage(image, 0, 0, canvas.width, canvas.height)
                            let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                            //console.log(new_img_url)


                            //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                            const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                            const img_convert_to_blob = await img_fetch.blob("image/png")

                            setImagen4(img_convert_to_blob)
                            //console.log(img_convert_to_blob)
                            CambiarImagenesProduct3(img_convert_to_blob)

                        }
                    }
                } else {
                    setImagen4(null)
                }
            } else {
                setImagen4(null)
            }
        } catch (error) {
            console.log(error)
        }


        //OPTIMIZACION DEL IMAGEN1


    }

    const CambiarImagenesProduct = (imagen) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreview(reader.result);

        }
        reader.readAsDataURL(imagen)
    }
    const CambiarImagenesProduct1 = (imagen) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreview1(reader.result);

        }
        reader.readAsDataURL(imagen)
    }
    const CambiarImagenesProduct2 = (imagen) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreview2(reader.result);

        }
        reader.readAsDataURL(imagen)
    }
    const CambiarImagenesProduct3 = (imagen) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreview3(reader.result);

        }
        reader.readAsDataURL(imagen)
    }

    //funcion para enviar los datos en el api y asi guardarlos
    const GuardarProducto = async e => {
        e.preventDefault();
        console.log("user", user_name)
        console.log("user_id", user_id)
        console.log("categoria", categoria ? categoria : data.categoria)
        console.log("subcategoria", subCateg ? subCateg : data.subcategoria)
        console.log("subsubcategoria", subSubCateg ? subSubCateg : data.subsubcategoria)
        console.log("descripcion", descripcion ? descripcion : data.brevedescripcion)
        console.log("precio", precio ? precio : data.precio)
        console.log("paiz", pais ? pais : data.paiz)
        console.log("ciudad", ciudad ? ciudad : data.ciudad)
        console.log("nombreOMarca", nombreDelArticulo ? nombreDelArticulo : data.nombre)
        console.log("localizacion", localizacion ? localizacion : data.localizacion)
        console.log("numeroT", numeroT ? numeroT : data.llamar)
        console.log("precioString", precioString ? precioString : data.precio)
        console.log("numeroW", numeroW ? numeroW : data.mensage)
        console.log("breveDescripcion", breveDescripcion ? breveDescripcion : data.brevedescripcion)
        console.log("imagen1", imagen1 ? imagen1 : data.imagen1)
        console.log("imagen2", imagen2 ? imagen2 : data.imagen2)
        console.log("imagen3", imagen3 ? imagen3 : data.imagen3)
        console.log("imagen4", imagen4 ? imagen4 : data.imagen4)
 
        setProbar(true)
        //console.log(categoria, subCateg, subSubCateg)
        const fs = new FormData()
        fs.append("user", user_name)
        fs.append("user_id", user_id)
        fs.append("id_product", data._id)
        fs.append("categoria", categoria ? categoria : data.categoria)
        fs.append("subcategoria", subCateg ? subCateg : data.subcategoria)
        fs.append("subsubcategoria", subSubCateg ? subSubCateg : data.subsubcategoria)
        fs.append("descripcion", descripcion ? descripcion : data.brevedescripcion)
        fs.append("precio", precio ? precio : data.precio)
        fs.append("paiz", pais ? pais : data.paiz)
        fs.append("ciudad", ciudad ? ciudad : data.ciudad)
        fs.append("nombreOMarca", nombreDelArticulo ? nombreDelArticulo : data.nombre)
        fs.append("localizacion", localizacion ? localizacion : data.localizacion)
        fs.append("numeroT", numeroT ? numeroT : data.llamar)
        fs.append("precioString", precioString ? precioString : data.precio)
        fs.append("numeroW", numeroW ? numeroW : data.mensage)
        fs.append("breveDescripcion", breveDescripcion ? breveDescripcion : data.breveDescripcion)
        fs.append("imagen1", imagen1 ? imagen1 : data.imagen1)
        fs.append("imagen2", imagen2 ? imagen2 : data.imagen2)
        fs.append("imagen3", imagen3 ? imagen3 : data.imagen3)
        fs.append("imagen4", imagen4 ? imagen4 : data.imagen4)
        //console.log(fs)

        
        try {
            const respuesta = await axios.post(`${SERVER_URL}/edit_product`, fs, { headers: { "Content-Type": "multipart/form-data" } })
            if (respuesta.data === "producto publicado") {
                setProbar(false)
                var toastHTML = '<span>' + respuesta.data + '</span>';
                M.toast({ html: toastHTML });
 
 
                // RESETEAR FORMULARIO 
                setPreview(null)
                setImagen1(null)
                Limpiarfotos()
 
 
                setCategoria("");
                setSubCateg("");
                setSubSubCateg("")
                setPais("");
                setCiudad("");
                setLocalizacion("");
                setNombreDelArticulo("");
                setBreveDescripcion("");
                setPrecio("");
                setNumeroW("");
                setNumeroT("");
 
 
                document.getElementById("cat1").selectedIndex = 0
                document.getElementById("cat1").style.borderColor = "red"
 
                document.getElementById("pais").selectedIndex = 0
                document.getElementById("pais").style.borderColor = "red"
 
 
                document.getElementById("input1").value = ""
                document.getElementById("input1").style.borderColor = "red"
 
                document.getElementById("input2").value = ""
                document.getElementById("input2").style.borderColor = "red"
 
 
                document.getElementById("precio").value = ""
                document.getElementById("precio").style.borderColor = "red"
 
                document.getElementById("descripcion").value = ""
                document.getElementById("descripcion").style.borderColor = "red"
 
                document.getElementById("nombre").value = ""
                document.getElementById("nombre").style.borderColor = "red"
 
                document.getElementById("localizacion").value = ""
                document.getElementById("localizacion").style.borderColor = "red"
 
 
                //FIN DE  RESETEAR FORMULARIO 
 
 
 
 
 
            } else {
                setProbar(false)
                var toastHTML = '<span>' + respuesta.data + '</span>';
                M.toast({ html: toastHTML });
            }
        } catch {
            setProbar(false)
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
        }
        






    }

    const Limpiarfotos = () => {
        setPreview1(""); setImagen2("")
        setPreview2(""); setImagen3("")
        setPreview3(""); setImagen4("")

    }

    //FUNCION PARA HACER APARECER EL BOTUN QUE PERMITE ENVIAR LOS DATOS A LA API PARA ASI AGREGAR PRODUCTO

    const ValidarFormulario = () => {
        var validarForm = ""
        const precioValidar = (precio || precioString)
        if (subSubCateg) {
            validarForm = breveDescripcion && imagen1 && categoria && numeroW && nombreDelArticulo && precioValidar && subCateg && subSubCateg;
        } else {
            validarForm = breveDescripcion && imagen1 && categoria && numeroW && nombreDelArticulo && precioValidar && subCateg;
        }
        return validarForm
    }















    useEffect(() => {
        const alls = document.querySelector(".modal-edite-product")
        M.Modal.init(alls, {
            preventScrolling: false,
            inDuration: 50,
            outDuration: 50,
            onCloseStart: () => {
                setCategoria('')
                setCiudad('')
                setBreveDescripcion('')
                setDescripcion("")
                setNumeroT('')
                setNumeroW('')
                setPais('')
                setImagen4('')
                setImagen1('')
                setImagen2('')
                setImagen3('')
                setPreview('')
                setPreview1('')
                setPreview2('')
                setPreview3('')
                setLocalizacion('')
                setPrecio('')
                setSubCateg('')
                setSubSubCateg('')
                setValidSub(true)
                setValid(true)
                setImagenUpload(false)


            },
            onOpenStart: () => {
                console.log(data)

            }
        })
        setProduct(data)

        //contar carracteres en el input
        const textareas = document.querySelector("textarea.textareaCount")
        M.CharacterCounter.init(textareas, {})

        const input = document.querySelector("input#input1 , input#input2")
        M.CharacterCounter.init(input, {})

    }, [/*imagen1, imagen2, imagen3, imagen4, categoria, subCategTele*/])
    return (
        <div className='modal-edite-product-container-global'>
            <div id='modal-edite-product' className="modal modal-edite-product" style={{ width: "50%" }} >
                {data ?
                    <form className="formulario-agregar-form z-depth-1 " onSubmit={GuardarProducto}  >

                        <div className="input-field contenedor-input  col s12">
                            <select value={categoria ? categoria : data.categoria} className="browser-default" id="cat1" onChange={(e) => {

                                if (e.target.value) {
                                    setCategoria(e.target.value);
                                    setSubCateg("");
                                    setSubSubCateg("");
                                    setValid(false)
                                } else {
                                }

                            }} >
                                <option disabled selected>Elige la categoria</option>
                                <option value="1" >Electronica</option>
                                <option value="2">Material de telecomunicacion</option>
                                <option value="3" >Casas</option>
                                <option value="4" >Servicios</option>
                                <option value="5" >Coches</option>
                                <option value="6" >Vestimenta</option>
                                <option value="8" >Hogar</option>
                                <option value="7" >Terrenos</option>
                            </select>
                        </div>
                        {categoria == 1 || (Number(data.categoria) == 1) && valid ?
                            <div>
                                <div className="input-field contenedor-input">
                                    <select value={subCateg ? subCateg : data.subcategoria} className="browser-default subcat" id="cat11" onChange={(e) => {

                                        if (e.target.value) {
                                            setSubCateg(e.target.value);
                                        } else {
                                            setSubCateg("");
                                        }

                                    }} >
                                        <option disabled selected>Elige la subcategorisa</option>
                                        <option value="11" >Telefonos</option>
                                        <option value="12" >Televisiones</option>
                                        <option value="13" >Ordenadores</option>
                                        <option value="14" >Material informatico</option>
                                        <option value="15" >Videojuegos</option>
                                        <option value="16" >Electrodomesticos</option>
                                        <option value="17" >Arduino</option>
                                        <option value="18" >Equipos de audiovision</option>
                                        <option value="19" >Otro</option>

                                    </select>
                                </div>


                            </div>
                            : ""
                        }
                        {categoria == 2 || (Number(data.categoria) == 2) && valid ?
                            <div className="input-field contenedor-input subcat2  " >
                                <select value={subCateg ? subCateg : data.subcategoria} className="browser-default" id="cat2" onChange={(e) => {
                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                    } else {
                                        setSubCateg("");
                                    }

                                }
                                } >
                                    <option disabled selected>Elige tu material de telecomunicacion</option>
                                    <option value="21" >conectores rg45</option>
                                    <option value="22" >cable ethernet</option>
                                    <option value="23" >router</option>
                                    <option value="24" >antena</option>
                                    <option value="25" >modem</option>
                                    <option value="26" >switch</option>
                                    <option value="27" >otro</option>
                                </select>
                            </div>
                            : ""
                        }

                        {categoria == 8 || (Number(data.categoria) == 8) && valid ?
                            <div className="input-field contenedor-input subcat2  " >
                                <select value={subCateg ? subCateg : data.subcategoria} className="browser-default" id="cat8" onChange={(e) => {
                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                    } else {
                                        setSubCateg("");
                                    }

                                }
                                } >
                                    <option disabled selected>Elige una subcategoria</option>
                                    <option value="81" >material de construccion</option>
                                    <option value="82" >material para decorrar el hogar</option>
                                    <option value="83" >otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        {categoria == 3 || (Number(data.categoria) == 3) && valid ?
                            <>
                                <div className="input-field contenedor-input  subcat ">
                                    <select value={subCateg ? subCateg : data.subcategoria} className="browser-default" id="cat3" onChange={(e) => {

                                        if (e.target.value) {
                                            setSubCateg(e.target.value);
                                            setValidSub(false)
                                            setSubCategCasa(e.target.value)
                                            setSubSubCateg("");
                                        } else {
                                        }
                                    }} >
                                        <option disabled selected>Elige la subcategoria de tu casa</option>
                                        <option value="31" >Casas en alquiler</option>
                                        <option value="32" >Casas en venta</option>
                                        <option value="33" >Todo</option>
                                    </select>
                                </div>

                                {subCateg == "31" || (data.subcategoria == '31') && validSub ?
                                    <div className="input-field contenedor-input ">
                                        <select value={subSubCateg ? subSubCateg : data.subsubcategoria} className="browser-default" id="cat31" onChange={(e) => {
                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                            } else {
                                                setSubSubCateg("");

                                            }
                                        }} >
                                            <option disabled selected>Elige el numero de habitaciones</option>
                                            <option value="311" >una habitacion</option>
                                            <option value="312" >dos habitaciones</option>
                                            <option value="313" >tres habitaciones</option>
                                            <option value="314" >cuatro habitaciones</option>
                                            <option value="315" >cinco habitaciones</option>
                                            <option value="316" >mas de cinco habitaciones</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                                {subCateg == "32" || (data.subcategoria == '32') && validSub ?
                                    <div className="input-field contenedor-input  ">
                                        <select value={subSubCateg ? subSubCateg : data.subsubcategoria} className="browser-default" id="cat32" onChange={(e) => {
                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                            } else {
                                                setSubSubCateg("");
                                            }
                                        }} >
                                            <option disabled selected>Elige el numero de habitaciones</option>
                                            <option value="321" >una habitacion</option>
                                            <option value="322" >dos habitaciones</option>
                                            <option value="323" >tres habitaciones</option>
                                            <option value="324" >cuatro habitaciones</option>
                                            <option value="325" >cinco habitaciones</option>
                                            <option value="326" >mas de cinco habitaciones</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                            </>
                            : ""
                        }

                        {categoria == 4 || (Number(data.categoria) == 4) && valid ?
                            <div className="input-field contenedor-input subcat ">
                                <select value={subCateg ? subCateg : data.subcategoria} className="browser-default" id="cat4" onChange={(e) => {

                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                    } else {
                                        setSubCateg("");
                                    }
                                }} >
                                    <option disabled selected>Elige la subcategoria de tu servicio</option>
                                    <option value="41" >Clases particulares</option>
                                    <option value="42" >servicios de construccion</option>
                                    <option value="43" >servicios informaticos</option>
                                    <option value="44" >servicios de publicidad</option>
                                    <option value="45" >servicios en el area de sanidad</option>
                                    <option value="46" >Buscar empleo</option>
                                    <option value="47" >Ofrecer empleo</option>
                                    <option value="48" >servicios de limpiesa en hogar</option>
                                    <option value="49" >servicio de mecanico de coche</option>
                                    <option value="401" >servicios de reparacion de aparatos</option>
                                    <option value="402" >servicios de fontaneria</option>
                                    <option value="403" >servicios de coches grua</option>
                                    <option value="404" >servicios de agua a domicilio</option>
                                    <option value="405" >Servicios de lavado de coches a domicilio</option>
                                    <option value="406" >servicios de instalaciones electricas</option>
                                    <option value="407" >otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        {categoria == 5 || (Number(data.categoria) == 5) && valid ?
                            <div className="input-field  subcat ">
                                <select value={subCateg ? subCateg : data.subcategoria} className="browser-default" id="cat5" onChange={(e) => {

                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                    } else {
                                        setSubCateg("");
                                    }
                                }} >
                                    <option disabled selected>Elige la subcategoria de tu coche</option>
                                    <option value="51" >Alquilar coche</option>
                                    <option value="52" >Vender coche</option>
                                    <option value="53" >taxi en alquiler</option>
                                    <option value="54" >Otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        {categoria == 6 || (Number(data.categoria) == 6) && valid ?
                            <div className="input-field  subcat ">
                                <select value={subCateg ? subCateg : data.subcategoria} className="browser-default" id="cat6" onChange={(e) => {

                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                    } else {
                                        setSubCateg("");
                                    }
                                }} >
                                    <option disabled selected>Elige la subcategoria de tus articulos</option>
                                    <option value="61" >Zapatos para hombres</option>
                                    <option value="62" >Zapatos para mujeres</option>
                                    <option value="63" >Zapatos para niños</option>
                                    <option value="64" >Ropas para mujeres</option>
                                    <option value="65" >Ropas para hombres</option>
                                    <option value="66" >Ropas para niños</option>
                                    <option value="67" >Venta de pelo para mujeres</option>
                                    <option value="68" >Aguacate</option>
                                    <option value="69" >Pelucas</option>
                                    <option value="601" >Pulceras, relojes, y joyas</option>
                                    <option value="602" >otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        <div className="input-field contenedor-input subcat ">
                            <select value={pais ? pais : data.paiz} className="browser-default" id="pais" onChange={(e) => {

                                if (e.target.value) {
                                    setPais(e.target.value);
                                } else {
                                    setPais("");
                                }
                            }} >
                                <option disabled selected>Elige tu pais</option>
                                <option value="01" >Guinea Ecuatorial</option>
                                <option disabled value="02" >Cameroun</option>
                                <option disabled value="07" >Costa de Marfil</option>
                                <option disabled value="03" >Senegal</option>
                                <option disabled value="06" >Gabon</option>
                                <option disabled value="05" >Benin</option>
                                <option disabled value="08" >Centro Africa</option>
                                <option disabled value="09" >Congo Kinsaza</option>
                            </select>
                        </div>
                        {pais === "01" || data.paiz == '01' ?
                            <div className="input-field contenedor-input subcat ">
                                <select value={ciudad ? ciudad : data.ciudad} className="browser-default" id="ciudad" onChange={(e) => {
                                    setCiudad(e.target.value);
                                    if (e.target.value) {
                                    } else {
                                    }
                                }} >
                                    <option disabled selected>Elige la ciudad</option>
                                    <option value="011" >Malabo</option>
                                    <option value="012" >Bata</option>
                                    <option value="013" >Oyala</option>
                                    <option value="014" >Ebibeyin</option>
                                    <option value="015" >Mongomo</option>
                                    <option value="016" >Luba</option>
                                    <option value="017" >Annobon</option>
                                </select>
                            </div>
                            :
                            ""
                        }

                        {pais === "02" ?
                            <div className="input-field contenedor-input subcat color-select">
                                <select className="browser-default" onChange={(e) => {
                                    setCiudad(e.target.value)
                                }} >
                                    <option disabled selected>Elige la ciudad</option>
                                    <option value="021" >Duoila</option>
                                    <option value="022" >Bamenda</option>
                                </select>
                            </div>
                            :
                            ""
                        }

                        <div className="contenedor-de-input">
                            <div className="input-field ">

                                <textarea value={localizacion ? localizacion : data.localizacion} className=" materialize-textarea" id="localizacionw" data-length="20" placeholder="" onChange={(e) => {
                                    if (e.target.value.length < 21 && e.target.value.length > 1) {
                                        setLocalizacion(e.target.value);
                                    } else {
                                    }
                                }}></textarea>
                                <label className="active ">Localizacion (Barrio) "OPCIONAL"</label>
                            </div>
                            <div className="input-field precios">
                                <input value={nombreDelArticulo ? nombreDelArticulo : data.nombre} id="nombrew" data-length="9" onChange={(e) => {

                                    if (e.target.value) {
                                        setNombreDelArticulo(e.target.value);
                                    } else {
                                        setNombreDelArticulo("")
                                    }
                                }} placeholder="" />
                                <label className="active ">Nombre o marca del producto</label>
                            </div>
                            <div className="input-field ">

                                <textarea value={breveDescripcion ? breveDescripcion : data.brevedescripcion} id="descripcionw" className="textareaCount  materialize-textarea" data-length="200" placeholder="" onChange={(e) => {

                                    if (e.target.value) {
                                        setBreveDescripcion(e.target.value);
                                    } else {
                                    }
                                }}></textarea>
                                <label className="active ">Descripcion</label>
                            </div>
                            <div className="input-field " style={{ display: "none" }}>

                                <textarea className="textareaCount materialize-textarea" data-length="200" placeholder="" onChange={(e) => { setDescripcion(e.target.value) }}></textarea>
                                <label className="active ">Carracteristicas del producto "OPCIONAL"</label>
                            </div>
                            <div className="input-field precios">
                                <input value={precio ? precio : data.precio} id="preciow" onChange={(e) => {
                                    if (e.target.value.trim()) {
                                        if (Number(e.target.value)) {
                                            setPrecio(e.target.value);
                                            setPrecioString("")
                                        } else {
                                            setPrecio("");
                                            setPrecioString(e.target.value);
                                        }
                                    } else {
                                        setPrecio("");
                                        setPrecioString("");
                                    }
                                }} placeholder="" />
                                <label className="active ">Precio del articulo</label>
                            </div>

                            <div className="row">
                                <div className="input-field col s6 m6 input-numbre">
                                    <input value={numeroW ? numeroW : data.mensage} id="inputw" data-length="9" onChange={e => {

                                        if (e.target.value.length <= 9 && Number(e.target.value.trim())) {
                                            setNumeroW(e.target.value.trim());
                                        } else {
                                        }
                                    }} placeholder="" validate />
                                    <label className="active ">Numero whatsapp</label>
                                </div>
                                <div className="input-field col s6 m6 input-numbre">
                                    <input value={numeroT ? numeroT : data.llamar} data-length="9" className="input-count" id="inputq" onChange={e => {

                                        if (e.target.value.length <= 9 && Number(e.target.value.trim())) {
                                            setNumeroT(e.target.value.trim());
                                        } else {
                                        }
                                    }} placeholder="" validate />
                                    <label className="active ">Numero llamadas</label>
                                </div>
                            </div>
                            <div className="texto-opcional">
                                <p>Selecciona las imagenes de tu publicacion maximo 4 imagenes</p>
                            </div>
                            <div className="file-select btn foto-principal" id="src-file1" >
                                <input multiple className="input-count" type="file" name="src-file1" aria-label="Archivo" onChange={(e) => { handleChangeImagen1(e.target.files) }} />
                            </div>


                        </div>

                        <div className="imagenes-elegidas">
                            {preview ? <img src={preview} alt="" /> : <img src={`${data.imagen1}`} alt="" />}
                            {preview1 || imagenUpload ? <>{imagen2 && preview1 ? <img src={preview1} alt="" /> : ""}</> : <>{data.imagen2 ? <img src={`${data.imagen2}`} alt="" /> : <></>}</>}
                            {preview2 || imagenUpload ? <>{imagen3 && preview2 ? <img src={preview2} alt="" /> : ""}</> : <>{data.imagen3 ? <img src={`${data.imagen3}`} alt="" /> : <></>}</>}
                            {preview3 || imagenUpload ? <>{imagen4 && preview3 ? <img src={preview3} alt="" /> : ""}</> : <>{data.imagen4 ? <img src={`${data.imagen4}`} alt="" /> : <></>}</>}
                        </div>

                        {probar ?
                            <div className="contenedor-spinner">
                                <div>
                                    <p>agregando..</p>
                                </div>
                                <div className="spinners">
                                    <MoonLoader color={"blue"} loading={true} size={30} />

                                </div>
                            </div>
                            : ""
                        }
                        <div className="input-field input-submit-btn ">
                            <button type="submit" className="btn">PUBLICAR PRODUCTO</button>
                        </div>

                    </form>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default EditarProducto