import React, { useState, useEffect, useContext } from 'react';
import { } from "react-router-dom"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BarradeNavegacion from '../components/navegacionBarAll/BarradeNavegacion';
import MenuAbajo from '../components/menuAbajo';
import FloatingButton from '../components/floatingButton';
import axios from 'axios';
import { BsListUl } from "react-icons/bs";
import { FiUser, FiPlus, FiSearch } from "react-icons/fi";
import "animate.css"
import CarrouselServicios from '../components/carroselServicios/carrouselServicios';
import VerdInfoProducto from '../components/verProduct/verdInfoProducto';
import { Link, NavLink, useParams } from 'react-router-dom'
import "../components/cardsFotosArticulos/cardFotos.css"
import AppContext from '../services/contextApp';
import { IoMdTime } from "react-icons/io";
import { format, register } from 'timeago.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoonLoader } from "react-spinners";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../styles/searchInput.css"
import { ARRAY_DATA_HOME, INFO_PRODUCT, SERVER_URL, SIGUIENTE_PAGINA_NUMERO, VERIFICAR_DATA_HOME, USER_ID, USER_NAME, VALIDAR_USER } from '../contexts/constantesVar';



const VerCatAgencias = () => {
    const { dispatch, verificarDataHome, arrayDataHome, siguientePaginaNumero } = useContext(AppContext)
    const [articlosDeHome, setArticlosDeHome] = useState([])
    const [productsCargado, setProductsCargado] = useState(false)
    const [paginaSiguiente, setPaginaSiguiente] = useState("")
    const [noMore, setNoMore] = useState(false)
    const { pais_post, ciudad_post, validar_user, user_id } = useContext(AppContext)
    const [buscar, setBuscar] = useState('')
    const [abrir, setAbrir] = useState(false)
    const [userData, setUserData] = useState([])

    const { id } = useParams()



    // funcion para realizar busquedas
    const Buscar = async () => {
        console.log(buscar)
        if (buscar) {
            setNoMore(false)

            setProductsCargado(false)

            const datos = await axios({ method: 'post', data: { 'clave': buscar, "user": userData.nombre, "pais": pais_post, "ciudad": ciudad_post }, url: `${SERVER_URL}/search_product_agencias` })
            console.log(datos, 'aaaaaaaa')

            if (datos.data.product.docs[0]) {
                //console.log(datos.data.docs)
                const valor = datos.data.product.docs[0]
                setArticlosDeHome(datos.data.product.docs)
                setPaginaSiguiente(datos.data.product.nextPage)
                //variable que verifica si ya se cargó una vez los articulos home para no volver a cargarlos


                setProductsCargado(true)

            } else {
                setProductsCargado(true)
                setArticlosDeHome([])
            }
        } else {
            setBuscar("")

        }

    }


    // funcion para activar la realizacion de busquedas
    const Validar = () => {
        if (buscar) {
            return true
        } else {
            return false
        }
    }


    //funcion para llamar a los submenus


    const CallSubMenu = (sub) => {

        for (let i = 1; i < 12; i++) {
            if (i === sub) {
                if (document.querySelector(`.subMenu${i}`)) {
                    document.querySelector(`.subMenu${i}`).style.display = "block"
                } else {

                }
            } else {
                if (document.querySelector(`.subMenu${i}`)) {
                    document.querySelector(`.subMenu${i}`).style.display = "none"
                } else {

                }
            }

        }
    }
    //configuracion que permite mostrar fecha en español
    register('es_ES', (number, index, total_sec) => [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 dia', 'en 1 dia'],
        ['hace %s dias', 'en %s dias'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['hace 1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años']
    ][index]);

    const timeago = timestamp => format(timestamp, 'es_ES')


    //traer articulos al cargar la pagina
    const ArticoloHome = async (paiss, ciudadd) => {
        setProductsCargado(false)
        setNoMore(false)
        try {
            const datos = await axios({
                method: "post", data: { "pais": paiss, "ciudad": "" },
                url: `${SERVER_URL}/agencia_article/${id}`
            })

            console.log(datos.data)
            if (datos.data.product.docs[0]) {
                //console.log(datos.data.docs)
                const valor = datos.data.product.docs[0]
                setArticlosDeHome(datos.data.product.docs)
                setUserData(datos.data.user)
                setPaginaSiguiente(datos.data.product.nextPage)
                //variable que verifica si ya se cargó una vez los articulos home para no volver a cargarlos

                setNoMore(true)

                setProductsCargado(true)

            } else {
                setProductsCargado(true)
                setArticlosDeHome([])
            }
            if (datos.data.user) {
                setUserData(datos.data.user)
            }
        } catch (error) {
            setProductsCargado(true)
            setArticlosDeHome([])

            console.log(error)

        }
    }

    // paginacion infinita mientras que se hace el scroll hacia abajo
    const PaginationInfiniScroll = async () => {
        //console.log(paginaSiguiente)
        try {
            if (paginaSiguiente) {
                setNoMore(true)
                const datos = await axios({
                    method: 'post',
                    data: {
                        'page': paginaSiguiente,
                        "pais": "01",//pais_post,
                        "ciudad": "",
                        "precio1": "home",
                        "precio2": "",
                        "indicator": true ? "cat" : "cat",
                        "user": userData.nombre
                    },
                    url: `${SERVER_URL}/paginacion_product_agencias`
                })
                console.log(datos)


                setPaginaSiguiente(datos.data.nextPage)

                if (siguientePaginaNumero !== null) {
                    //console.log(datos.data.docs)
                    //console.log(datos.data.docs.length)
                    //ingrementar el array de data home mientras se hace el scroll
                    setArticlosDeHome([...arrayDataHome, ...datos.data.docs])

                    setNoMore(false)


                } else {
                    setNoMore(false)
                    //console.log("esta bien")
                }
            } else {
                setNoMore(false)

            }

        } catch (error) {
            console.log(error)
            setNoMore(false)

        }


    }
    useEffect(() => {
        //verificar si ya se cargó los datos de data home para no cargarlos dos veces

        ArticoloHome()


    }, [])
    return (
        <div >
            <div className="contenedor-sticky  container-fluid  barra-de-nav-scrollTop ">
                <div className="menu-sticky container" id="menu">
                    <div className=" contenedor-enlaces-nav-sticky">
                        <div className="logotipo-sticky">
                            <Link to="/#" ><img src={"https://res.cloudinary.com/mumbex/image/upload/v1664622527/logo512_xmwnqy.png"} replace alt="" /></Link>
                        </div>
                        <div className="btn-departamentos-sticky esconde-menu-iniciar-idiomas">
                            <a
                                onMouseLeave={
                                    () => {
                                        CallSubMenu(9)

                                    }}
                            ><span>categorias</span> <BsListUl size={20} color="#fff" /></a>

                            <div className="dropppdown-content-sticky"
                                onMouseLeave={
                                    () => {
                                        CallSubMenu(9)

                                    }}
                            >
                                <div className="collection collection-1 col col4 bordes-bottom">
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(1)
                                            }}

                                        to="#" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Electronica
                                    </Link>
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(10)
                                            }}

                                        to="#" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/v1667469676/hogar_vlmpoe.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Hogar
                                    </Link>
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(6)
                                            }}
                                        to="#" className="collection-item droppdown-5 bodes-boton-none" >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Telecom.</Link>
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(5)
                                            }}
                                        to="#" className="collection-item droppdown-4 bodes-boton-none" >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Coches</Link>
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(2)

                                            }}


                                        to="#" className="collection-item droppdown-3 bodes-boton-none" >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654240227/56_xucqe2.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Casas en alquiler</Link>
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(3)

                                            }}


                                        to="#" className="collection-item droppdown-3 bodes-boton-none" >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Casas en venta</Link>



                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(7)
                                            }}
                                        to="#" className="collection-item droppdown-4 bodes-boton-none" >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243221/67_zulzib.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Vestimenta</Link>
                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(4)
                                            }}
                                        to="#" className="collection-item droppdown-5 bodes-boton-none" >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243124/66_gmotgv.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Servicios</Link>


                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(9)
                                            }}

                                        to="/vercatategoria/7" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243449/68_lxeaxc.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Terrenos
                                    </Link>

                                    <Link
                                        onMouseEnter={
                                            () => {
                                                CallSubMenu(9)
                                            }}

                                        to="/vercatategoria/16" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243798/69_qvvhqu.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                                        Electrodomesticos
                                    </Link>

                                </div>

                                {/* submemus */}
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu1">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/1" replace className="collection-item bodes-boton-none "> Todo</Link>
                                        <Link to="/vercatategoria/11" replace className="collection-item  bodes-boton-none ">Telefonos</Link>
                                        <Link to="/vercatategoria/12" replace className="collection-item  bodes-boton-none ">Televisiones</Link>
                                        <Link to="/vercatategoria/13" replace className="collection-item bodes-boton-none ">Ordenadores</Link>
                                        <Link to="/vercatategoria/14" replace className="collection-item bodes-boton-none "> Material informatico</Link>
                                        <Link to="/vercatategoria/15" replace className="collection-item bodes-boton-none "> Videojuegos</Link>
                                        <Link to="/vercatategoria/17" replace className="collection-item bodes-boton-none "> Arduino</Link>
                                        <Link to="/vercatategoria/18" replace className="collection-item bodes-boton-none "> Equipos de audiovision</Link>
                                    </div>
                                </div>
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu10">
                                    <div className="collection collection-1-2">
                                        <NavLink to="/vercatategoria/8" replace className="collection-item bodes-boton-none "> Todo</NavLink>
                                        <NavLink to="/vercatategoria/81" replace className="collection-item  bodes-boton-none ">Material de construccion</NavLink>
                                        <NavLink to="/vercatategoria/82" replace className="collection-item  bodes-boton-none ">Material para decorrar el hogar</NavLink>
                                    </div>
                                </div>
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu2">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/31" replace className="collection-item  bodes-boton-none ">Todo</Link>
                                        <Link to="/vercatategoria/311" replace className="collection-item  bodes-boton-none ">Alquilar casa de una habitacion</Link>
                                        <Link to="/vercatategoria/312" replace className="collection-item  bodes-boton-none ">Alquilar casa de dos habitaciones</Link>
                                        <Link to="/vercatategoria/313" replace className="collection-item bodes-boton-none ">Alquilar casa de tres habitaciones</Link>
                                        <Link to="/vercatategoria/314" replace className="collection-item bodes-boton-none ">Alquilar casa de cuatro habitaciones</Link>
                                        <Link to="/vercatategoria/315" replace className="collection-item bodes-boton-none ">Alquilar casa de cinco habitaciones</Link>
                                        <Link to="/vercatategoria/316" replace className="collection-item bodes-boton-none ">Alquilar casa de mas de cinco habitaciones</Link>
                                    </div>
                                </div>
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu3">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/32" className="collection-item  bodes-boton-none ">Todo</Link>
                                        <Link to="/vercatategoria/321" className="collection-item  bodes-boton-none ">Vender casa una habitacion</Link>
                                        <Link to="/vercatategoria/322" className="collection-item  bodes-boton-none ">Vender casa dos habitaciones</Link>
                                        <Link to="/vercatategoria/323" className="collection-item bodes-boton-none ">Vender casa tres habitaciones</Link>
                                        <Link to="/vercatategoria/324" className="collection-item bodes-boton-none ">Vender casa cuatro habitaciones</Link>
                                        <Link to="/vercatategoria/325" className="collection-item bodes-boton-none ">Vender casa cinco habitaciones</Link>
                                        <Link to="/vercatategoria/326" className="collection-item bodes-boton-none ">Vender casa mas de cinco habitaciones</Link>
                                    </div>
                                </div>
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu7">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/6" replace className="collection-item bodes-boton-none ">todo</Link>
                                        <Link to="/vercatategoria/61" replace className="collection-item  bodes-boton-none ">Zapatos para hombres</Link>
                                        <Link to="/vercatategoria/62" replace className="collection-item  bodes-boton-none ">Zapatos para mujeres</Link>
                                        <Link to="/vercatategoria/63" replace className="collection-item  bodes-boton-none ">Zapatos para niños</Link>
                                        <Link to="/vercatategoria/64" replace className="collection-item bodes-boton-none ">Ropas para mujeres</Link>
                                        <Link to="/vercatategoria/65" replace className="collection-item bodes-boton-none ">Ropas para hombres</Link>
                                        <Link to="/vercatategoria/66" replace className="collection-item bodes-boton-none ">Ropas para niños</Link>
                                        <Link to="/vercatategoria/67" replace className="collection-item bodes-boton-none ">Venta de pelo para mujeres</Link>
                                        <Link to="/vercatategoria/68" replace className="collection-item bodes-boton-none ">Aguacate</Link>
                                        <Link to="/vercatategoria/69" replace className="collection-item bodes-boton-none ">Pelucas</Link>
                                        <Link to="/vercatategoria/601" replace className="collection-item bodes-boton-none ">Joyas</Link>
                                    </div>
                                </div>
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu4">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/4" replace className="collection-item bodes-boton-none "> Todo</Link>
                                        <Link to="/vercatategoria/41" replace className="collection-item  bodes-boton-none ">Clases particulares</Link>
                                        <Link to="/vercatategoria/46" replace className="collection-item bodes-boton-none ">Buscar empleo</Link>
                                        <Link to="/vercatategoria/47" replace className="collection-item bodes-boton-none ">Ofrecer empleo</Link>
                                        <Link to="/vercatategoria/48" replace className="collection-item  bodes-boton-none ">limpiesa en hogar</Link>
                                        <Link to="/vercatategoria/404" replace className="collection-item bodes-boton-none ">venta de agua a domicilio</Link>
                                        <Link to="/vercatategoria/405" replace className="collection-item bodes-boton-none ">lavado de coches a domicilio</Link>
                                        <Link to="/vercatategoria/403" replace className="collection-item bodes-boton-none ">coche grua</Link>
                                        <Link to="/vercatategoria/401" replace className="collection-item bodes-boton-none ">reparacion de aparatos</Link>
                                        <Link to="/vercatategoria/49" replace className="collection-item bodes-boton-none ">mecanicos de coches</Link>

                                    </div>
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/406" replace className="collection-item bodes-boton-none ">instaciones electricas</Link>
                                        <Link to="/vercatategoria/44" replace className="collection-item bodes-boton-none ">publicidad</Link>
                                        <Link to="/vercatategoria/43" replace className="collection-item bodes-boton-none ">informatica</Link>
                                        <Link to="/vercatategoria/42" replace className="collection-item bodes-boton-none ">construccion</Link>
                                        <Link to="/vercatategoria/402" replace className="collection-item bodes-boton-none ">fontaneria</Link>
                                        <Link to="/vercatategoria/45" replace className="collection-item bodes-boton-none ">sanidad</Link>
                                    </div>
                                </div>
                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu5">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/5" className="collection-item bodes-boton-none ">Todo</Link>
                                        <Link to="/vercatategoria/51" className="collection-item  bodes-boton-none ">coches en alquiler</Link>
                                        <Link to="/vercatategoria/52" className="collection-item  bodes-boton-none ">coches en venta</Link>
                                        <Link to="/vercatategoria/53" className="collection-item bodes-boton-none ">taxis en elquiler</Link>
                                    </div>
                                </div>

                                <div className="collection collection-1-1 col col4 bordes-bottom subMenu6">
                                    <div className="collection collection-1-2">
                                        <Link to="/vercatategoria/2" className="collection-item bodes-boton-none ">Todo</Link>
                                        <Link to="/vercatategoria/21" className="collection-item  bodes-boton-none ">conectores rg45</Link>
                                        <Link to="/vercatategoria/22" className="collection-item  bodes-boton-none ">cables ethernet</Link>
                                        <Link to="/vercatategoria/23" className="collection-item bodes-boton-none ">routers</Link>
                                        <Link to="/vercatategoria/24" className="collection-item bodes-boton-none ">antenas</Link>
                                        <Link to="/vercatategoria/25" className="collection-item bodes-boton-none ">modems</Link>
                                        <Link to="/vercatategoria/26" className="collection-item bodes-boton-none ">Switch</Link>
                                    </div>
                                </div>
                            </div>




                        </div>
                        {/* fin de los submemus */}

                        {/* input para hacer busquedas*/}
                        <div className="logotipo-input-sticky" style={{}}>
                            <form className="row esconder-input-seach esconder-input-seach">

                                <div className="caja-buscar">

                                    <div className=" " style={{ display: "none" }}>
                                        <input id="icon_prefix" onChange={e => {
                                            try {
                                                if (e.target.value.trim()) {
                                                    setAbrir(true)
                                                    setBuscar(e.target.value.trim());
                                                } else {
                                                    setAbrir(false)
                                                    setBuscar("");
                                                    ArticoloHome()
                                                }
                                            } catch (error) {
                                                setAbrir(false)
                                                Buscar("")
                                            }


                                        }} name="buscar" type="text" className="validate" placeholder="Que buscas ?" />
                                    </div>

                                </div>
                                <div style={{ display: "flex", alignItems: "center", display: "none" }}>
                                    <button onClick={() => {
                                        //setBuscar('')
                                        Buscar()
                                    }} style={{ marginTop: "17px", marginLeft: "15px", cursor: "pointer", display: "flex", alignItems: "center", backgroundColor: "#fff", borderRadius: "10%", height: "31px", width: "50px" }} type="button" className='btn-small'><FiSearch size={20} color="#212121" /></button>
                                </div>
                            </form>
                        </div>
                        {/* fin del input para hacer busquedas*/}

                        {/* links de navegacion*/}
                        <div className="contenedor-link-sticky esconde-menu-iniciar-idiomas">
                            <div className="logotipo-link-sticky ">
                                <Link to={validar_user ? `/profil/${user_id}` : "/iniciar"}><FiUser size={20} color="#fff" /><span>{validar_user ? "Cuenta" : "Cuenta"}</span></Link>
                                <Link to="/agregar"><FiPlus size={20} color="#fff" /><span>Publicar</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*
        <div className='imgPubSinoLoUsas'>
            <div className='imgPubSinoLoUsasResp'>

                <img src={"https://res.cloudinary.com/mumbex/image/upload/v1667220434/aq_zvbg1i.jpg"} />


            </div>
        </div>

*/}
            {userData.imgLogo ?
                <>
                    <div className='container-logo-text' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "60px" }}>
                        <div className='container-log-resp' style={{ width: "200px", height: "200px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", backgroundColor: "rgba(0, 0, 0, 0.5)", border: "2px solid rgb(61, 61, 61)" }}>
                            {<img src={userData.imgLogo} style={{ width: "130px", objectFit: "contain" }} loader='lazy' />}
                        </div>

                    </div>
                    <div className='container-text-resp' style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <h2>{userData.nombre.slice(0, 9)}...</h2>
                    </div>
                    <div className='container-text-resp' style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <p>{userData.paiz} - {userData.ciudad} - {userData.numeroT.slice(0, 3)} {userData.numeroT.slice(3, 6)} {userData.numeroT.slice(6,)}</p>
                    </div>
                </>
                :
                <></>
            }

            {userData.imgLogo ?
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                    <div style={{ width: "300px", display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px", fontWeight: "600", textAlign: "center" }}>Enlace de la tienda o agencia</span>
                        <div style={{ display: 'flex', width: "300px", justifyContent: "center", textAlign: "center" }}>
                            <p style={{ color: "red", fontWeight: "600" }}>https://compraventaeg.com/#/<br></br>tienda_agencia/{userData.nombre}</p>
                        </div>
                    </div>
                </div>
                :
                ""
            }


            {/* fin del  imagen de publicidad */}
            <div style={{ marginTop: "60px", display: "none" }}>
                <CarrouselServicios />
            </div>
            {userData.imgLogo ?
                <div className="container-search-input-agencia">
                    <form className="div-search-input-agencia">

                        <input id="icon_prefix" onChange={e => {
                            try {
                                if (e.target.value.trim()) {
                                    setAbrir(true)
                                    setBuscar(e.target.value.trim());
                                } else {
                                    setAbrir(false)
                                    setBuscar("");
                                    ArticoloHome()
                                }
                            } catch (error) {
                                setAbrir(false)
                                Buscar("")
                            }


                        }} name="buscar" type="text" className="validate" placeholder="Que buscas ?" />

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button onClick={() => {
                                //setBuscar('')
                                Buscar()
                            }} style={{ marginTop: "17px", marginLeft: "15px", cursor: "pointer", display: "flex", alignItems: "center", backgroundColor: "#fff", borderRadius: "10%", height: "31px", width: "50px" }} type="button" className='btn-small'><FiSearch size={20} color="#212121" /></button>
                        </div>
                    </form>
                </div>
                : ""}

            {/* container pa pantallas grndes superiores a 600px */}
            <div className='text-publicaciones-recientes'>
                <h2></h2>
            </div>
            <di className="displayCardBigScreen">
                <div className='cardVerticalContainer '>

                    <InfiniteScroll
                        dataLength={articlosDeHome.length}
                        loader={<div className='cargarDatosmas'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                        hasMore={noMore}
                        next={() => {
                            PaginationInfiniScroll()
                        }}
                        className='cardVerticalContainerResp'
                    >
                        {productsCargado && articlosDeHome[0] ?
                            <>
                                {articlosDeHome.map((x, y) =>
                                    <Link to="#"
                                        onClick={() => {
                                            dispatch({
                                                type: INFO_PRODUCT,
                                                payload: [x]
                                            })
                                        }}
                                        className='cardVertical modal-trigger'
                                        data-target="modalInfoProduct"
                                        key={x["_id"]}
                                    >
                                        <div className='cardVerticalImg'>
                                            {<img src={x.imagen1} loader='lazy' />}
                                            {/* <img src ="https://mumbexserver.tech/img/1-928571e3-e98f-40bc-88f6-15fbd6a37094.jpg"/> */}
                                        </div>
                                        <div className='cardVerticalProductName'>
                                            <h6>{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</h6>
                                        </div>
                                        <div className='cardVerticalProductDescripcion' style={{ display: "none" }}>
                                            <h6>{x.brevedescripcion}...</h6>
                                        </div>
                                        <div className='cardVerticalProductPrecio'>
                                            {x["precio"] ?
                                                <h6>{x.precio.toLocaleString("es-GQ")} xfa</h6>
                                                :
                                                <h6>{x["precioString"]}</h6>
                                            }
                                        </div>
                                        <div className='cardVerticalProductTiempo'>
                                            <IoMdTime size={20} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                        </div>
                                    </Link>
                                )}

                            </>
                            :

                            <></>

                        }


                    </InfiniteScroll>
                    {productsCargado ?
                        <></> :
                        <div className="spinner-cat" style={{ height: 200, width: "100%" }}>
                            <p></p>
                            <MoonLoader color={"blue"} laoding={productsCargado} size={40} />
                            <div className="spinner-home-altura"></div>
                        </div>
                    }

                </div>
            </di>


            {/* container pa pantallas pequeñas inferiores a 600px */}
            <div className="displayCardMobil">
                <div className='nuevos-productos'>


                    <InfiniteScroll
                        dataLength={articlosDeHome.length}
                        loader={<div className='cargarDatosmas'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                        hasMore={noMore}
                        next={() => {
                            PaginationInfiniScroll()

                        }}
                        className='profilContainerCardResp'
                    >
                        <div className="respon-nuevos-productos ">

                            <div className="fila-de-targetas-horizontal ">
                                {productsCargado && articlosDeHome[0] ?
                                    <>
                                        {articlosDeHome.map((x, y) =>
                                            <Link to="#"
                                                onClick={() => {
                                                    dispatch({
                                                        type: INFO_PRODUCT,
                                                        payload: [x]
                                                    })
                                                }}
                                                className='cardCategoriaHorizotalContainer modal-trigger'
                                                data-target="modalInfoProduct"
                                                key={x["_id"]}
                                            >
                                                <div className="cardCategoriaHorizotal">
                                                    <div className="cardHorizotalfoto">
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            src={x["imagen1"]}
                                                        />
                                                    </div>
                                                    <div className="cardHorizotalText">
                                                        <div className="cardHorizotalDescription">
                                                            <p className="cardHorizotalDescriptionText">{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</p>
                                                            {x["precio"] ?
                                                                <p className="cardHorizotalprecio">{x["precio"].toLocaleString("es-GQ")} xfa</p>
                                                                :
                                                                <p className="cardHorizotalprecio">{x["precioString"]}</p>
                                                            }
                                                            <div className='cardHorizontalProductTiempo'>
                                                                <IoMdTime size={18} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </Link>
                                        )}

                                    </>
                                    :

                                    <></>

                                }

                            </div>
                        </div>
                    </InfiniteScroll>
                    {productsCargado ?
                        <></> :
                        <div className="spinner-cat" style={{ height: 200, width: "100%" }}>
                            <p></p>
                            <MoonLoader laoding={productsCargado} color={"blue"} size={40} />
                            <div className="spinner-home-altura"></div>
                        </div>
                    }


                </div>
            </div>
            <VerdInfoProducto />
            <FloatingButton />
        </div>
    )
}

export default VerCatAgencias