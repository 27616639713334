import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom"
import { css } from "@emotion/react";
import { ClipLoader, MoonLoader, PulseLoader } from "react-spinners";
import { FaFrown } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BarradeNavegacion from "../components/navegacionBarAll/BarradeNavegacion";
import AppContext from "../services/contextApp";
import Footer from '../components/Footer'
import MenuAbajo from "../components/menuAbajo";
import { ARRAY_SERVICIOS, RESP_ERROR_LOGIN } from "../contexts/constantesVar";
import 'animate.css';



const Registro = (props) => {
    const { history } = props
    const [user, setUser] = useState({
        email: '',
        contrasena: ''
    })
    var aaaaa = ["1"]
    var arrayServiciosAgence = []
    const { Registers, dispatch, loginSpinner, respServerUserUndefined, array_servicios, RegistroPersonal } = useContext(AppContext)

    const [a, setA] = useState(["1"])
    const [servicios, setServicios] = useState("")
    const [paisAgence, setPaisAgence] = useState("")
    const [arrayServicio, setArrayServicio] = useState("")
    const [categoria, setCategoria] = useState("")
    const [numeroW, setNumeroW] = useState("")
    const [numeroT, setNumeroT] = useState("")
    const [selectForm, setSelectForm] = useState('0101')
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [emailAgence, setEmailAgence] = useState("")
    const [password, setPassword] = useState("")
    const [passwordAgence, setPasswordAgence] = useState("")
    const [genero, setGenero] = useState("")
    const [pais, setPais] = useState("")
    const [nombre, setNombre] = useState("")
    const [descripcionAgence, setDescripcionAgence] = useState("")
    const [nombreAgence, setNombreAgence] = useState("")
    const [errors, setErrors] = useState("")


    const [breveDescripcionTiendaCorta, setBreveDescripcionTiendaCorta] = useState("")
    const [ciudadTienda, setCiudadTienda] = useState("")
    const [paisTienda, setPaisTienda] = useState("")
    const [phoneTienda, setPhoneTienda] = useState("")
    const [breveDescripcionTiendaLarga, setBreveDescripcionTiendaLarga] = useState("")
    const [nombreAgenceTienda, setNombreAgenceTienda] = useState("")
    const [passwordAgenceTienda, setPasswordAgenceTienda] = useState("")
    const [emailAgenceTienda, setEmailAgenceTienda] = useState("")
    const [imgLogo, setImgLogo] = useState("")
    const [imgPreview, setImgPreview] = useState(null)
    const [activeLogin,setActiveLogin] = useState(false)



    const WIDTH = 500




    //REGISTRO PARA AGENCIAS Y TIENDAS 
    const EnviarAgenciaTienda = async (e) => {
        console.log(selectForm)
        e.preventDefault();

        //console.log(loginSpinner)
        //console.log(emailAgence, passwordAgence, nombreAgence, descripcionAgence, servicios, numeroW, numeroW, categoria, imgLogo, selectForm)
        //const arrayDeServicios = new Array(servicios.split(","))

        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const verificar = emailAgenceTienda && passwordAgenceTienda && nombreAgenceTienda && breveDescripcionTiendaCorta && paisTienda && phoneTienda && ciudadTienda
        if (verificar) {
            if (emailAgenceTienda.match(mailformat)) {
                setErrors("")
                Registers(emailAgenceTienda, passwordAgenceTienda, nombreAgenceTienda, breveDescripcionTiendaCorta, paisTienda, phoneTienda, ciudadTienda, selectForm,imgLogo)

            } else {
                setErrors("introduce un email valido")
            }
        } else {
            if (nombreAgenceTienda) {
                document.getElementById("nombreAgencia").style.borderColor = "#4AC958";
            } else {
                document.getElementById("nombreAgencia").style.borderColor = "red";
            }
            if (emailAgenceTienda.match(mailformat)) {
                document.getElementById("emailAgencia").style.borderColor = "#4AC958";
            } else {
                document.getElementById("emailAgencia").style.borderColor = "red";
            }
            if (phoneTienda.length >= 9 && Number(phoneTienda)) {
                document.getElementById("phoneAgencia").style.borderColor = "#4AC958";

            } else {
                document.getElementById("phoneAgencia").style.borderColor = "red";

            }

            if (paisTienda) {
                document.getElementById("paisAgencias").style.borderColor = "#4AC958"
            } else {
                document.getElementById("paisAgencias").style.borderColor = "red"
            }
            if (paisTienda) {
                if (ciudadTienda) {
                    document.getElementById("ciudadAgencias").style.borderColor = "#4AC958"
                } else {
                    document.getElementById("ciudadAgencias").style.borderColor = "red"
                }
            } else {

            }

            if (breveDescripcionTiendaCorta) {
                document.getElementById("descripcionAgencia").style.borderColor = "#4AC958";
            } else {
                document.getElementById("descripcionAgencia").style.borderColor = "red"
            }
            if (passwordAgenceTienda.trim().length >= 8) {
                document.getElementById("passwordAgencia").style.borderColor = "#4AC958";
            } else {
                document.getElementById("passwordAgencia").style.borderColor = "red";
            }
            if (passwordAgenceTienda.trim().length >= 8) {
                document.getElementById("passwordAgenciaRep").style.borderColor = "#4AC958";
            } else {
                document.getElementById("passwordAgenciaRep").style.borderColor = "red";
            }
            setErrors("todos los campos son obligatorios")
        }

    }

    //REGISTRO DE TIPO PERSONAL
    const EnviarPersonal = async (e) => {
        e.preventDefault();
        //console.log(emailAgence, passwordAgence, nombreAgence,)

        //console.log(loginSpinner)

        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailAgence && passwordAgence && nombreAgence && phone && selectForm) {
            if (emailAgence.match(mailformat)) {
                setErrors("")
                RegistroPersonal(emailAgence, passwordAgence, nombreAgence, selectForm, phone)

            } else {
                setErrors("introduce un email valido")
            }


        } else {
            if (passwordAgence.length >= 8) {
                document.getElementById("passwordUserRep").style.borderColor = "#4AC958";
            } else {
                document.getElementById("passwordUserRep").style.borderColor = "red";
            }
            if (phone.trim().length >= 9) {
                document.getElementById("passwordUser").style.borderColor = "#4AC958";
            } else {
                document.getElementById("passwordUser").style.borderColor = "red";
            }
            if (emailAgence.trim().match(mailformat)) {
                document.getElementById("emailUser").style.borderColor = "#4AC958";
            } else {
                document.getElementById("emailUser").style.borderColor = "red";
            }
            if (nombreAgence.trim()) {
                document.getElementById("nombreUser").style.borderColor = "#4AC958";
            } else {
                document.getElementById("nombreUser").style.borderColor = "red"
            }
            setErrors("todos los campos son obligatorios")
        }
    }

    //PREVISUALIZAR IMAGEN DEL LOGO
    const VerImgLogo = (e) => {
        console.log(e)
        if (e) {
            setImgLogo(e)
            //OPTIMIZACION DEL IMAGEN4
            const reader = new FileReader()
            reader.readAsDataURL(e)
            /*
            reader.onload = (event) => {
                let img_url = event.target.result
                setImgPreview(img_url);

            }
*/

            reader.onload = (event) => {
                let img_url = event.target.result
                //console.log(img_url)

                let image = document.createElement("img")
                image.src = img_url
                image.onload = async (e) => {
                    //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                    let canvas = document.createElement("canvas")
                    let ratio = WIDTH / e.target.width
                    canvas.width = WIDTH
                    canvas.height = e.target.height * ratio
                    //crear objeto canvas 
                    const context = canvas.getContext("2d")

                    context.drawImage(image, 0, 0, canvas.width, canvas.height)
                    context.strokeStyle = "#ffffff"
                    context.shadowColor = "rgba(255, 255, 255, 1)"
                    context.globalAlpha = 1
                    context.imageSmoothingEnabled = false
                    context.shadowBlur = 1
                    context.fillStyle = "#ffffff"


                    let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                    //console.log(context)


                    //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                    const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                    const img_convert_to_blob = await img_fetch.blob("image/png")

                    setImgLogo(img_convert_to_blob)
                    //console.log(img_convert_to_blob)
                    setImgPreview(new_img_url);
                }


            }

        }
    }


    useEffect(() => {
        setErrors("")
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        if (imgLogo) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImgPreview(reader.result);

            }
            reader.readAsDataURL(imgLogo)
        } else {
            setImgPreview(null)
        }
    }, [])

    return (
        <div>
            <BarradeNavegacion />


            <div className="formulario-agregar" id="form">
                <div className="formulario-agregar-respon container " >
                    <form onSubmit={(e) => {
                        console.log(selectForm)
                        if (selectForm === '0101') {
                            EnviarPersonal(e)
                        }
                        if (selectForm === '0202') {
                            EnviarAgenciaTienda(e)
                        }
                    }}
                        className="formulario-agregar-form z-depth-1"
                    >
                        <div className="input-field contenedor-input  col s12  " style={{display:"none"}}>
                            <select onChange={(e) => {
                                if (e.target.value) {
                                    setSelectForm(e.target.value)

                                    /*
                                    setNombreAgence("")
                                    document.getElementById("nombres").value = ""
                                    setEmailAgence("")
                                    document.getElementById("passwordA").value = ""
                                    setPasswordAgence("")
                                    document.getElementById("emailA").value = ""
                                    setImgLogo("")
                                    setImgPreview("")
                                    */

                                    dispatch({
                                        type: RESP_ERROR_LOGIN,
                                        payload: ""
                                    })
                                    setErrors("")
                                } else {
                                    setSelectForm("")
                                }
                            }} className="browser-default "
                                name="seleccionar-formulario"
                                id="selectFormRegister"
                            >
                                <option disabled selected>Tipo de cuenta</option>
                                <option value="0101" >Usuario simple</option>
                                <option value="0202" >Tienda o agencias</option>

                            </select>
                        </div>
                        {true ?
                            <>




                                <div className="input-field  ">

                                    <input
                                        id="nombreUser"
                                        type="text"
                                        className=""
                                        name="nombre"
                                        placeholder="increse un nombre"
                                        onChange={(e) => {
                                            if (e.target.value.trim()) {
                                                document.getElementById("nombreUser").style.borderColor = "#4AC958";
                                                setNombreAgence(e.target.value.trim())
                                            } else {
                                                document.getElementById("nombreUser").style.borderColor = "red"
                                                setNombreAgence("")
                                            }
                                        }}


                                    />

                                </div>

                                <div className="input-field   col s12">

                                    <input
                                        type="email"
                                        id="emailUser"
                                        className="input-text"
                                        name="emailUser"
                                        placeholder="vuestro correo"
                                        onChange={(e) => {
                                            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                                            if (e.target.value.trim().match(mailformat)) {
                                                document.getElementById("emailUser").style.borderColor = "#4AC958";
                                                setEmailAgence(e.target.value.trim())
                                            } else {
                                                document.getElementById("emailUser").style.borderColor = "red";
                                                setEmailAgence("")
                                            }
                                        }}
                                    />
                                </div>

                                <div className="input-field contenedor-input  col s12">

                                    <input
                                        type="password"
                                        className="input-text"
                                        name="contrasena"
                                        id="passwordUser"
                                        placeholder="tu contraseña"
                                        onChange={(e) => {
                                            if (e.target.value.trim().length >= 9) {
                                                document.getElementById("passwordUser").style.borderColor = "#4AC958";
                                                setPhone(e.target.value.trim())
                                            } else {
                                                document.getElementById("passwordUser").style.borderColor = "red";
                                                setPhone("")
                                            }
                                        }}

                                    />
                                </div>

                                <div className="input-field   col s12">

                                    <input
                                        type="password"
                                        className="input-text"
                                        name="contrasenaRep"
                                        id="passwordUserRep"
                                        placeholder="repita la contraseña"
                                        onChange={(e) => {
                                            if (e.target.value.trim().length >= 8) {
                                                document.getElementById("passwordUserRep").style.borderColor = "#4AC958";
                                                setPasswordAgence(e.target.value.trim())
                                            } else {
                                                document.getElementById("passwordUserRep").style.borderColor = "red";
                                                setPasswordAgence("")
                                            }
                                        }}

                                    />
                                </div>



                                <div className="formularioReI-respon-cambio">
                                    <p>Si  tienes una cuenta  </p><Link onClick={() => {
                                        dispatch({
                                            type: RESP_ERROR_LOGIN,
                                            payload: ""
                                        })
                                    }} to="/iniciar">Inicia session !</Link>
                                </div>
                                <div className="respuestaError">
                                    <>
                                        <p className='animate__shakeX animate__animated animate__repeat-1'>{respServerUserUndefined && errors === "" ? respServerUserUndefined : ""}</p>
                                        <p className='animate__shakeX animate__animated animate__repeat-1'>{respServerUserUndefined === "" && errors ? errors : ""}</p>
                                    </>
                                </div>
                                <div className='boton-submit-container'>
                                    <button
                                        className="formularioReI-form-btn  btn-small"
                                        type="submit"
                                        onClick={
                                            () => {

                                                dispatch({
                                                    type: RESP_ERROR_LOGIN,
                                                    payload: ""
                                                })
                                                setActiveLogin(false)
                                            }
                                        }

                                    >
                                        {loginSpinner ? <PulseLoader className="fff" color={"#fff"} loading={activeLogin} size={15} /> : "Registrarse !"}
                                    </button>
                                </div>


                            </>
                            :
                            <></>
                        }
                        {false ?
                            <>


                                <div className="input-field  ">

                                    <input
                                        id="nombreAgencia"
                                        type="text"
                                        className=""
                                        name="nombreAgencia"
                                        placeholder="Nombre de la agencia o tienda"
                                        onChange={(e) => {
                                            if (e.target.value.trim()) {
                                                document.getElementById("nombreAgencia").style.borderColor = "#4AC958";
                                                setNombreAgenceTienda(e.target.value.trim())
                                            } else {
                                                document.getElementById("nombreAgencia").style.borderColor = "red";
                                                setNombreAgenceTienda("")
                                            }
                                        }}

                                    />

                                </div>

                                <div className="input-field   col s12">

                                    <input
                                        type="email"
                                        id="emailAgencia"
                                        className="input-text"
                                        name="emailsAgencia"
                                        placeholder="vuestro correo"
                                        onChange={(e) => {
                                            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                                            if (e.target.value.trim().match(mailformat)) {
                                                document.getElementById("emailAgencia").style.borderColor = "#4AC958";

                                                setEmailAgenceTienda(e.target.value.trim())
                                            } else {
                                                document.getElementById("emailAgencia").style.borderColor = "red";

                                                setEmailAgenceTienda("")
                                            }
                                        }}

                                    />
                                </div>

                                <div className="input-field contenedor-input  col s12">

                                    <input
                                        type="text"
                                        className="input-text"
                                        name="phoneAgencia"
                                        id="phoneAgencia"
                                        placeholder="Numero de telefono"
                                        onChange={(e) => {
                                            if (e.target.value.trim().length >= 9) {
                                                document.getElementById("phoneAgencia").style.borderColor = "#4AC958";

                                                setPhoneTienda(e.target.value.trim())
                                            } else {
                                                document.getElementById("phoneAgencia").style.borderColor = "red";

                                                setPhoneTienda("")
                                            }
                                        }}

                                    />
                                </div>
                                <div className="input-field contenedor-input subcat ">
                                    <select className="browser-default" id="paisAgencias" name="paisAgencias" onChange={(e) => {

                                        if (e.target.value) {
                                            setPaisTienda(e.target.value);
                                            document.getElementById("paisAgencias").style.borderColor = "#4AC958"
                                        } else {
                                            setPaisTienda("");
                                            document.getElementById("paisAgencias").style.borderColor = "red"
                                        }
                                    }} >
                                        <option disabled selected>Elige tu pais</option>
                                        <option value="Guinea Ecuatorial" >Guinea Ecuatorial</option>
                                        <option disabled value="Cameroun" >Cameroun</option>
                                        <option disabled value="Costa de Marfil" >Costa de Marfil</option>
                                        <option disabled value="Senegal" >Senegal</option>
                                        <option disabled value="Gabon" >Gabon</option>
                                        <option disabled value="Benin" >Benin</option>
                                        <option disabled value="Centro Africa" >Centro Africa</option>
                                        <option disabled value="Congo Kinsaza" >Congo Kinsaza</option>
                                    </select>
                                </div>
                                {paisTienda === "Guinea Ecuatorial" ?
                                    <div className="input-field contenedor-input subcat ">
                                        <select className="browser-default" name="ciudadAgencias" id="ciudadAgencias" onChange={(e) => {
                                            if (e.target.value) {
                                                setCiudadTienda(e.target.value);
                                                document.getElementById("ciudadAgencias").style.borderColor = "#4AC958"
                                            } else {
                                                document.getElementById("ciudadAgencias").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Elige la ciudad</option>
                                            <option value="Malabo" >Malabo</option>
                                            <option value="Bata" >Bata</option>
                                            <option value="Oyala" >Oyala</option>
                                            <option value="Ebibeyin" >Ebibeyin</option>
                                            <option value="Mongomo" >Mongomo</option>
                                            <option value="Luba" >Luba</option>
                                            <option value="Annobon" >Annobon</option>
                                        </select>
                                    </div>
                                    :
                                    ""
                                }
                                <div className="input-field ">

                                    <textarea id="descripcionAgencia" name="" className="textareaCount  materialize-textarea" data-length="200" placeholder="Servios o productos que ofreceis" onChange={(e) => {

                                        if (e.target.value.trim()) {
                                            document.getElementById("descripcionAgencia").style.borderColor = "#4AC958";
                                            setBreveDescripcionTiendaCorta(e.target.value.trim());
                                        } else {
                                            document.getElementById("descripcionAgencia").style.borderColor = "red"
                                        }
                                    }}></textarea>
                                </div>
                                <div className="input-field " style={{ display: "none" }}>

                                    <textarea id="descripcionAgencias" className="textareaCount  materialize-textarea" data-length="200" placeholder="Descripcion de vuestro negocio" onChange={(e) => {

                                        if (e.target.value.trim()) {
                                            document.getElementById("descripcionAgencias").style.borderColor = "#4AC958";
                                            setBreveDescripcionTiendaLarga(e.target.value.trim());
                                        } else {
                                            document.getElementById("descripcionAgencias").style.borderColor = "red"
                                        }
                                    }}></textarea>
                                </div>
                                <div className="input-field contenedor-input  col s12">

                                    <input
                                        type="password"
                                        className="input-text"
                                        name="contrasena"
                                        id="passwordAgencia"
                                        placeholder="tu contraseña"
                                        onChange={(e) => {
                                            if (e.target.value.trim().length >= 8) {
                                                document.getElementById("passwordAgencia").style.borderColor = "#4AC958";
                                                setPasswordAgenceTienda(e.target.value.trim())
                                            } else {
                                                document.getElementById("passwordAgencia").style.borderColor = "red";
                                                setPasswordAgenceTienda("")
                                            }
                                        }}

                                    />
                                </div>
                                <div className="input-field contenedor-input  col s12">

                                    <input
                                        type="password"
                                        className="input-text"
                                        name="contrasena"
                                        id="passwordAgenciaRep"
                                        placeholder="Repite la contraseña"
                                        onChange={(e) => {
                                            if (e.target.value.trim().length >= 8) {
                                                document.getElementById("passwordAgenciaRep").style.borderColor = "#4AC958";
                                                setPasswordAgenceTienda(e.target.value.trim())
                                            } else {
                                                document.getElementById("passwordAgenciaRep").style.borderColor = "red";
                                                setPasswordAgenceTienda("")
                                            }
                                        }}

                                    />
                                </div>

                                <div className="file-select btn foto-principal" id="src-fileRegist" >
                                    <input onChange={(e) => VerImgLogo(e.target.files[0])} className="input-count" type="file" name="src-file1" aria-label="Archivo" />
                                </div>
                                <div className="imagenes-elegidas" >
                                    <img src={imgPreview} alt="" />
                                </div>






                                <div className="respuestaError">
                                    <>
                                        <p className='animate__shakeX animate__animated animate__repeat-1'>{respServerUserUndefined && errors === "" ? respServerUserUndefined : ""}</p>
                                        <p className='animate__shakeX animate__animated animate__repeat-1'>{respServerUserUndefined === "" && errors ? errors : ""}</p>
                                    </>
                                </div>
                                <div className='boton-submit-container'>
                                    <button
                                        className="formularioReI-form-btn  btn-small"
                                        type="submit"
                                        onClick={
                                            () => {

                                                dispatch({
                                                    type: RESP_ERROR_LOGIN,
                                                    payload: ""
                                                })
                                            }
                                        }

                                    >
                                        {loginSpinner ? <PulseLoader className="fff" color={"#212121"} loading={activeLogin} size={15} /> : "Registrarse !"}
                                    </button>
                                </div>
                                <div className="formularioReI-respon-cambio">
                                    <p>Si  tienes una cuenta  </p><Link onClick={() => {
                                        dispatch({
                                            type: RESP_ERROR_LOGIN,
                                            payload: ""
                                        })
                                        setActiveLogin(true)
                                    }} to="/iniciar">Inicia session !</Link>
                                </div>


                            </>
                            :
                            <></>
                        }


                    </form>

                </div>

            </div>

            <MenuAbajo />

        </div>
    )
}






export default Registro;