import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./cardFotos.css"
import AppContext from '../../services/contextApp';
import { IoMdTime } from "react-icons/io";
import axios from 'axios';
import { format, register } from 'timeago.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoonLoader } from "react-spinners";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ARRAY_DATA_HOME, INFO_PRODUCT, SERVER_URL, SIGUIENTE_PAGINA_NUMERO, VERIFICAR_DATA_HOME } from '../../contexts/constantesVar';


function CardFotos() {
    const { dispatch, verificarDataHome, arrayDataHome, siguientePaginaNumero } = useContext(AppContext)
    const [articlosDeHome, setArticlosDeHome] = useState([])
    const [productsCargado, setProductsCargado] = useState(false)
    const [paginaSiguiente, setPaginaSiguiente] = useState("")
    const [noMore, setNoMore] = useState(false)



    //configuracion que permite mostrar fecha en español
    register('es_ES', (number, index, total_sec) => [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 dia', 'en 1 dia'],
        ['hace %s dias', 'en %s dias'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['hace 1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años']
    ][index]);

    const timeago = timestamp => format(timestamp, 'es_ES')


    //traer articulos al cargar la pagina
    const ArticoloHome = async (paiss, ciudadd) => {
        setProductsCargado(false)
        setNoMore(false)
        try {
            const datos = await axios({
                method: "post", data: { "pais": paiss, "ciudad": "" },
                url: `${SERVER_URL}/home_article`
            })

            //console.log(datos.data)
            if (datos.data.docs[0]) {
                //console.log(datos.data.docs)
                const valor = datos.data.docs[0]
                setArticlosDeHome(datos.data.docs)
                setPaginaSiguiente(datos.data.nextPage)
                //variable que verifica si ya se cargó una vez los articulos home para no volver a cargarlos
                dispatch({
                    type: VERIFICAR_DATA_HOME,
                    payload: true
                })
                dispatch({
                    type: SIGUIENTE_PAGINA_NUMERO,
                    payload: datos.data.nextPage
                })
                //array de data home
                dispatch({
                    type: ARRAY_DATA_HOME,
                    payload: datos.data.docs
                })


                setProductsCargado(true)
                setNoMore(true)

            } else {
                setProductsCargado(true)
                setArticlosDeHome([])
            }
        } catch (error) {
            setProductsCargado(true)
            setArticlosDeHome([])

            console.log(error)

        }
    }

    // paginacion infinita mientras que se hace el scroll hacia abajo
    const PaginationInfiniScroll = async () => {
        console.log("object")
        try {
            setNoMore(true)
            const datos = await axios({
                method: 'post',
                data: {
                    'page': siguientePaginaNumero,
                    "pais": "01",//pais_post,
                    "ciudad": "",
                    "precio1": "home",
                    "precio2": "",
                    "indicator": "home"
                },
                url: `${SERVER_URL}/infiniti_scroll_home/${"p"}`
            })

            //guardar la pagina siguiente
            dispatch({
                type: SIGUIENTE_PAGINA_NUMERO,
                payload: datos.data.nextPage
            })
            setPaginaSiguiente(datos.data.nextPage)

            if (siguientePaginaNumero !== null) {
                //console.log(datos.data.docs)
                //console.log(datos.data.docs.length)
                //ingrementar el array de data home mientras se hace el scroll
                setArticlosDeHome([...arrayDataHome, ...datos.data.docs])
                dispatch({
                    type: ARRAY_DATA_HOME,
                    payload: [...arrayDataHome, ...datos.data.docs]
                })
                setNoMore(false)


            } else {
                setNoMore(false)
                //console.log("esta bien")
            }
        } catch (error) {
            console.log(error)
            setNoMore(false)

        }


    }
    const scrollToBottom = () => {
        document.getElementById('modalInfoProduct').scrollIntoView({ behavior: 'smooth', block: "end" });

    }

    useEffect(() => {

        //verificar si ya se cargó los datos de data home para no cargarlos dos veces
        if (verificarDataHome) {
            setArticlosDeHome(arrayDataHome)
            setProductsCargado(true)
            setNoMore(true)
        } else {
            ArticoloHome()
        }

    }, [])
    return (
        <>
            {/* container pa pantallas grndes superiores a 600px */}
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "-20px" }}>
                <div style={{ width: "950px" }}><h2 style={{ fontWeight: 600, color: "#b71c1c", textAlign: "center" }}>Recientes</h2></div>
            </div>
            <di className="displayCardBigScreen">
                <div className='cardVerticalContainer '>

                    <InfiniteScroll
                        dataLength={articlosDeHome.length}
                        loader={<div className='cargarDatosmas'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                        hasMore={noMore}
                        next={() => {
                            PaginationInfiniScroll()
                        }}
                        className='cardVerticalContainerResp'
                    >
                        {productsCargado && articlosDeHome[0] ?
                            <>
                                {articlosDeHome.map((x, y) =>
                                    <Link to={`/product/${x._id}`}
                                        onClick={() => {
                                            /*
                                            dispatch({
                                                type: INFO_PRODUCT,
                                                payload: [x]
                                            })
                                            scrollToBottom()
                                            */
                                        }}
                                        className='cardVertical modal-trigger'
                                        data-target="modalInfoProduct"
                                        key={x["_id"]}
                                    >
                                        <div className='cardVerticalImg'>
                                            {<img src={x.imagen1} loader='lazy' />}
                                            {/* <img src ="https://mumbexserver.tech/img/1-928571e3-e98f-40bc-88f6-15fbd6a37094.jpg"/> */}
                                        </div>
                                        <div className='cardVerticalProductName'>
                                            <h6>{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</h6>
                                        </div>
                                        <div className='cardVerticalProductDescripcion' style={{ display: "none" }}>
                                            <h6>{x.brevedescripcion}...</h6>
                                        </div>
                                        <div className='cardVerticalProductPrecio'>
                                            {x["precio"] ?
                                                <h6>{x.precio.toLocaleString("es-GQ")} xfa</h6>
                                                :
                                                <h6>{x["precioString"]}</h6>
                                            }
                                        </div>
                                        <div className='cardVerticalProductTiempo'>
                                            <IoMdTime size={20} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                        </div>
                                    </Link>
                                )}

                            </>
                            :

                            <></>

                        }


                    </InfiniteScroll>
                    {productsCargado ?
                        <></> :
                        <div className="spinner-cat" style={{ height: 200, width: "100%", marginTop: "-95px" }}>
                            <p></p>
                            <MoonLoader color={"blue"} laoding={productsCargado} size={40} />
                            <div className="spinner-home-altura"></div>
                        </div>
                    }

                </div>
            </di>


            {/* container pa pantallas pequeñas inferiores a 600px */}
            <div className="displayCardMobil">
                <div className='nuevos-productos'>


                    <InfiniteScroll
                        dataLength={articlosDeHome.length}
                        loader={<div className='cargarDatosmas'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                        hasMore={noMore}
                        next={() => {
                            PaginationInfiniScroll()

                        }}
                        className='profilContainerCardResp'
                    >
                        <div className="respon-nuevos-productos ">

                            <div className="fila-de-targetas-horizontal ">
                                {productsCargado && articlosDeHome[0] ?
                                    <>
                                        {articlosDeHome.map((x, y) =>
                                            <Link to={`/product/${x._id}`}
                                                onClick={() => {
                                                    /*
                                                    dispatch({
                                                        type: INFO_PRODUCT,
                                                        payload: [x]
                                                    })
                                                    scrollToBottom()
                                                    */
                                                }}
                                                className='cardCategoriaHorizotalContainer modal-trigger'
                                                data-target="modalInfoProduct"
                                                key={x["_id"]}
                                            >
                                                <div className="cardCategoriaHorizotal">
                                                    <div className="cardHorizotalfoto">
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            src={x["imagen1"]}
                                                        />
                                                    </div>
                                                    <div className="cardHorizotalText">
                                                        <div className="cardHorizotalDescription">
                                                            <p className="cardHorizotalDescriptionText">{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</p>
                                                            {x["precio"] ?
                                                                <p className="cardHorizotalprecio">{x["precio"].toLocaleString("es-GQ")} xfa</p>
                                                                :
                                                                <p className="cardHorizotalprecio">{x["precioString"]}</p>
                                                            }
                                                            <div className='cardHorizontalProductTiempo'>
                                                                <IoMdTime size={18} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </Link>
                                        )}

                                    </>
                                    :

                                    <></>

                                }

                            </div>
                        </div>
                    </InfiniteScroll>
                    {productsCargado ?
                        <></> :
                        <div className="spinner-cat" style={{ height: 200, width: "100%", marginTop: "-135px" }}>
                            <p></p>
                            <MoonLoader laoding={productsCargado} color={"blue"} size={40} />
                            <div className="spinner-home-altura"></div>
                        </div>
                    }


                </div>
            </div>
        </>
    )
}

export default CardFotos
