import React, { useState, useEffect, useContext } from 'react';
import { Link, Linnk } from "react-router-dom"
import { MoonLoader } from "react-spinners";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfiniteScroll from 'react-infinite-scroll-component';
import MenuAbajoCat from '../components/menuAbajoCat';
import Footer from '../components/Footer';
import FloatingButton from '../components/floatingButton';
import M from "materialize-css"
import { useParams } from "react-router-dom";
import AppContext from '../services/contextApp';
import { FaFrown } from 'react-icons/fa';
import { CAT_PARA_PAGE, INFO_PRODUCT } from '../contexts/constantesVar';
import { format, register } from 'timeago.js';
import BarradeNavegacionCat from '../components/navegacionBarCate/BarradeNavegacionCat';
import { FiltrarPorPrecio } from '../components/filtrarPorPrecio';
import SinavCat from '../components/navegacionBarCate/sinavCat';
import FiltraComponente from '../components/filtraComponente';
import '../styles/verCayegoriaCardStyle.css'

import { IoMdTime } from 'react-icons/io';
import VerdInfoProducto from '../components/verProduct/verdInfoProducto';

const VerCategoria = (props) => {
    //obteniendo el parametro del enlace
    const { id } = useParams()

    //variables del contexto global de la aplicacion
    const { precio1, precio2, dispatch, busquedaClave, catParaPage, categoria, pais_post,noMoreVerCat,
        ciudad_post, probarSiHay, paginaSiguiente, dataCategoria, CallDataCat, PaginacionConScroll } = useContext(AppContext)

    const [noMore, setNoMore] = useState(true)//para paginacion scroll
    const [pais, setPais] = useState("01")
    const [ciudad, setCiudad] = useState("")
    const [precio11, setPrecio1] = useState("")
    const [precio22, setPrecio2] = useState("")

    //configuracion que permite mostrar cuando hace que el articulo es publicado
    register('es_ES', (number, index, total_sec) => [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 dia', 'en 1 dia'],
        ['hace %s dias', 'en %s dias'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['hace 1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años']
    ][index]);
    const timeago = timestamp => format(timestamp, 'es_ES')
    /*********************************************************** */

    //funcion para filtrar productos por precio
    const FiltrarProductos = () => {
        FiltrarPorPrecio(pais, dispatch, ciudad, precio11, precio22, busquedaClave, categoria, pais_post)
    }

    //funcion para hacer paginacion mientrar que se hace el scroll hacia abajo
    const PaginacionScroll = () => {
        PaginacionConScroll(catParaPage, dataCategoria, dispatch, categoria, paginaSiguiente, pais_post, ciudad_post, precio1, precio2)
    }

    useEffect(() => {
        dispatch({
            type: CAT_PARA_PAGE,
            payload: id
        })

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        //TraerDatosCategoria()
        CallDataCat(id, dispatch, pais_post, ciudad_post)


        /***sidenav */
        const allFilter = document.querySelector(".filterSide")
        M.Sidenav.init(allFilter, {
            preventScrolling: false,
            inDuration: 150,
            edge: "right",
        })
        /***Finsidenav */

        const collap = document.querySelector('.collapsible')
        M.Collapsible.init(collap, {})



    }, [])
    return (
        <div>
            <div>
                {/************* side nav para filtrar ***************/}
                <FiltraComponente />
                {/*********menu vertical que aparece en dispositivos moviles******** */}

                {/*********menu vertical o sideNav*************  */}
                <SinavCat />
                {/* *************** */}

                {/*barra de navegacion*/}
                <BarradeNavegacionCat />
                {/*fin de la barra de navegacion*/}


                {/*contenedor para card y filtros*/}

                <div className='containerMenuCard100Cat'>
                    <div className="containerMenuCard">
                        {/* seccion de filtro */}
                        <div className='menuLateral z-dept-1'>
                            <div className='puedesFiltrarPor'>
                                {<h6>Puedes filtrar por</h6>}
                            </div>
                            {false ?
                                <div className="input-field select-filter">
                                    <select className="browser-default selectFormFiltro" onChange={(e) => { setPais(e.target.value); setCiudad("") }} >
                                        <option disabled selected>Elige tu pais</option>
                                        <option value="01" >Guinea Ecuatorial</option>
                                        <option value="02" >Cameroun</option>
                                        <option value="07" >Cote d`Ivoire</option>
                                        <option value="03" >Senegal</option>
                                        <option value="06" >Gabon</option>
                                        <option value="05" >Benin</option>
                                        <option value="08" >Centro Africa</option>
                                        <option value="09" >Congo Kinsaza</option>

                                    </select>
                                </div> : ""}
                            {/*pais === "01" */true ?
                                <div className="input-field select-filter">
                                    <select id='ciudadB' className="browser-default selectFormFiltro" onChange={(e) => { setCiudad(e.target.value) }} >
                                        <option disabled selected>Elige la ciudad</option>
                                        <option value="011" >Malabo</option>
                                        <option value="012" >Bata</option>
                                        <option value="013" >Ebibeyin</option>
                                        <option value="014" >Mongomo</option>
                                        <option value="015" >Annobon</option>
                                    </select>
                                </div>
                                :
                                ""
                            }


                            {/*pais === "02"*/false ?
                                <div className="input-field ">
                                    <select className="browser-default selectFormFiltro" onChange={(e) => { setCiudad(e.target.value) }} >
                                        <option disabled selected>Elige la ciudad</option>
                                        <option value="021" >Duoila</option>
                                        <option value="022" >Bamenda</option>
                                    </select>
                                </div>
                                :
                                ""
                            }

                            <div className="input-field select-filter">
                                <select id='precioB' className="browser-default selectFormFiltro" onChange={(e) => {
                                    setPrecio1(Number(e.target.value.split(",")[0]));
                                    setPrecio2(Number(e.target.value.split(",")[1]));
                                    /*Number(e.target.value.split(",")[1])*/
                                }} >
                                    <option disabled selected>filtrar precio</option>
                                    <option value={[0, 0]} >Todos</option>
                                    <option value={[-1, 10000]} >menos de 10.000 fxa</option>
                                    {/* <option value={[10000, 20000]} >10.000 a 20.000</option> */}
                                    <option value={[20000, 50000]} >menos de 50.000</option>
                                    <option value={[50000, 100000]} >menos de  100.000</option>
                                    {/* <option value={[100000, 250000]} >100.000 a 250.000</option> */}
                                    <option value={[250000, 500000]} >menos de 500.000</option>
                                    <option value={[500000, 1000000]} >menos de 1.000.000</option>
                                    <option value={[1000000, 5000000]} >menos de 5.000.000</option>
                                    <option value={[5000000, 10000000]} >menos de 10.000.000</option>
                                    <option value={[10000000, 50000000]} >menos de 50.000.000</option>
                                    <option value={[50000000, 99000000]} >menos de 100.000.000</option>
                                    <option value={[99000000, 999000000]} >menos de 999.000.000</option>
                                </select>
                            </div>

                            <div className='btnFiltrar container-btn-filter'>
                                <button onClick={() => { FiltrarProductos() }} className='btn-small'>
                                    Filtrar
                                </button>
                            </div>
                        </div>
                        {/* fin de la seccion de filtro */}

                        {/* seccion de card de producto*/}
                        <div className="productos-ver-cat">
                            <div className='containerLocalizacion container'>
                                <h1>{ciudad_post ? "" : ""}{ciudad_post === "011" ? "Malabo" : ""}{ciudad_post === "012" ? "Bata" : ""}{ciudad_post === "013" ? "Ebibeyin" : ""}{ciudad_post === "014" ? "Mongomo" : ""}{ciudad_post === "015" ? "Annobon" : ""}{ciudad_post === "016" ? "Mongomo" : ""}</h1>
                            </div>


                            <div className="respon-productos-ver-cat ">
                                <div className="fila-de-targetas-ver-cat ">
                                    {probarSiHay && dataCategoria[0] ?
                                        <InfiniteScroll
                                            dataLength={dataCategoria.length} //This is important field to render the next data
                                            next={() => {
                                                PaginacionScroll()
                                            }}

                                            hasMore={noMoreVerCat}
                                            loader={<div className='cargarDatosmas-ver-cat'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>

                                                </p>
                                            }
                                            className='card-container-resp-targetas-ver-cat '


                                        >
                                            {dataCategoria.map((x, y) =>

                                                <Link to={`/product/${x._id}`}
                                                    onClick={() => {
                                                        /*
                                                        dispatch({
                                                            type: INFO_PRODUCT,
                                                            payload: [x]
                                                        })
                                                        */
                                                    }}
                                                    className="cardCategoria-ver-cat-2 modal-trigger"
                                                    data-target="modalInfoProduct"
                                                >
                                                    <div className="cardCategoria-ver-cat-">
                                                        <div className="card-foto-ver-cat">
                                                            <LazyLoadImage
                                                                effect="blur"
                                                                src={x["imagen1"]}
                                                            />
                                                        </div>
                                                       

                                                        <div className='cardVerticalProductName-ver-cat'>
                                                            <h6>{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</h6>
                                                        </div>
                                                        <div className='cardVerticalProductDescripcion-ver-cat' style={{ display: "none" }}>
                                                            <h6>{x.brevedescripcion}...</h6>
                                                        </div>
                                                        <div className='cardVerticalProductPrecio-ver-cat'>
                                                            {x["precio"] ?
                                                                <h6>{x.precio.toLocaleString("es-GQ")} xfa</h6>
                                                                :
                                                                <h6>{x["precioString"]}</h6>
                                                            }
                                                        </div>
                                                        <div className='cardVerticalProductTiempo-ver-cat'>
                                                            <IoMdTime size={20} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                        </div>



                                                    </div>
                                                </Link>
                                            )}
                                        </InfiniteScroll>



                                        :
                                        <>
                                            {!dataCategoria[0] && probarSiHay ?
                                                <div className="ceroResultado">
                                                    <FaFrown className='iconResultadoCeros' />
                                                    <h1>Cero resultado</h1>
                                                </div>
                                                :
                                                ""
                                            }
                                            {probarSiHay ?
                                                ""
                                                :
                                                <div className="spinner-cat">
                                                    <p></p>
                                                    <MoonLoader color={"blue"} loading={true} size={40} />
                                                    <div className="spinner-home-altura"></div>
                                                </div>
                                            }
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                        {/* fin de la seccion de card de producto*/}
                    </div>
                </div>

                {/*fin del contenedor para card y filtros*/}
                {true ?
                    ""
                    :
                    < Footer />
                }
                {!probarSiHay ?
                    ""
                    :
                    <MenuAbajoCat />
                }
            </div>
            <VerdInfoProducto />
            <FloatingButton />
        </div>

    )

}


export default VerCategoria;