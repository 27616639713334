import React, { useContext, useEffect, useState } from 'react'
import HorizontalScroll from 'react-horizontal-scrolling'
import "./horizontalScroll.css"
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';


import { Link } from 'react-router-dom'

const ArrayLinkMenu = [
    {
        urlLink:"vercatategoria/31",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png",
        titleLink:"Casas alq"
    },
    {
        urlLink:"vercatategoria/32",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png",
        titleLink:"Casas vent"
    },
    {
        urlLink:"vercatategoria/11",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png",
        titleLink:"Telefonos"
    },
    {
        urlLink:"vercatategoria/13",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206420/1_15_uutaaq.png",
        titleLink:"Ordena.."
    },









    {
        urlLink:"vercatategoria/5",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png",
        titleLink:"Coches"
    },
    {
        urlLink:"vercatategoria/41",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206421/1_17_gn0xrm.png",
        titleLink:"Clases part."
    },
    {
        urlLink:"vercatategoria/69",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206436/1_1_lojfds.png",
        titleLink:"Pelucas"
    },
    {
        urlLink:"vercatategoria/64",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_100,h_120,g_face/v1654208346/51_hcwj7m.png",
        titleLink:"Ropas muj."
    },
    {
        urlLink:"vercatategoria/65",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_2_ppvxvy.png",
        titleLink:"Ropas hom."
    },
    {
        urlLink:"vercatategoria/62",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206425/1_5_wjih4u.png",
        titleLink:"Zapatos m.."
    },


    {
        urlLink:"vercatategoria/61",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_350,g_face/v1654206434/1_4_pryd0o.png",
        titleLink:"Ropas hom."
    },
    {
        urlLink:"vercatategoria/12",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206418/1_9_xf6dce.png",
        titleLink:"Television"
    },

    {
        urlLink:"vercatategoria/48",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206422/1_16_eurgzy.png",
        titleLink:"Limpieza"
    },
    {
        urlLink:"vercatategoria/24",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png",
        titleLink:"Antenas"
    },

    {
        urlLink:"vercatategoria/23",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206419/1_11_vxhkqp.png",
        titleLink:"Router"
    },
    {
        urlLink:"vercatategoria/25",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206419/1_10_zxcina.png",
        titleLink:"Modem"
    },

    {
        urlLink:"vercatategoria/17",
        urlIcono:"https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654245194/70_duxjxg.png",
        titleLink:"Arduino"
    }

]
function HorizontalScrollHome() {
    const [items, setItems] = useState("");
    const [selected, setSelected] = useState([]);
    const [position, setPosition] = useState(0);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    return (

        <div className='scroll-horizontall-container' style={{marginTop:"90px"}}>
            <div className='scroll-horizontall-container-resp'>
                <ScrollMenu >
                    {ArrayLinkMenu.map((i,id) => (

                        <div
                            itemId={id} // NOTE: itemId is required for track items
                            title={id}
                            key={id}
                            selected={isItemSelected(id)}
                            className="icon-titulo-1 ">
                            <Link className="imgen-icono" to={i.urlLink}>

                                <img src={i.urlIcono} className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="vercatategoria/31" >{i.titleLink}</Link>
                        </div>


                    ))}
                </ScrollMenu>
            </div>
        </div>

    )
}

export default HorizontalScrollHome
