import React, { useContext, useEffect, useState } from 'react'
import "./carrouselServicios.css"
import { Link } from 'react-router-dom';
//import OwlCarousel from 'react-owl-carousel';
//import "owl.carousel/dist/assets/owl.carousel.min.css"
//import "owl.carousel/dist/assets/owl.theme.default.min.css"
import 'animate.css';
import AppContext from '../../services/contextApp';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



import { AGENCIAS_TIENDAS, SERVER_URL } from '../../contexts/constantesVar';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';




function CarrouselServicios() {
    const { dispatch, agenciasTiendas } = useContext(AppContext)
    const [agenciasTiendass, setAgenciasTiendas] = useState([])
    const [spinnerAgencias, setSpinnerAgencias] = useState(false)
    const options = {
        dots: false,
        responsiveClass: true,
        autoplay: false,
        nav: true,
        navClass: ["owl-prev", "owl-next"],
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            250: {
                items: 2,
            },
            350: {
                items: 2,
            },
            500: {
                items: 2,
            },
            650: {
                items: 3,
            },
            800: {
                items: 4,
            },
            860: {
                items: 4,
            },
            950: {
                items: 4,
            },
            1000: {
                items: 4,
            },

        },
    };

    const TraerAgenciasVentaTiendas = async () => {
        if (agenciasTiendas[0]["st"]) {

        } else {
            setSpinnerAgencias(true)
            try {
                const datos = await axios({ method: "post", data: {}, url: `${SERVER_URL}/obtener_tiendas_agencias_venta` })
                console.log(datos)
                if (datos.data.docs) {
                    console.log(datos)
                    dispatch({
                        type: AGENCIAS_TIENDAS,
                        payload: [{ st: true, data: datos.data.docs }]
                    })
                    setAgenciasTiendas(datos.data.docs)
                    setSpinnerAgencias(false)
                } else {
                    setSpinnerAgencias(false)
                }
            } catch (error) {
                setSpinnerAgencias(false)
            }
        }


    }

    useEffect(() => {
        TraerAgenciasVentaTiendas()
    }, [])


    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: "10px", marginTop: "-40px" }}>
                <div style={{ width: "950px" }}><h2 style={{ fontWeight: 600, marginLeft: "5px", color: "#b71c1c", textAlign: "center" }}>Tiendas</h2></div>
            </div>
            <section className="articulosCarrouselContainer">
                <div className='articulosCarrouselContainerTextYCaRD catBack1 z-depth-'>
                    <div className='TextCenterDiv'>
                        <h2></h2>
                    </div>
                    <div className='articulosCarrouselContainerResponContainer'>
                        <div className="articulosCarrouselContainerRespon">

                            {!spinnerAgencias ?
                                <OwlCarousel {...options} className=" owl-theme " >
                                    {/*
                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1690120854/logoGNOB_cfgg5s.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>
                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>


                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>



                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>


                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>




                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>




                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>



                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>

                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}></Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}></Link>
                                    </div>


                                </div>


                                <div className="targetasCarousel item z-depth-" >
                                    <div className='contenedorImagenCarrosel-container'>
                                        <div className="contenedorImagenCarrosel">
                                            <Link to={`/profinAgence/`} className="imagen-targeta">
                                                <img
                                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1693344520/sinFondoAsecna_rz2j1c.png'}
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="descripcionCarrousel">
                                        <Link className='title-agence-tienda' to={`/profinAgence/`}>EHG</Link>
                                        <Link className='descripcion-agence-tienda' to={`/profinAgence`}>Venta de ropas y de muebles</Link>
                                    </div>


                                </div>
                        */}

                                    {agenciasTiendas[0]["data"].map((x, y) =>


                                        <div className="targetasCarousel item z-depth-" >
                                            <div className="contenedorImagenCarrosel-container">
                                                <div className='contenedorImagenCarrosel'>
                                                    <Link to={`/tienda_agencia/${x.nombre}`} className="imagen-targeta">
                                                        {
                                                            <img
                                                                src={x.imgLogo}
                                                            />}
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="descripcionCarrousel">
                                                <Link className='title-agence-tienda' to={`/tienda_agencia/${x.nombre}`}>{x.nombre.slice(0, 15)}..</Link>
                                                <Link className='descripcion-agence-tienda' to={`/tienda_agencia/${x.nombre}`}>{x.descripcionAgence.slice(0, 50) + ".."}</Link>
                                            </div>


                                        </div>


                                    )}
                                </OwlCarousel>
                                :
                                <div className="spinner-cat" style={{ marginTop: "0px" }}>
                                    <MoonLoader color={"blue"} loading={true} size={40} />
                                    <div className="spinner-home-altura"></div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default CarrouselServicios