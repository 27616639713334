import React, { useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import M from "materialize-css"
import AppContext from '../../services/contextApp'

const Side = () => {
    const { } = useContext(AppContext)

    useEffect(() => {
        const all = document.querySelector(".sidenav")
        const collap = document.querySelector('.collapsible')
        M.Sidenav.init(all, {})
        M.Collapsible.init(collap, {
            preventScrolling: false,
            inDuration: 150,
        })
    })
    const Destruir = () => {
        const elem = document.querySelector(".sidenav")
        var instance = M.Sidenav.getInstance(elem);
        instance.close()
    }



    return (
        <ul id="slide-out-nav" className="collapsible  sidenav"/*inicio del colapsable del menu lateral */>

            <li>
                <div className="collapsible-header avatar-collecion" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png" />
                    Electronica
                </div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/1" className="collection-item blue-grey-text text-darken-4 "> Todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/11" className="collection-item blue-grey-text text-darken-4 ">Telefonos</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/13" className="collection-item blue-grey-text text-darken-4 ">Ordenadores</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/12" className="collection-item blue-grey-text text-darken-4 ">Televisiones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/14" className="collection-item blue-grey-text text-darken-4 ">Material informatico</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/18" className="collection-item blue-grey-text text-darken-4 ">Equipos de audiovision</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/15" className="collection-item blue-grey-text text-darken-4 ">Videojuegos</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/17" className="collection-item blue-grey-text text-darken-4 "> Arduino</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/16" className="collection-item blue-grey-text text-darken-4 "> Electrodomesticos</Link>
                    </div>
                </div>
            </li>

            <li>
                <div className="collapsible-header avatar-collecion">
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654240227/56_xucqe2.png" />
                    Casass en alquiler</div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/31" className="collection-item blue-grey-text text-darken-4" > Todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/311" className="collection-item blue-grey-text text-darken-4">Una habitacion</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/312" className="collection-item blue-grey-text text-darken-4">Dos habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/313" className="collection-item blue-grey-text text-darken-4">Tres habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/314" className="collection-item blue-grey-text text-darken-4">Cuatro habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/315" className="collection-item blue-grey-text text-darken-4">Cinco habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/316" className="collection-item blue-grey-text text-darken-4">Mas de cinco habitaciones</Link>
                    </div>
                </div>
            </li>
            <li>
                <div className="collapsible-header avatar-collecion">
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png" />
                    Casas en venta</div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/32" className="collection-item blue-grey-text text-darken-4" > Todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/311" className="collection-item blue-grey-text text-darken-4">Una habitacion</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/322" className="collection-item blue-grey-text text-darken-4">Dos habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/323" className="collection-item blue-grey-text text-darken-4">Tres habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/324" className="collection-item blue-grey-text text-darken-4">Cuatro habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/325" className="collection-item blue-grey-text text-darken-4">Cinco habitaciones</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/326" className="collection-item blue-grey-text text-darken-4">Mas de cinco habitaciones</Link>
                    </div>
                </div>
            </li>
            <li>
                <div className="collapsible-header avatar-collecion" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/v1667469676/hogar_vlmpoe.png" />
                    Hogar
                </div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/8" className="collection-item blue-grey-text text-darken-4 "> Todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/81" className="collection-item blue-grey-text text-darken-4 ">material de construccion</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/82" className="collection-item blue-grey-text text-darken-4 ">material para decorrar el hogar</Link>
                    </div>
                </div>
            </li>
            <li>
                <div className="collapsible-header avatar-collecion">
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png" />
                    Telecomunicacion
                </div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/2" className="collection-item  c7 blue-grey-text text-darken-4" > todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/21" className="collection-item c1 blue-grey-text text-darken-4">conectores rg45</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/22" className="collection-item c2 blue-grey-text text-darken-4" >cable ethernet</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/23" className="collection-item c3 blue-grey-text text-darken-4" >router</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/24" className="collection-item c4 blue-grey-text text-darken-4" >antenas</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/25" className="collection-item c5 blue-grey-text text-darken-4" >modem</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/26" className="collection-item c6 blue-grey-text text-darken-4" > switch</Link>
                    </div>
                </div>
            </li>
            <li>
                <div className="collapsible-header avatar-collecion">
                    <img src="https://res.cloudinary.com/mumbe/image/upload/c_thumb,w_200,g_face/v1639062139/trabajar_o64yj9.png" />
                    Servicios
                </div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/4" className="collection-item  c16 blue-grey-text text-darken-4" > Todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/41" className="collection-item c8 blue-grey-text text-darken-4">Clases particulares</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/42" className="collection-item c9 blue-grey-text text-darken-4" >Servicios de construccion</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/43" className="collection-item c10 blue-grey-text text-darken-4" >Servicios informaticos</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/44" className="collection-item c12 blue-grey-text text-darken-4" >Servicios de publicidad</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/45" className="collection-item c13 blue-grey-text text-darken-4" >Servicios en el area de sanidad</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/46" className="collection-item c14 blue-grey-text text-darken-4" >Buscar empleo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/47" className="collection-item c15 blue-grey-text text-darken-4" >Ofrecer de empleo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/48" className="collection-item c15 blue-grey-text text-darken-4" >Limpiesa a domicilio</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/49" className="collection-item c15 blue-grey-text text-darken-4" >Servicio de mecanico de coche</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/401" className="collection-item c15 blue-grey-text text-darken-4" >Reparacion de aparatos</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/402" className="collection-item c15 blue-grey-text text-darken-4" >servicios de fontaneria</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/403" className="collection-item c15 blue-grey-text text-darken-4" >servicios de coche grua</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/404" className="collection-item c15 blue-grey-text text-darken-4" >servicios de agua a domicilio</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/405" className="collection-item c15 blue-grey-text text-darken-4" >Lavado de coches a domicilio</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/406" className="collection-item c15 blue-grey-text text-darken-4" >Instalaciones electricas</Link>
                    </div>
                </div>
            </li>

            <li>
                <div className="collapsible-header avatar-collecion">
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png" />
                    Coches</div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/5" className="collection-item  c19 blue-grey-text text-darken-4" > Todo</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/51" className="collection-item c17 blue-grey-text text-darken-4">Coches en alquiler</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/52" className="collection-item c18 blue-grey-text text-darken-4" >Coches en venta</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/53" className="collection-item c18 blue-grey-text text-darken-4" >Taxis en alquiler</Link>
                    </div>
                </div>
            </li>

            <li>
                <div class="collapsible-header avatar-collecion">
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243221/67_zulzib.png" />
                    Vestimenta
                </div>
                <div className="collapsible-body">
                    <div className="collection">
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/6" className="collection-item  c24 blue-grey-text text-darken-4" > Todo</Link>

                        <Link onClick={() => { Destruir() }} to="/vercatategoria/61" className="collection-item c20 blue-grey-text text-darken-4">Zapatos para hombres</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/62" className="collection-item c21 blue-grey-text text-darken-4" >Zapatos para mujeres</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/63" className="collection-item c22 blue-grey-text text-darken-4" >Zapatos para niños</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/64" className="collection-item c23 blue-grey-text text-darken-4" >Ropas para mujeres</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/65" className="collection-item  c24 blue-grey-text text-darken-4" >Ropas para hombres</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/66" className="collection-item  c24 blue-grey-text text-darken-4" >Ropas para niños</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/67" className="collection-item  c24 blue-grey-text text-darken-4" >Venta de pelo para mujeres</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/68" className="collection-item  c24 blue-grey-text text-darken-4" >Aguacate</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/69" className="collection-item  c24 blue-grey-text text-darken-4" >Pelucas</Link>
                        <Link onClick={() => { Destruir() }} to="/vercatategoria/601" className="collection-item  c24 blue-grey-text text-darken-4" >Pulceras, relojes, y joyas</Link>
                    </div>
                </div>
            </li>


        </ul>
    )
}
export default Side