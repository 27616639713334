import React, { useContext } from "react"
import {
    BUSCAR, BUSQUEDA_RESULTADOS, CATEGORIA, CAT_PARA_PAGE, CIUDAD_POST,
    DATA_CATEGO, PAGINA_SIGUIENTE, PROBAR_SI_HAY, SERVER_URL
} from "../../contexts/constantesVar"
import M from "materialize-css"
import axios from "axios"



export const BuscarForm = async (texto, dispatch, pais_post) => {

    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
    //console.log(busquedaClave, "ffffffffffffffffffffffffffffffffffffffffff")
    console.log(texto)
    const claves = texto

    //setCatParaPage(e.target.buscar.value)
    dispatch({
        type: CAT_PARA_PAGE,
        payload: claves
    })
    dispatch({
        type: BUSQUEDA_RESULTADOS,
        payload: claves
    })
    dispatch({
        type: CATEGORIA,
        payload: "clave"
    })
    dispatch({
        type: CIUDAD_POST,
        payload: ""
    })
    try {
        dispatch({
            type: PROBAR_SI_HAY,
            payload: false
        })
        //setProbarSiHay(false)
        const datos = await axios({
            method: 'post',
            data: {
                'clave': claves,
                "pais": pais_post,
                "ciudad": "" /*ciudad_post*/
            }, url: `${SERVER_URL}/buscar`
        })
        if (datos.data.docs[0]) {
            dispatch({
                type: PROBAR_SI_HAY,
                payload: true
            })
            //setProbarSiHay(true)

            dispatch({
                type: DATA_CATEGO,
                payload: datos.data.docs
            })
            //setDatosArticulos(datos.data.docs)
            if ((datos.data.docs != "")/*&&(datos.data.docs[0]["categoria"]!=datosArticulosCateg[0]["categoria"])*/) {
                dispatch({
                    type: DATA_CATEGO,
                    payload: datos.data.docs
                })
                //setDatosArticulosCateg(datos.data.docs)
            }

            dispatch({
                type: PAGINA_SIGUIENTE,
                payload: datos.data.nextPage
            })
            //setPaginaSiguiente(datos.data.nextPage)
            console.log(datos.data)
        } else {
            //setDatosArticulos([])
            dispatch({
                type: DATA_CATEGO,
                payload: []
            })
            //setProbarSiHay(true)
            dispatch({
                type: PROBAR_SI_HAY,
                payload: true
            })
        }
    } catch {
        var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
        M.toast({ html: toastHTML });
        dispatch({
            type: PROBAR_SI_HAY,
            payload: true
        })
        //setProbarSiHay(true)
    }
    //setBuscar("")
    dispatch({
        type: BUSCAR,
        payload: ""
    })
    //Validar()
}