import React, { useContext, useEffect, useState } from "react"
import {
    BUSCAR, BUSQUEDA_RESULTADOS, CATEGORIA, CAT_PARA_PAGE, CIUDAD_POST,
    DATA_CATEGO, PAGINA_SIGUIENTE, PAIS_POST, PRECIO1, PRECIO2, PROBAR_SI_HAY
} from "../contexts/constantesVar"
import M from "materialize-css"
import axios from "axios"
import AppContext from "../services/contextApp"
import { FiltrarPorPrecio } from "./filtrarPorPrecio"


function FiltraComponente() {
    const { validar_user, precio1, precio2, dispatch, busquedaClave, catParaPage, categoria, pais_post,
        ciudad_post, user_id, probarSiHay, paginaSiguiente, ArticulosSingleDB, dataCategoria, CallDataCat, PaginacionConScroll } = useContext(AppContext)
    const [pais, setPais] = useState("01")
    const [ciudad, setCiudad] = useState("")
    const [precio11, setPrecio1] = useState("")
    const [precio22, setPrecio2] = useState("")

    const Destruir = () => {
        const elem = document.querySelector(".allFilter")
        var instance = M.Sidenav.getInstance(elem);
        instance.close()
    }
    return (
        <ul id="slide-out-navFilter" className="sidenav allFilter   filterSide">
            <div className='sideNavFilter'>
                <div className='sideNavPuedesFiltrarPor'>
                    <h6>Pudes filtrar por :{pais_post}</h6>
                </div>
                {false ?
                    <div className="input-field ">
                        <select className="browser-default selectFormFiltro" onChange={(e) => { setPais(e.target.value); setCiudad("") }} >
                            <option disabled selected>Elige tu pais</option>
                            <option value="01" >Guinea Ecuatorial</option>
                            <option value="02" >Cameroun</option>
                            <option value="07" >Cote d`Ivoire</option>
                            <option value="03" >Senegal</option>
                            <option value="06" >Gabon</option>
                            <option value="05" >Benin</option>
                            <option value="08" >Centro Africa</option>
                            <option value="09" >Congo Kinsaza</option>

                        </select>
                    </div> : ""}
                {true ?
                    <div className="input-field ">
                        <select id="ciudadM" className="browser-default selectFormFiltro" onChange={(e) => { setCiudad(e.target.value) }} >
                            <option disabled selected>Elige la ciudad</option>
                            <option value="" >Todo</option>
                            <option value="011" >Malabo</option>
                            <option value="012" >Bata</option>
                            <option value="013" >Ebibeyin</option>
                            <option value="014" >Mongomo</option>
                            <option value="015" >Annobon</option>
                        </select>
                    </div>
                    :
                    ""
                }


                {/*pais === "02"*/false ?
                    <div className="input-field ">
                        <select className="browser-default selectFormFiltro" onChange={(e) => { setCiudad(e.target.value) }} >
                            <option disabled selected>Elige la ciudad</option>
                            <option value="021" >Duoila</option>
                            <option value="022" >Bamenda</option>
                        </select>
                    </div>
                    :
                    ""
                }

                <div className="input-field ">
                    <select id="precioM" className="browser-default selectFormFiltro" onChange={(e) => {
                        //console.log(Number(e.target.value.split(",")[0]))
                        //console.log(Number(e.target.value.split(",")[1]))
                        setPrecio1(Number(e.target.value.split(",")[0]));
                        setPrecio2(Number(e.target.value.split(",")[1]));
                        /*Number(e.target.value.split(",")[1])*/
                    }} >
                        <option disabled selected>filtrar precio</option>
                        <option value={[0, 0]} >Todos</option>
                        <option value={[-1, 10000]} >menos de 10.000 fxa</option>
                        {/* <option value={[10000, 20000]} >10.000 a 20.000</option> */}
                        <option value={[20000, 50000]} >menos de 50.000</option>
                        <option value={[50000, 100000]} >menos de  100.000</option>
                        {/* <option value={[100000, 250000]} >100.000 a 250.000</option> */}
                        <option value={[250000, 500000]} >menos de 500.000</option>
                        <option value={[500000, 1000000]} >menos de 1.000.000</option>
                        <option value={[1000000, 5000000]} >menos de 5.000.000</option>
                        <option value={[5000000, 10000000]} >menos de 10.000.000</option>
                        <option value={[10000000, 50000000]} >menos de 50.000.000</option>
                        <option value={[50000000, 99000000]} >menos de 100.000.000</option>
                        <option value={[99000000, 999000000]} >menos de 999.000.000</option>

                    </select>
                </div>

                <div className='sideNavbtnFiltrar'>
                    <button onClick={() => { FiltrarPorPrecio(pais, dispatch, ciudad, precio11, precio22, busquedaClave, categoria, pais_post); Destruir() }} className='btn-small'>
                        Filtrar
                    </button>
                </div>
            </div>



        </ul>
    )
}

export default FiltraComponente


