import React,{useContext} from "react"
import { BUSCAR, BUSQUEDA_RESULTADOS, CATEGORIA, CAT_PARA_PAGE, CIUDAD_POST, DATA_CATEGO, PAGINA_SIGUIENTE, PROBAR_SI_HAY, SERVER_URL } from "../contexts/constantesVar"
import AppContext from "../services/contextApp"
import M from "materialize-css"
import axios from "axios"





export const ArticulosSingleDB = async (id ,dispatch ,pais_post ,ciudad_post) => {
    //console.log(pais_post , ciudad_post ,"ffffffffffffffffqqqqqqqqqq")
    dispatch({
        type: BUSCAR,
        payload: ""
    })

    dispatch({
        type: CAT_PARA_PAGE,
        payload: id
    })
    //setCatParaPag(id)
    dispatch({
        type: CIUDAD_POST,
        payload: ""
    })
    try {
        dispatch({
            type: PROBAR_SI_HAY,
            payload: false
        })
        //setProbarSiHay(false)
        if (Number(id)) {
            dispatch({
                type: CATEGORIA,
                payload: id
            })
            const datos = await axios({
                method: 'post',
                data: { "pais": pais_post, "ciudad": ciudad_post },
                url: `${SERVER_URL}/ver_articulo/${id}`
            })

            if (datos.data.docs[0]) {
                dispatch({
                    type: PROBAR_SI_HAY,
                    payload: true
                })
                //setProbarSiHay(true)
                dispatch({
                    type: DATA_CATEGO,
                    payload: datos.data.docs
                })
            
                //setDatosArticulos(datos.data.docs)

                //setPaginaSiguiente(datos.data.nextPage)
                dispatch({
                    type: PAGINA_SIGUIENTE,
                    payload: datos.data.nextPage
                })
                if ((datos.data.docs != "")) {
                    dispatch({
                        type: DATA_CATEGO,
                        payload: datos.data.docs
                    })
                    //setDatosArticulosCateg(datos.data.docs)
                }
                //console.log(datos.data, "cogiendo 2")
            } else {
                dispatch({
                    type: DATA_CATEGO,
                    payload: []
                })
                console.log("vacioooooooooooooooooo")
                dispatch({
                    type: PROBAR_SI_HAY,
                    payload: true
                })
            }

        } else {
            dispatch({
                type: BUSQUEDA_RESULTADOS,
                payload: id
            })
            const datos = await axios({
                method: 'post',
                data: { 'clave': id, "pais": pais_post, "ciudad": ciudad_post },
                url: `${SERVER_URL}/buscar`
            })
            //console.log("esta en buacar")
            if (datos.data.docs[0]) {
                dispatch({
                    type: PROBAR_SI_HAY,
                    payload: true
                })
                //setProbarSiHay(true)

                dispatch({
                    type: DATA_CATEGO,
                    payload: datos.data.docs
                })
                //setDatosArticulos(datos.data.docs)
                if ((datos.data.docs != "")) {
                    dispatch({
                        type: DATA_CATEGO,
                        payload: datos.data.docs
                    })
                    //setDatosArticulosCateg(datos.data.docs)
                }
                //setPaginaSiguiente(datos.data.nextPage)
                dispatch({
                    type: PAGINA_SIGUIENTE,
                    payload: datos.data.nextPage
                })
                //console.log(datos.data, "cogiendo 1")
            } else {
                dispatch({
                    type: DATA_CATEGO,
                    payload: []
                })
                //setDatosArticulos([])
                dispatch({
                    type: PROBAR_SI_HAY,
                    payload: true
                })
                //setProbarSiHay(true)

            }
        }
    } catch {
        var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
        M.toast({ html: toastHTML });
        dispatch({
            type: PROBAR_SI_HAY,
            payload: true
        })
        //setProbarSiHay(true)
    }

}