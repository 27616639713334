import React, { useContext, useEffect, useState } from "react";
import TimeAgo from 'timeago-react';
import { format, register } from 'timeago.js';
import { MoonLoader } from "react-spinners";
import AppContext from "../services/contextApp";
import BarradeNavegacion from "../components/navegacionBarAll/BarradeNavegacion";
import MenuAbajo from "../components/menuAbajo";
import FloatingButton from "../components/floatingButton";
import M from "materialize-css"
import { Link, useParams } from "react-router-dom"
import { IoMdTime } from "react-icons/io";
import axios from "axios"
import { SERVER_URL } from "../contexts/constantesVar";
import ProfilAgenciaCuenta from "../components/InfoprofileUser/profilAgenciaCuenta";
import BannerProfilUser from "../components/bannerProfilUser/bannerProfilUser";
import InfoAgence from "../components/infoAgence/infoAgence";
import AñadirImagenesAgencia from "../components/añadirImagenesAgencia/añadirImagenesAgencia";
import AgenciasDescripcion from "../components/agenciasDescrip/agenciasDescripcion";
import BannerAgenciaTienda from "../components/bannerAgenciasTiendas/bannerAgenciaTienda";
import EditarProducto from "../components/editarProducto/editarProducto";


const Profil = (props) => {
    const { history } = props
    register('es_ES', (number, index, total_sec) => [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 dia', 'en 1 dia'],
        ['hace %s dias', 'en %s dias'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años']
    ][index]);
    const timeago = timestamp => format(timestamp, 'es_ES')
    const { id } = useParams()
    const { validar_user, user_name, user_id } = useContext(AppContext)

    const [datosArticulos, setDatosArticulos] = useState([])
    const [agenciasTiendas, setAgenciasTiendas] = useState([])
    const [probar, setProbar] = useState(false)
    const [spinnerModif, setSpinnerModif] = useState(false)
    const [spinnerUserData, setSpinnerUserData] = useState(false)
    const [numeroDeArti, setNumeroDeArti,] = useState("0")
    const [imagen1, setImagen1] = useState('')
    const [datoProduct, setDatoProduct] = useState([])
    const [imagen2, setImagen2] = useState('')
    const [imagen3, setImagen3] = useState('')
    const [imagen4, setImagen4] = useState('')
    const [preview1, setPreview1] = useState("");
    const [preview2, setPreview2] = useState("");
    const [preview3, setPreview3] = useState("");
    const [preview4, setPreview4] = useState("");

    const [nombreAgence, setNombreAgence] = useState("")
    const [emailAgence, setEmailAgence] = useState("")
    const [phoneAgence, setPhoneAgence] = useState("")

    const [nombreAgenceTienda, setNombreAgenceTienda] = useState("")
    const [phoneTienda, setPhoneTienda] = useState("")
    const [emailAgenceTienda, setEmailAgenceTienda] = useState("")
    const [paisTienda, setPaisTienda] = useState("")
    const [ciudadTienda, setCiudadTienda] = useState("")
    const [breveDescripcionTiendaCorta, setBreveDescripcionTiendaCorta] = useState("")
    const [imgLogo, setImgLogo] = useState("")
    const [imgPreview, setImgPreview] = useState(null)

    const WIDTH = 500


    //PREVISUALIZAR IMAGEN DEL LOGO
    const VerImgLogo = (e) => {
        console.log(e)
        if (e) {
            setImgLogo(e)
            //OPTIMIZACION DEL IMAGEN4
            const reader = new FileReader()
            reader.readAsDataURL(e)
            /*
            reader.onload = (event) => {
                let img_url = event.target.result
                setImgPreview(img_url);

            }
*/

            reader.onload = (event) => {
                let img_url = event.target.result
                //console.log(img_url)

                let image = document.createElement("img")
                image.src = img_url
                image.onload = async (e) => {
                    //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                    let canvas = document.createElement("canvas")
                    let ratio = WIDTH / e.target.width
                    canvas.width = WIDTH
                    canvas.height = e.target.height * ratio
                    //crear objeto canvas 
                    const context = canvas.getContext("2d")

                    context.drawImage(image, 0, 0, canvas.width, canvas.height)
                    context.strokeStyle = "#ffffff"
                    context.shadowColor = "rgba(255, 255, 255, 1)"
                    context.globalAlpha = 1
                    context.imageSmoothingEnabled = false
                    context.shadowBlur = 1
                    context.fillStyle = "#ffffff"


                    let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                    //console.log(context)


                    //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                    const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                    const img_convert_to_blob = await img_fetch.blob("image/png")

                    setImgLogo(img_convert_to_blob)
                    //console.log(img_convert_to_blob)
                    setImgPreview(new_img_url);
                }


            }

        }
    }

    const TraerAgenciasVentaTiendas = async () => {
        setSpinnerUserData(false)
        try {
            const datos = await axios({ method: "post", data: {}, url: `${SERVER_URL}/obtener_user_agencias_tiendas_simple/${id}` })
            //console.log(datos.data, "WAEEEEEEEEEEEEEEEEEEE")
            if (datos.data) {
                setSpinnerUserData(true)
                setAgenciasTiendas(datos.data)
                setNombreAgenceTienda(datos.data.nombre)
                setPhoneTienda(datos.data.contact)
                setEmailAgenceTienda(datos.data.email)
                setPaisTienda(datos.data.paiz)
                setCiudadTienda(datos.data.ciudad)
                setBreveDescripcionTiendaCorta(datos.data.descripcionAgence)
                setImgPreview(datos.data.imgLogo)
            } else {
                setSpinnerUserData(true)
            }
        } catch (error) {
            setSpinnerUserData(true)
        }

    }


    const ArticulosSingleDB = async () => {
        //console.log(id)
        setProbar(false)
        try {
            const datos = await axios({
                method: 'post',
                url: `${SERVER_URL}/articulos_user/${id}`
            })

            if (datos.data) {
                setProbar(true)
                setDatosArticulos(datos.data.docs)
                setNumeroDeArti(datos.data.docs.length)
                //console.log(datos.data.docs)
            }

        } catch {
            setProbar(true)
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
        }
    }

    const EliminarProduct = async (id) => {
        var validarEliminar = window.confirm("Seguro que quieres eliminar este anuncio !")
        if (validarEliminar) {
            try {
                const datos = await axios({
                    method: 'post',
                    url: `${SERVER_URL}/user_eliminar_product/${id}`
                })
                //console.log(datos.data)
                if (datos.data) {

                    await ArticulosSingleDB()
                    var toastHTML = '<span>' + `${datos.data}` + '</span>';
                    M.toast({ html: toastHTML });
                }

            } catch {
                setProbar(false)
                var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
                M.toast({ html: toastHTML });
            }
        } else {

        }

    }

    const ControlarInicio = () => {
        if (validar_user) {
            ArticulosSingleDB()
        } else {
            history.push(`/iniciar`)
        }
    }




    //funcion para enviar los datos en el api y asi guardarlos
    const GuardarProducto = async e => {
        e.preventDefault();


        setSpinnerModif(true)
        //console.log(categoria, subCateg, subSubCateg)
        const fs = new FormData()
        fs.append("user", user_name ? user_name : agenciasTiendas.nombre)
        fs.append("user_id", agenciasTiendas._id)
        fs.append("contact", phoneTienda ? phoneTienda : agenciasTiendas.contact)
        fs.append("nombre", nombreAgenceTienda ? nombreAgenceTienda : agenciasTiendas.nombre)
        fs.append("email", emailAgenceTienda ? emailAgenceTienda : agenciasTiendas.email)
        fs.append("paiz", paisTienda ? paisTienda : agenciasTiendas.paiz)
        fs.append("ciudad", ciudadTienda ? ciudadTienda : agenciasTiendas.ciudad)
        fs.append("descripcionAgence", breveDescripcionTiendaCorta ? breveDescripcionTiendaCorta : agenciasTiendas.descripcionAgence)
        fs.append("imagen1", imgLogo ? imgLogo : agenciasTiendas.imgLogo)

        console.log(imgLogo)


        try {
            const respuesta = await axios.post(`${SERVER_URL}/edit_profil`, fs, { headers: { "Content-Type": "multipart/form-data" } })
            if (respuesta.data.mens === "Datos modificados") {
                setSpinnerModif(false)

                setAgenciasTiendas(respuesta.data.user)
                setNombreAgenceTienda(respuesta.data.user.nombre)
                setPhoneTienda(respuesta.data.user.contact)
                setEmailAgenceTienda(respuesta.data.user.email)
                setPaisTienda(respuesta.data.user.paiz)
                setCiudadTienda(respuesta.data.user.ciudad)
                setBreveDescripcionTiendaCorta(respuesta.data.user.descripcionAgence)
                setImgPreview(respuesta.data.user.imgLogo)
                const alls = document.querySelector(".modal-edite-perfil")
                var instance = M.Modal.getInstance(alls);
                instance.close()
                TraerAgenciasVentaTiendas()

                var toastHTML = '<span>' + respuesta.data.mens + '</span>';
                M.toast({ html: toastHTML });

                //FIN DE  RESETEAR FORMULARIO 

            } else {
                setSpinnerModif(false)
                var toastHTML = '<span>' + respuesta.data.mens + '</span>';
                M.toast({ html: toastHTML });
            }
        } catch {
            setSpinnerModif(false)
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
        }

    }

    useEffect(() => {
        const alls = document.querySelector(".modal-edite-perfil")
        M.Modal.init(alls, {
            preventScrolling: false,
            inDuration: 50,
            outDuration: 50,
            onCloseStart: () => {

                setNombreAgenceTienda(agenciasTiendas.nombre)
                setPhoneTienda(agenciasTiendas.contact)
                setEmailAgenceTienda(agenciasTiendas.email)
                setPaisTienda(agenciasTiendas.paiz)
                setCiudadTienda(agenciasTiendas.ciudad)
                setBreveDescripcionTiendaCorta(agenciasTiendas.descripcionAgence)
                setImgPreview(agenciasTiendas.imgLogo)
                setImgLogo("")

            },
            onOpenStart: () => {
            }
        })
        ControlarInicio()
        TraerAgenciasVentaTiendas()


        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        if (imgLogo) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImgPreview(reader.result);

            }
            reader.readAsDataURL(imgLogo)
        } else {
            setImgPreview(null)
        }
    }, [])
    return (
        <div>
            <BarradeNavegacion />
            {spinnerUserData && agenciasTiendas && false ?
                <>
                    <BannerAgenciaTienda imgAgencia={agenciasTiendas["imgLogo"]} />
                    <AgenciasDescripcion

                        descripcion={agenciasTiendas["descripcionAgence"]}
                        nombre={agenciasTiendas["nombre"]}
                        numeroT={agenciasTiendas["numeroT"] ? agenciasTiendas["numeroT"] : " "}
                        numeroW={agenciasTiendas["numeroW"] ? agenciasTiendas["numeroW"] : " "}
                        servicios={agenciasTiendas["servicios"] ? agenciasTiendas["servicios"] : " "}
                        categoria={agenciasTiendas["categoria"]}
                    />
                </>
                :
                <></>
            }
            {spinnerUserData && agenciasTiendas.imgLogo ?
                <>
                    <div className='container-logo-text' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "70px" }}>
                        <div className='container-log-resp' style={{ width: "200px", height: "200px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", backgroundColor: "rgba(0, 0, 0, 0.5)", border: "2px solid rgb(61, 61, 61)" }}>
                            {<img src={agenciasTiendas.imgLogo} style={{ width: "130px", objectFit: "cover" }} loader='lazy' />}
                        </div>

                    </div>
                    <div className='container-text-resp' style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <h2>{agenciasTiendas.nombre}</h2>
                    </div>
                    <div className='container-text-resp' style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "-30px", display: "none" }}>
                        <p>{agenciasTiendas.paiz} - {agenciasTiendas.ciudad} - {agenciasTiendas.numeroT.slice(0, 3)} {agenciasTiendas.numeroT.slice(3, 6)} {agenciasTiendas.numeroT.slice(6,)}</p>
                    </div>
                </>
                :
                <></>
            }

            {spinnerUserData && agenciasTiendas.imgLogo ?
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign:"center",marginBottom:"10px"}}>
                    <span style={{marginBottom:"5px"}}>Comparte el siguiente enlace de tu tienda con tus clientes</span> 
                    <span style={{color:"red"}}>https://compraventaeg.com/#/tienda_agencia/{agenciasTiendas.nombre}</span>
                </div>
                :
                ""
            }
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Link to="#" onClick={() => {
                    //setBuscar('')
                    //Buscar()
                }} style={{ cursor: "pointer", backgroundColor: "#fff", color: "#212121" }} data-target="modal-edite-perfil" className='modal-trigger btn-small'>Editar perfil</Link>
            </div>
            {spinnerUserData && agenciasTiendas && agenciasTiendas["categoria"] === "011111" ?
                <>
                    {

                    }
                    <AñadirImagenesAgencia
                        codeVery={agenciasTiendas["codeVery"] ? agenciasTiendas["codeVery"] : " "}
                        user_id={id}
                    />
                </>
                :
                <></>

            }
            {spinnerUserData && agenciasTiendas && probar ?
                <>
                    <div className="numberOfProducts">
                        <p>Hola {user_name} ! tus articulos publicados</p>
                        <h1>{numeroDeArti.toLocaleString("es-GQ")}</h1>
                    </div>

                    <div className="profilContainerCard">
                        <div className="profilContainerCardResp">

                            {datosArticulos.map((art) => <div className="cardAnuncioContainer">
                                {/*<div className="cardAnuncioAnuncioYBotones">*/}
                                <div className="cardAnuncioAnuncio">
                                    <div className="foto">
                                        <img src={art["imagen1"]} />
                                    </div>
                                    <div className="anuncioText">
                                        <div className="description">
                                            <p className="descriptionText">{art["nombre"]}</p>
                                            {art["precio"] ?
                                                <p className="precioProfil">{art["precio"].toLocaleString("es-GQ")} xfa</p>
                                                :
                                                <p className="precioProfil">{art["precioString"]}</p>
                                            }
                                            <p className="tiempoHace"><IoMdTime color="rgb(250, 0, 0)" size={20} /> {timeago(`${art["fecha"]}`)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cardAnuncioBotones">
                                    <div className="cardAnuncioBotonesCenter">
                                        <Link to="#" onClick={() => { EliminarProduct(art["_id"]) }} className="btn-small eliminar">eliminar</Link>
                                        <Link to="#" className=' modal-trigger btn-small editar' data-target="modal-edite-product" style={{}} onClick={() => { setDatoProduct(art) }} >editar</Link>
                                        <Link to="#" style={{ display: "none" }} onClick={() => { alert("Todavia esta funcion no esta activada !") }} className="btn-small desactivar">desactivar </Link>
                                    </div>

                                </div>

                                {/*</div>*/}
                            </div>)}

                        </div>
                    </div>
                </>
                :
                <div className="spinner-cat">
                    <MoonLoader color={"blue"} loading={true} size={40} />
                    <div className="spinner-home-altura"></div>
                </div>
            }
            <EditarProducto data={datoProduct} />

            {true ?
                <form id='modal-edite-perfil' className="formulario-agregar-form z-depth-1 modal modal-edite-perfil" style={{ width: "50% !important", paddingInline: '20px' }} onSubmit={(e) => { GuardarProducto(e) }}  >
                    <div style={{ paddingInline: "6px" }}>
                        <div className="input-field  ">

                            <input
                                value={nombreAgenceTienda}
                                id="nombreAgencia"
                                type="text"
                                className=""
                                name="nombreAgencia"
                                placeholder="Nombre de la agencia o tienda"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setNombreAgenceTienda(e.target.value)
                                    } else {
                                        setNombreAgenceTienda("")
                                    }
                                }}

                            />

                        </div>

                        <div className="input-field   col s12">

                            <input
                                value={emailAgenceTienda}
                                type="email"
                                id="emailAgencia"
                                className="input-text"
                                name="emailsAgencia"
                                placeholder="vuestro correo"
                                onChange={(e) => {
                                    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                                    if (e.target.value) {

                                        setEmailAgenceTienda(e.target.value)
                                    } else {

                                        setEmailAgenceTienda("")
                                    }
                                }}

                            />
                        </div>

                        <div className="input-field contenedor-input  col s12">

                            <input
                                value={phoneTienda}
                                type="text"
                                className="input-text"
                                name="phoneAgencia"
                                id="phoneAgencia"
                                placeholder="Numero de telefono"
                                onChange={(e) => {

                                    if (e.target.value) {

                                        setPhoneTienda(e.target.value)
                                    } else {

                                        setPhoneTienda("")
                                    }
                                }}

                            />
                        </div>
                        <div className="input-field contenedor-input subcat ">
                            <select
                                value={paisTienda}
                                className="browser-default"
                                id="paisAgencias"
                                name="paisAgencias"
                                onChange={(e) => {

                                    if (e.target.value) {
                                        setPaisTienda(e.target.value);
                                    } else {
                                        setPaisTienda("");
                                    }
                                }} >
                                <option disabled selected>Elige tu pais</option>
                                <option value="Guinea Ecuatorial" >Guinea Ecuatorial</option>
                                <option disabled value="Cameroun" >Cameroun</option>
                                <option disabled value="Costa de Marfil" >Costa de Marfil</option>
                                <option disabled value="Senegal" >Senegal</option>
                                <option disabled value="Gabon" >Gabon</option>
                                <option disabled value="Benin" >Benin</option>
                                <option disabled value="Centro Africa" >Centro Africa</option>
                                <option disabled value="Congo Kinsaza" >Congo Kinsaza</option>
                            </select>
                        </div>
                        <div className="input-field contenedor-input subcat ">
                            <select
                                value={ciudadTienda}
                                className="browser-default"
                                name="ciudadAgencias"
                                id="ciudadAgencias"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setCiudadTienda(e.target.value);
                                    } else {
                                        setCiudadTienda("")
                                    }
                                }} >
                                <option disabled selected>Elige la ciudad</option>
                                <option value="Malabo" >Malabo</option>
                                <option value="Bata" >Bata</option>
                                <option value="Oyala" >Oyala</option>
                                <option value="Ebibeyin" >Ebibeyin</option>
                                <option value="Mongomo" >Mongomo</option>
                                <option value="Luba" >Luba</option>
                                <option value="Annobon" >Annobon</option>
                            </select>
                        </div>

                        <div className="input-field ">
                            <textarea
                                value={breveDescripcionTiendaCorta}
                                id="descripcionAgencia"
                                name=""
                                className="textareaCount  materialize-textarea"
                                data-length="200"
                                placeholder="Servios o productos que ofreceis"
                                onChange={(e) => {

                                    if (e.target.value) {
                                        setBreveDescripcionTiendaCorta(e.target.value);
                                    } else {
                                        setBreveDescripcionTiendaCorta("")
                                    }
                                }}></textarea>
                        </div>


                        <div className="file-select btn foto-principal" id="src-fileRegist" >
                            <input onChange={(e) => { VerImgLogo(e.target.files[0]) }} className="input-count" type="file" name="src-file1" aria-label="Archivo" />
                        </div>
                        <div className="imagenes-elegidas" >
                            <img src={imgPreview} alt="" />
                        </div>

                        {spinnerModif ?
                            <div className="contenedor-spinner">
                                <div>
                                    <p>Modificando...</p>
                                </div>
                                <div className="spinners">
                                    <MoonLoader color={"blue"} loading={true} size={30} />

                                </div>
                            </div>
                            : ""
                        }
                        <div className="input-field input-submit-btn ">
                            <button type="submit" className="btn">Editar</button>
                        </div>
                    </div>
                </form>
                :
                <></>
            }

            <FloatingButton />
            <MenuAbajo />
        </div>
    )
}

export default Profil;