import React from 'react';


function PageNotFoun(props){
    return(
        <div>
            <h1>error 401</h1>
        </div>    
    )
}

export default PageNotFoun;