import React from 'react';
import ReactDOM from "react-dom/client";

import App from './App'
import './styles/Index.css'
import './css/materialize.min.css'
import './css/materialize-icons.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import "react-lazy-load-image-component/src/effects/blur.css"




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


