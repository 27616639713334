import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import { css } from "@emotion/react";
import { ClipLoader, MoonLoader,ScaleLoader ,PulseLoader} from "react-spinners";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaFrown } from 'react-icons/fa';
import BarradeNavegacion from "../components/navegacionBarAll/BarradeNavegacion";
import AppContext from "../services/contextApp";
import Footer from '../components/Footer'
import MenuAbajo from "../components/menuAbajo";
import { RESP_ERROR_LOGIN } from "../contexts/constantesVar";
import 'animate.css';
import '../styles/Formulario.css'




const Login = (props) => {
    const { history } = props
    const [user, setUser] = useState({
        email: '',
        contrasena: ''
    })
    const { Logins, dispatch, loginSpinner, respServerUserUndefined } = useContext(AppContext)
    const navigate = useNavigate();


    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password1, setPassword1] = useState("")
    const [errors, setErrors] = useState("")


    const Enviar = async (e) => {
        e.preventDefault();
        const password2 = e.target.contrasena.value.trim()
        const email2 = e.target.email.value.trim()
        //console.log(password2, email2)

        //console.log(loginSpinner)

        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (email2.match(mailformat)) {
            if (email2 && password2) {
                Logins(navigate, email2, password2)
                setErrors("")
            } else {
                setErrors("todos los campos son obligatorios")
            }
        } else {
            setErrors("introduce un email valido")
        }




    }

    const MensageForm = () => {
        return (
            <>
                <p className='animate__shakeX animate__animated animate__repeat-1'>{respServerUserUndefined && errors === "" ? respServerUserUndefined : ""}</p>
                <p className='animate__shakeX animate__animated animate__repeat-1'>{respServerUserUndefined === "" && errors ? errors : ""}</p>
            </>
        )
    }

    useEffect(() => {
        setErrors("")
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <div>
            <BarradeNavegacion />

            <div className="formulario-agregar" id="form">
                <div className="formulario-agregar-respon container " >


                        
                        <form onSubmit={(e) => { Enviar(e) }} className="formulario-agregar-form z-depth-1">
                            <div className="input-field contenedor-input  col s12">

                                <input
                                    type="email"
                                    className="input-text"
                                    name="email" required
                                    id="email"
                                    placeholder="tu email"
                                />
                            </div>
                            <div className="input-field contenedor-input  col s12" >
                                <input
                                    type="password"
                                    className="input-text"
                                    name="contrasena"
                                    placeholder="tu contraseña"

                                />
                            </div>
                            <div className="respuestaError">
                                <MensageForm />
                            </div>
                            <div className="formularioReI-respon-cambio">
                                <p>Si no tienes una cuenta  </p><Link onClick={() => {
                                    dispatch({
                                        type: RESP_ERROR_LOGIN,
                                        payload: ""
                                    })
                                }} to="/registro">Registrate !</Link>
                            </div>
                            {/* {!loginSpinner ? espiner para el login
                                <div className="spinner-login" style={{display:"none"}}>
                                    <MoonLoader color={"blue"} loading={true} size={30} />
                                </div>
                                : ""} */}

                            <div className='boton-submit-container'>
                                <button
                                    className="formularioReI-form-btn  btn-small"
                                    type="submit"
                                    onClick={
                                        () => {

                                            dispatch({
                                                type: RESP_ERROR_LOGIN,
                                                payload: ""
                                            })
                                        }
                                    }
                                    
                                >
                                    {loginSpinner?<PulseLoader className="fff" color={"#212121"} loading={true} size={15} />:"iniciar !"}
                                </button>
                            </div>

                        </form>

                </div>

            </div> 
            {/*
            <div className='imgPubSinoLoUsas'>
                <div className='imgPubSinoLoUsasResp'>
                    <LazyLoadImage
                        effect="blur"
                        src={"https://res.cloudinary.com/mumbex/image/upload/v1664622419/sino_pxlmqb.jpg"}
                    />
                </div>
            </div>
            */}
            <MenuAbajo />

        </div>
    )
}

export default Login;