
import React, { useState } from 'react'
import { Route, BrowserRouter, Router, Navigate, HashRouter, Routes } from 'react-router-dom';

import Profil from './users/Profil'
import Auth from './contexts/Auth'
import AuthentificatedRoute from './components/AuthentificatedRoute'
import PageNotFoun from './pages/PageNotFoun'
import Confir from './users/confir'
import ForProducto from "./pages/ForParaAgregarProducto"
import Producto from "./pages/producto"
import VerCategoria from "./pages/verCategoria"
import Login from "./users/Login"
import Registro from "./users/Registro"
/***************  SUSPENSE LOADER PAGE*************** */
import Homme from "./pages/home"
import AgenciasTiendas from './pages/verAgencias'
import Home from './pages/home';
import Protected from './components/protectedRoute';
import VerCatAgencias from './pages/verCatAgencias';




function App() {
    return (
        <Auth>
            <HashRouter>
                <Routes>
                    <Route exact path="/" element={<div><Home /></div>} />
                    <Route exact path="/registro" element={<Registro />} />
                    <Route exact path="/iniciar" element={<Login />} />
                    <Route exact path="/psss" element={<Profil />} />
                    <Route exact path="/confirm/:id" element={<Confir />} />
                    <Route exact path="/product/:id" element={<Producto />} />
                    <Route exact path="/vercatategoria/:id" element={<VerCategoria />} />
                    <Route exact path="/tienda_agencia/:id" element={<VerCatAgencias />} />
                    <Route exact path="/profinAgence/:id" element={<AgenciasTiendas />} />
                    {/**
                     <AuthentificatedRoute  path = "/profil/:id" component = {Profil}/>

                    <AuthentificatedRoute exact  path = "/agregar" component = {ForProducto}/>

                    */}
                    <Route exact path="/profil/:id" element={
                        <Protected >
                            <Profil />
                        </Protected>
                    }
                    />
                    <Route exact path="/agregar" element={
                        <Protected >
                            <ForProducto />
                        </Protected>
                    }
                    />
                    <Route element={<PageNotFoun />} />
                </Routes>
            </HashRouter>
        </Auth>
    )

}

export default App;

