import React, { useContext, useEffect } from 'react'
import AppContext from '../../services/contextApp'
import { Link } from 'react-router-dom'
import M from 'materialize-css'

function SinavCat() {
    const { pais_post, ciudad_post, validar_user, user_id, BuscarConUnLink, dispatch, BuscarConFormInput } = useContext(AppContext)

    const BuscarConLink = (linkId) => {
        BuscarConUnLink(linkId, dispatch, pais_post, ciudad_post)
        //setCatParaPage(claveLink)
        document.getElementById("ciudadM").selectedIndex = 0
        document.getElementById("precioM").selectedIndex = 0
        const elem = document.querySelector(".wwwwww")
        var instance = M.Sidenav.getInstance(elem);
        instance.close()
        //Fin de Reiniciar o Cerrar el SideNav

    }

    useEffect(() => {
        const alls = document.querySelector(".wwwwww")
        M.Sidenav.init(alls, {
            preventScrolling: false,
            inDuration: 150,
        })

    }, [])
    return (
        <>
            {/* side nav para para categoria*/}

            <ul id="slide-out-nav" className="collapsible  sidenav wwwwww">
                <li>
                    <div className="collapsible-header avatar-collecion" >
                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png" />
                        Electronica
                    </div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/1" onClick={() => { BuscarConLink("1") }} className="collection-item blue-grey-text text-darken-4 "> Todo</Link>
                            <Link to="/vercatategoria/11" onClick={() => { BuscarConLink("11") }} className="collection-item blue-grey-text text-darken-4 ">Telefonos</Link>
                            <Link to="/vercatategoria/13" onClick={() => { BuscarConLink("13") }} className="collection-item blue-grey-text text-darken-4 ">Ordenadores</Link>
                            <Link to="/vercatategoria/12" onClick={() => { BuscarConLink("12") }} className="collection-item blue-grey-text text-darken-4 ">Televisiones</Link>
                            <Link to="/vercatategoria/14" onClick={() => { BuscarConLink("14") }} className="collection-item blue-grey-text text-darken-4 ">Material informatico</Link>
                            <Link to="/vercatategoria/18" onClick={() => { BuscarConLink("18") }} className="collection-item blue-grey-text text-darken-4 ">Equipos de audiovision</Link>
                            <Link to="/vercatategoria/15" onClick={() => { BuscarConLink("15") }} className="collection-item blue-grey-text text-darken-4 ">Videojuegos</Link>
                            <Link to="/vercatategoria/17" onClick={() => { BuscarConLink("17") }} className="collection-item blue-grey-text text-darken-4 "> Arduino</Link>
                            <Link to="/vercatategoria/16" onClick={() => { BuscarConLink("16") }} className="collection-item blue-grey-text text-darken-4 "> Electrodomesticos</Link>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="collapsible-header avatar-collecion">
                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654240227/56_xucqe2.png" />
                        Casass en alquiler</div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/31" onClick={() => { BuscarConLink("31") }} className="collection-item blue-grey-text text-darken-4" > Todo</Link>
                            <Link to="/vercatategoria/311" onClick={() => { BuscarConLink("311") }} className="collection-item blue-grey-text text-darken-4">Una habitacion</Link>
                            <Link to="/vercatategoria/312" onClick={() => { BuscarConLink("312") }} className="collection-item blue-grey-text text-darken-4">Dos habitaciones</Link>
                            <Link to="/vercatategoria/313" onClick={() => { BuscarConLink("313") }} className="collection-item blue-grey-text text-darken-4">Tres habitaciones</Link>
                            <Link to="/vercatategoria/314" onClick={() => { BuscarConLink("314") }} className="collection-item blue-grey-text text-darken-4">Cuatro habitaciones</Link>
                            <Link to="/vercatategoria/315" onClick={() => { BuscarConLink("315") }} className="collection-item blue-grey-text text-darken-4">Cinco habitaciones</Link>
                            <Link to="/vercatategoria/316" onClick={() => { BuscarConLink("316") }} className="collection-item blue-grey-text text-darken-4">Mas de cinco habitaciones</Link>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="collapsible-header avatar-collecion">
                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png" />
                        Casas en venta</div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/32" onClick={() => { BuscarConLink("32") }} className="collection-item blue-grey-text text-darken-4" > Todo</Link>
                            <Link to="/vercatategoria/321" onClick={() => { BuscarConLink("321") }} className="collection-item blue-grey-text text-darken-4">Una habitacion</Link>
                            <Link to="/vercatategoria/322" onClick={() => { BuscarConLink("322") }} className="collection-item blue-grey-text text-darken-4">Dos habitaciones</Link>
                            <Link to="/vercatategoria/323" onClick={() => { BuscarConLink("323") }} className="collection-item blue-grey-text text-darken-4">Tres habitaciones</Link>
                            <Link to="/vercatategoria/324" onClick={() => { BuscarConLink("324") }} className="collection-item blue-grey-text text-darken-4">Cuatro habitaciones</Link>
                            <Link to="/vercatategoria/325" onClick={() => { BuscarConLink("325") }} className="collection-item blue-grey-text text-darken-4">Cinco habitaciones</Link>
                            <Link to="/vercatategoria/326" onClick={() => { BuscarConLink("326") }} className="collection-item blue-grey-text text-darken-4">Mas de cinco habitaciones</Link>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="collapsible-header avatar-collecion" >
                        <img src="https://res.cloudinary.com/mumbex/image/upload/v1667469676/hogar_vlmpoe.png" />
                        Hogar
                    </div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/8" onClick={() => { BuscarConLink("8") }} className="collection-item blue-grey-text text-darken-4 "> Todo</Link>
                            <Link to="/vercatategoria/81" onClick={() => { BuscarConLink("81") }} className="collection-item blue-grey-text text-darken-4 ">Material de construccion</Link>
                            <Link to="/vercatategoria/82" onClick={() => { BuscarConLink("82") }} className="collection-item blue-grey-text text-darken-4 ">Material para decorrar el hogar</Link>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="collapsible-header avatar-collecion">
                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png" />
                        Telecomunicacion
                    </div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/2" onClick={() => { BuscarConLink("2") }} className="collection-item  c7 blue-grey-text text-darken-4" > todo</Link>
                            <Link to="/vercatategoria/21" onClick={() => { BuscarConLink("21") }} className="collection-item c1 blue-grey-text text-darken-4">conectores rg45</Link>
                            <Link to="/vercatategoria/22" onClick={() => { BuscarConLink("22") }} className="collection-item c2 blue-grey-text text-darken-4" >cable ethernet</Link>
                            <Link to="/vercatategoria/23" onClick={() => { BuscarConLink("23") }} className="collection-item c3 blue-grey-text text-darken-4" >router</Link>
                            <Link to="/vercatategoria/24" onClick={() => { BuscarConLink("24") }} className="collection-item c4 blue-grey-text text-darken-4" >antenas</Link>
                            <Link to="/vercatategoria/25" onClick={() => { BuscarConLink("25") }} className="collection-item c5 blue-grey-text text-darken-4" >modem</Link>
                            <Link to="/vercatategoria/26" onClick={() => { BuscarConLink("26") }} className="collection-item c6 blue-grey-text text-darken-4" > switch</Link>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="collapsible-header avatar-collecion">
                        <img src="https://res.cloudinary.com/mumbe/image/upload/c_thumb,w_200,g_face/v1639062139/trabajar_o64yj9.png" />
                        Servicios
                    </div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/4" onClick={() => { BuscarConLink("4") }} className="collection-item  c16 blue-grey-text text-darken-4" > Todo</Link>
                            <Link to="/vercatategoria/41" onClick={() => { BuscarConLink("41") }} className="collection-item c8 blue-grey-text text-darken-4">Clases particulares</Link>
                            <Link to="/vercatategoria/42" onClick={() => { BuscarConLink("42") }} className="collection-item c9 blue-grey-text text-darken-4" >Servicios de construccion</Link>
                            <Link to="/vercatategoria/43" onClick={() => { BuscarConLink("43") }} className="collection-item c10 blue-grey-text text-darken-4" >Servicios informaticos</Link>
                            <Link to="/vercatategoria/44" onClick={() => { BuscarConLink("44") }} className="collection-item c12 blue-grey-text text-darken-4" >Servicios de publicidad</Link>
                            <Link to="/vercatategoria/45" onClick={() => { BuscarConLink("45") }} className="collection-item c13 blue-grey-text text-darken-4" >Servicios en el area de sanidad</Link>
                            <Link to="/vercatategoria/46" onClick={() => { BuscarConLink("46") }} className="collection-item c14 blue-grey-text text-darken-4" >Buscar empleo</Link>
                            <Link to="/vercatategoria/47" onClick={() => { BuscarConLink("47") }} className="collection-item c15 blue-grey-text text-darken-4" >Ofrecer de empleo</Link>
                            <Link to="/vercatategoria/48" onClick={() => { BuscarConLink("48") }} className="collection-item c15 blue-grey-text text-darken-4" >Limpiesa a domicilio</Link>
                            <Link to="/vercatategoria/49" onClick={() => { BuscarConLink("49") }} className="collection-item c15 blue-grey-text text-darken-4" >Servicio de mecanico de coche</Link>
                            <Link to="/vercatategoria/401" onClick={() => { BuscarConLink("401") }} className="collection-item c15 blue-grey-text text-darken-4" >Reparacion de aparatos</Link>
                            <Link to="/vercatategoria/402" onClick={() => { BuscarConLink("402") }} className="collection-item c15 blue-grey-text text-darken-4" >servicios de fontaneria</Link>
                            <Link to="/vercatategoria/403" onClick={() => { BuscarConLink("403") }} className="collection-item c15 blue-grey-text text-darken-4" >servicios de coche grua</Link>
                            <Link to="/vercatategoria/404" onClick={() => { BuscarConLink("404") }} className="collection-item c15 blue-grey-text text-darken-4" >servicios de agua a domicilio</Link>
                            <Link to="/vercatategoria/405" onClick={() => { BuscarConLink("405") }} className="collection-item c15 blue-grey-text text-darken-4" >Lavado de coches a domicilio</Link>
                            <Link to="/vercatategoria/406" onClick={() => { BuscarConLink("406") }} className="collection-item c15 blue-grey-text text-darken-4" >Instalaciones electricas</Link>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="collapsible-header avatar-collecion">
                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png" />
                        Coches</div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/5" onClick={() => { BuscarConLink("5") }} className="collection-item  c19 blue-grey-text text-darken-4" > Todo</Link>
                            <Link to="/vercatategoria/51" onClick={() => { BuscarConLink("51") }} className="collection-item c17 blue-grey-text text-darken-4">Coches en alquiler</Link>
                            <Link to="/vercatategoria/52" onClick={() => { BuscarConLink("52") }} className="collection-item c18 blue-grey-text text-darken-4" >Coches en venta</Link>
                            <Link to="/vercatategoria/53" onClick={() => { BuscarConLink("53") }} className="collection-item c18 blue-grey-text text-darken-4" >Taxis en alquiler</Link>
                        </div>
                    </div>
                </li>

                <li>
                    <div class="collapsible-header avatar-collecion">
                        <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243221/67_zulzib.png" />
                        Vestimenta
                    </div>
                    <div className="collapsible-body">
                        <div className="collection">
                            <Link to="/vercatategoria/6" onClick={() => { BuscarConLink("6") }} className="collection-item  c24 blue-grey-text text-darken-4" > Todo</Link>
                            <Link to="/vercatategoria/61" onClick={() => { BuscarConLink("61") }} className="collection-item c20 blue-grey-text text-darken-4">Zapatos para hombres</Link>
                            <Link to="/vercatategoria/62" onClick={() => { BuscarConLink("62") }} className="collection-item c21 blue-grey-text text-darken-4" >Zapatos para mujeres</Link>
                            <Link to="/vercatategoria/63" onClick={() => { BuscarConLink("63") }} className="collection-item c22 blue-grey-text text-darken-4" >Zapatos para niños</Link>
                            <Link to="/vercatategoria/64" onClick={() => { BuscarConLink("64") }} className="collection-item c23 blue-grey-text text-darken-4" >Ropas para mujeres</Link>
                            <Link to="/vercatategoria/65" onClick={() => { BuscarConLink("65") }} className="collection-item  c24 blue-grey-text text-darken-4" >Ropas para hombres</Link>
                            <Link to="/vercatategoria/66" onClick={() => { BuscarConLink("66") }} className="collection-item  c24 blue-grey-text text-darken-4" >Ropas para niños</Link>
                            <Link to="/vercatategoria/67" onClick={() => { BuscarConLink("67") }} className="collection-item  c24 blue-grey-text text-darken-4" >Venta de pelo para mujeres</Link>
                            <Link to="/vercatategoria/68" onClick={() => { BuscarConLink("68") }} className="collection-item  c24 blue-grey-text text-darken-4" >Aguacate</Link>
                            <Link to="/vercatategoria/69" onClick={() => { BuscarConLink("69") }} className="collection-item  c24 blue-grey-text text-darken-4" >Pelucas</Link>
                            <Link to="/vercatategoria/601" onClick={() => { BuscarConLink("601") }} className="collection-item  c24 blue-grey-text text-darken-4" >Pulceras, relojes, y joyas</Link>
                        </div>
                    </div>
                </li>


            </ul>
            {/* fin de  side nav para para categoria*/}
        </>
    )
}

export default SinavCat