import React, { useContext, useState } from 'react'
import {
    CATEGORIA, CLAVE_PARA_BUSCAR, DATA_HOME, ACTIVAR_BUSQUEDA,
    SPINNER_CATEGORIA, SUB_CATEGORIA, SPINNER_ALGO_VA_MAL,
    SPINNER_CARGAR_ART_CAT, ESCONDER_SUB_CATEGORIAS, LOGIN_SPINNER,
    REGISTER_SPINNER, ERROR_USER, RESP_ERROR_LOGIN, SPINNER_ADD_PRODUCT,
    VALIDAR_USER, USER_NAME, USER_ID, PAIS_POST, CIUDAD_POST, ALGO_VA_MAL_HOME,
    SPINNER_HOME, BUSQUEDA_RESULTADOS, PRECIO1, PRECIO2, PROBAR_SI_HAY, PAGINA_SIGUIENTE, CAT_PARA_PAGE, DATA_CATEGO, BUSCAR, VERIFICAR_DATA_HOME, ARRAY_DATA_HOME, SIGUIENTE_PAGINA_NUMERO, ARRAY_SERVICIOS, INFO_PRODUCT, AGENCIAS_TIENDAS, NO_MORE_VER_CAT
} from './constantesVar'




const AppReducer = (state, action) => {
    const { payload, type } = action;

    

    switch (type) {
        case NO_MORE_VER_CAT:
            return {
                ...state,
                noMoreVerCat:payload
            };
        case AGENCIAS_TIENDAS:
            return {
                ...state,
                agenciasTiendas:payload
            };
        case INFO_PRODUCT:
            return {
                ...state,
                info_product:payload
            };
        case ARRAY_SERVICIOS:
            return {
                ...state,
                array_servicios:payload,
            };
        case SIGUIENTE_PAGINA_NUMERO:
            return {
                ...state,
                siguientePaginaNumero:payload
            };
        case ARRAY_DATA_HOME:
            return {
                ...state,
                arrayDataHome: payload
            };
        case VERIFICAR_DATA_HOME:
            return {
                ...state,
                verificarDataHome: payload
            };
        case CATEGORIA:
            return {
                ...state,
                categoria: payload
            };
        case DATA_CATEGO:
            return {
                ...state,
                dataCategoria: payload
            };
        case BUSCAR:
            return {
                ...state,
                buscar: payload
            };
        case SUB_CATEGORIA:
            return {
                ...state,
                subCategoria: payload
            };
        case DATA_HOME:
            return {
                ...state,
                dataCatego: payload,
                categoria: type
            };
        case SPINNER_CATEGORIA:
            return {
                ...state,
                spinnerDataCategoria: payload,
            };
        case PAGINA_SIGUIENTE:
            return {
                ...state,
                paginaSiguiente: payload,
            };
        case CAT_PARA_PAGE:
            return {
                ...state,
                catParaPage: payload,
            };
        case PROBAR_SI_HAY:
            return {
                ...state,
                probarSiHay: payload,
            };
        case SPINNER_ALGO_VA_MAL:
            return {
                ...state,
                algoVamal: payload,
            };
        case SPINNER_CARGAR_ART_CAT:
            return {
                ...state,
                algoVamal: payload,
                spinnerDataCategoria: payload,
            };
        case BUSQUEDA_RESULTADOS:
            return {
                ...state,
                busquedaClave: payload,
            };
        case PRECIO1:
            return {
                ...state,
                precio1: payload,
            };
        case PRECIO2:
            return {
                ...state,
                precio2: payload,
            };
        case ACTIVAR_BUSQUEDA:
            return {
                ...state,
                algoVamal: payload,
                activarBusqueda: payload,
            };
        case CLAVE_PARA_BUSCAR:
            return {
                ...state,
                claveParaBuscar: payload,
            };
        case ESCONDER_SUB_CATEGORIAS:
            return {
                ...state,
                esconderSubCategoria: payload,
            };
        case LOGIN_SPINNER:
            return {
                ...state,
                loginSpinner: payload,
            };
        case REGISTER_SPINNER:
            return {
                ...state,
                registerSpinner: payload,
            };
        case ERROR_USER:
            return {
                ...state,
                userUndefined: payload,
            };
        case RESP_ERROR_LOGIN:
            return {
                ...state,
                respServerUserUndefined: payload,
            };
        case SPINNER_ADD_PRODUCT:
            return {
                ...state,
                spinnerAddProduct: payload,
            };
        case VALIDAR_USER:
            return {
                ...state,
                validar_user: payload,
            };
        case USER_NAME:
            return {
                ...state,
                user_name: payload,
            };
        case USER_ID:
            return {
                ...state,
                user_id: payload,
            };
        case PAIS_POST:
            return {
                ...state,
                pais_post: payload,
            };
        case CIUDAD_POST:
            return {
                ...state,
                ciudad_post: payload,
            };
        case ALGO_VA_MAL_HOME:
            return {
                ...state,
                algoSaliMalHome: payload,
            };
        case SPINNER_HOME:
            return {
                ...state,
                spinnerDatosHome: payload,
            };
        default:
            return state;
    }



}

export default AppReducer