export const SERVER_URL = process.env.REACT_APP_URL_API
export const CATEGORIA = "categoria"
export const SUB_CATEGORIA = "sub_categoria"  
export const DATA_HOME = "data_home"
export const SPINNER_CATEGORIA = "spinner_categoria"
export const SPINNER_ALGO_VA_MAL = "spinner_algo_va_mal"
export const SPINNER_CARGAR_ART_CAT = "spinner_cargar_art_cat"
export const ARTCULOS_CAT = "articulos_cat"
export const ACTIVAR_BUSQUEDA = "activar_busqueda"
export const CLAVE_PARA_BUSCAR = "clave_para_buscar"
export const ESCONDER_SUB_CATEGORIAS = "esconder_sub_categoria"
export const LOGIN_SPINNER = "login_spinner"
export const REGISTER_SPINNER = "register_spinner"
export const ERROR_USER = "error_user"
export const RESP_ERROR_LOGIN = "resp_error_login"
export const SPINNER_ADD_PRODUCT = "spinner_add_product"
export const VALIDAR_USER = "validar_user"
export const USER_NAME = "user_name"
export const USER_ID = "user_id"
export const PAIS_POST = "pais_post"
export const CIUDAD_POST = "ciudad_post"
export const SPINNER_HOME = "spinner_home"
export const PRECIO1 = "precio1"
export const PRECIO2 = "precio2"
export const BUSQUEDA_RESULTADOS = "busqueda_resultados"
export const ALGO_VA_MAL_HOME = "algo_va_mal_home"
export const PROBAR_SI_HAY = "probarSiHay"
export const PAGINA_SIGUIENTE = "paginaSiguientesss"
export const CAT_PARA_PAGE= "catParaPage"
export const DATA_CATEGO = "dataCategoSSS"
export const BUSCAR = "buscarSSS"
export const VERIFICAR_DATA_HOME = "verificarDataHome"
export const ARRAY_DATA_HOME = "arrayDataHome"
export const SIGUIENTE_PAGINA_NUMERO = "siguientePaginaNumero"
export const ARRAY_SERVICIOS = "array_servicios"
export const INFO_PRODUCT = "info_product"
export const AGENCIAS_TIENDAS = "agenciasTiendas"
export const NO_MORE_VER_CAT = "noMoreVerCat"