import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import 'react-lazy-load-image-component/src/effects/blur.css';
import "animate.css"
import axios from 'axios';
import AppContext from '../services/contextApp';
import { SERVER_URL, USER_ID, USER_NAME, VALIDAR_USER } from '../contexts/constantesVar';




function Confir(props) {
    const { history } = props
    const { user_id, dispatch } = useContext(AppContext)
    const navigate = useNavigate();

    const { id } = useParams()

    const Confirmar = async () => {
        try {
            const datos = await axios({
                method: "post",
                data: { "tokenId": id },
                url: `${SERVER_URL}/confirmar`
            })
            //console.log(datos)
            if (datos.data) {
                dispatch({
                    type: VALIDAR_USER,
                    payload: true
                })
                dispatch({
                    type: USER_ID,
                    payload: datos.data.id
                })
                dispatch({
                    type: USER_NAME,
                    payload: datos.data.user
                })
                window.localStorage.setItem("code", datos.data.code)
                navigate(`/profil/${datos.data.id}`)
            } else {
                dispatch({
                    type: VALIDAR_USER,
                    payload: false
                })
                dispatch({
                    type: USER_ID,
                    payload: ""
                })
                dispatch({
                    type: USER_NAME,
                    payload: ""
                })
                navigate(`/iniciar`)
            }
        } catch (error) {
            dispatch({
                type: VALIDAR_USER,
                payload: false
            })
            dispatch({
                type: USER_ID,
                payload: ""
            })
            dispatch({
                type: USER_NAME,
                payload: ""
            })
            history.push(`/iniciar`)
            // console.log("hay un problema")
        }

    }
    useEffect(() => {
        Confirmar()
        //console.log(id)
    }, [])
    return (
        <div className='confirmContainer'>
            <div className='confirmContainerResp'>

                {true ?
                    <div className='confirmContainerLoader'>
                        <MoonLoader color={"blue"} loading={true} size={40} />
                        <p>Verificando...</p>
                    </div> :
                    <>
                    </>
                }


            </div>
        </div>
    )
}

export default Confir