import React, { useContext, useEffect, useState } from "react"
import {
    BUSCAR, BUSQUEDA_RESULTADOS, CATEGORIA, CAT_PARA_PAGE, CIUDAD_POST,
    DATA_CATEGO, PAGINA_SIGUIENTE, PAIS_POST, PRECIO1, PRECIO2, PROBAR_SI_HAY, SERVER_URL
} from "../contexts/constantesVar"
import M from "materialize-css"
import axios from "axios"
import AppContext from "../services/contextApp"






export const FiltrarPorPrecio = async (pais, dispatch, ciudad, precio11, precio22, busquedaClave, categoria, pais_post) => {
    //console.log(busquedaClave,"sssssssssssssssssssssss")

    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
    const categorias = ["1", "2", "3", "4", "5", "6"]
    const catBuscar = Number(categoria)


    if (ciudad || precio11 !== "" || precio22 !== "") {
        //console.log(precio11, "hhhhhhhhhhhhh", precio22)
        if (precio11 === "1" && precio22 === "1") {

        }
        dispatch({
            type: PAIS_POST,
            payload: pais
        })
        dispatch({
            type: CIUDAD_POST,
            payload: ciudad
        })
        dispatch({
            type: PRECIO1,
            payload: precio11
        })
        dispatch({
            type: PRECIO2,
            payload: precio22
        })
        try {
            dispatch({
                type: PROBAR_SI_HAY,
                payload: false
            })
            //setProbarSiHay(false)
            if (categoria !== "clave") {
                const datos = await axios({
                    method: 'post',
                    data: { "pais": "01", "ciudad": ciudad, "precio1": precio11, "precio2": precio22 },
                    url: `${SERVER_URL}/ver_articulo/${categoria}`
                })

                if (datos.data.docs[0]) {
                    dispatch({
                        type: PROBAR_SI_HAY,
                        payload: true
                    })
                    //setProbarSiHay(true)

                    dispatch({
                        type: CAT_PARA_PAGE,
                        payload: datos.data.docs[0]["categoria"]
                    })
                    //setCatParaPag(datos.data.docs[0]["categoria"])
                    dispatch({
                        type: DATA_CATEGO,
                        payload: datos.data.docs
                    })
                    //setDatosArticulos(datos.data.docs)

                    dispatch({
                        type: PAGINA_SIGUIENTE,
                        payload: datos.data.nextPage
                    })
                    //setPaginaSiguiente(datos.data.nextPage)

                    if ((datos.data.docs != "")) {
                        dispatch({
                            type: DATA_CATEGO,
                            payload: datos.data.docs
                        })
                        //setDatosArticulosCateg(datos.data.docs)
                    }
                    //console.log(datos.data, "cogiendo 2")
                } else {
                    dispatch({
                        type: DATA_CATEGO,
                        payload: []
                    })
                    //setDatosArticulos([])
                    //console.log("vacioooooooooooooooooo")
                    dispatch({
                        type: PROBAR_SI_HAY,
                        payload: true
                    })
                    //setProbarSiHay(true)
                }

            } else {
                const datos = await axios({
                    method: 'post',
                    data: { 'clave': busquedaClave, "pais": "01", "ciudad": ciudad, "precio1": precio11, "precio2": precio22 },
                    url: `${SERVER_URL}/buscar`
                })
                //console.log("esta en buacar", datos.data.docs)
                if (datos.data.docs[0]) {
                    dispatch({
                        type: PROBAR_SI_HAY,
                        payload: true
                    })
                    //setProbarSiHay(true)
                    dispatch({
                        type: CAT_PARA_PAGE,
                        payload: datos.data.docs[0]["categoria"]
                    })
                    //setCatParaPag(datos.data.docs[0]["categoria"])
                    dispatch({
                        type: DATA_CATEGO,
                        payload: datos.data.docs
                    })
                    //setDatosArticulos(datos.data.docs)


                    dispatch({
                        type: PAGINA_SIGUIENTE,
                        payload: datos.data.nextPage
                    })
                    //setPaginaSiguiente(datos.data.nextPage)

                    if ((datos.data.docs != "")) {
                        dispatch({
                            type: DATA_CATEGO,
                            payload: datos.data.docs
                        })
                        //setDatosArticulosCateg(datos.data.docs)
                    }
                    //console.log(datos.data, "cogiendo 2")
                } else {
                    dispatch({
                        type: DATA_CATEGO,
                        payload: []
                    })
                    //setDatosArticulos([])
                    dispatch({
                        type: PROBAR_SI_HAY,
                        payload: true
                    })
                    //setProbarSiHay(true)

                }
            }
        } catch (e) {
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
            dispatch({
                type: PROBAR_SI_HAY,
                payload: true
            })
            //setProbarSiHay(true)
            //console.log(e)
        }

    } else {


    }

}



