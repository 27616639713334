import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import M from "materialize-css"
import BarradeNavegacion from '../components/navegacionBarAll/BarradeNavegacion';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FaWhatsapp, FaPhone } from "react-icons/fa"
import MenuAbajo from '../components/menuAbajo';
import { useParams } from "react-router-dom"
import { IoMdTime } from "react-icons/io";
import { format, register } from 'timeago.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ARRAY_DATA_HOME, INFO_PRODUCT, SERVER_URL, SIGUIENTE_PAGINA_NUMERO, VERIFICAR_DATA_HOME } from '../contexts/constantesVar';

import axios from "axios"
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.min.css"
import "owl.carousel/dist/assets/owl.theme.default.min.css"
import { MoonLoader } from "react-spinners";

import FloatingButton from '../components/floatingButton';

const Producto = (props) => {
    const { id } = useParams()
    const [imgV, setImgV] = useState("")
    const [datosArticulos, setDatosArticulos] = useState([])
    const [datosArticulosRelac, setDatosArticulosRelac] = useState([])
    const [probar, setProbar] = useState(true)
    const [center, setCenter] = useState(false)
    const [itemNumber, setItemNumber] = useState(3)
    const [itemNumber2, setItemNumber2] = useState(3)
    const [noMore, setNoMore] = useState(false)


    var options = {
        responsiveClass: true,
        autoplay: false,
        loop: true,
        dots: true,
        center: true,
        nav: true,
        navClass: ["owl-prev", "owl-next"],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1200: {
                items: 1,
            },

        },
    };

    //configuracion que permite mostrar fecha en español
    register('es_ES', (number, index, total_sec) => [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 dia', 'en 1 dia'],
        ['hace %s dias', 'en %s dias'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['hace 1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años']
    ][index]);

    const timeago = timestamp => format(timestamp, 'es_ES')
    const ArticulosSingleDB = async () => {

        //console.log(id)
        setProbar(true)
        try {
            const datos = await axios({
                method: 'post',
                url: `${SERVER_URL}/home_article_simple/${id}`
            })

            if (datos.data) {
                setProbar(false)
                setDatosArticulos(datos.data.articulo)
                setDatosArticulosRelac(datos.data.articRelac)

                console.log(datos.data)
            }

        } catch {
            setProbar(false)
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
        }
    }
    const ProductRelacionados = async (id) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        setProbar(true)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        try {
            const datos = await axios({
                method: 'post',
                url: `${SERVER_URL}/home_article_simple/${id}`
            })

            if (datos.data) {
                setProbar(false)
                setDatosArticulos(datos.data.articulo)
                setDatosArticulosRelac(datos.data.articRelac)
                //console.log(datos.data)
            }

        } catch {
            setProbar(false)
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {})

    }, [])
    useEffect(() => {
        ArticulosSingleDB()
    }, [])
    return (

        <div>
            <BarradeNavegacion />
            {!probar ?
                <div className='containerGlobal'>
                    <section className="ver-produto-contenedor container">
                        <div className="ver-produto-respon">
                            <div className="ver-producto-img ">
                                {datosArticulos[0] ?


                                    < OwlCarousel className=" owl-theme " responsiveClass {...options}>

                                        {datosArticulos[0]["imagen1"] ?
                                            <div className="item">

                                                <img src={`${datosArticulos[0]["imagen1"]}`} alt="" />
                                            </div>
                                            :
                                            ""
                                        }
                                        {datosArticulos[0]["imagen2"] ?
                                            <div className="item">

                                                <img src={`${datosArticulos[0]["imagen2"]}`} alt="" />
                                            </div>
                                            :
                                            ""
                                        }

                                        {datosArticulos[0]["imagen3"] ?
                                            <div className="item">
                                                <img src={`${datosArticulos[0]["imagen3"]}`} alt="" />
                                            </div>
                                            :
                                            ""
                                        }
                                        {datosArticulos[0]["imagen4"] ?
                                            <div className="item">
                                                <img src={`${datosArticulos[0]["imagen4"]}`} alt="" />
                                            </div>
                                            :
                                            ""
                                        }


                                    </OwlCarousel>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </section>
                    <div className="background-ver-img">
                        <section >
                            <div className="border-abaj top-margen">

                            </div>
                        </section>


                    </div>
                    <section >
                        <div className="border-abaj">

                        </div>
                    </section>
                    <section className="product-info container">
                        <div className="product-info-respon">

                            {datosArticulos[0] ?
                                <>
                                    <div className='articulo-description-info' style={{marginTop:"0px"}}>
                                        <div className='articulo-description-info-1'>
                                            <p className='product-name'>{datosArticulos[0]["nombre"]}</p>
                                        </div>
                                    </div>
                                    <div className='articulo-description-info' style={{display:"flex",justifyContent:"center",marginBlock:"20px"}}>
                                        <div className='articulo-description-info-1' style={{maxWidth:"400px"}}>
                                            {datosArticulos[0]["brevedescripcion"] ?
                                                <p className='product-description' style={{textAlign:"center"}}>{datosArticulos[0]["brevedescripcion"]}</p>
                                                : ""}
                                        </div>

                                    </div>
                                    <div className='articulo-description-info'>

                                        <div className='articulo-description-info-2'>
                                            {datosArticulos[0]["precio"] ?
                                                <p className='product-price' >{datosArticulos[0]["precio"].toLocaleString("es-GQ")} xfa</p>
                                                : ""}
                                        </div>
                                    </div>
                                    <div className='articulo-description-info' >

                                        <div className='articulo-description-info-2'>
                                            {datosArticulos[0]["localizacion"] ?
                                                <p className='localizacioTextProduct'>Guinea Ecuatorial - {datosArticulos[0]["ciudad"] === "011" ? "Malabo" : ""}{datosArticulos[0]["ciudad"] === "012" ? "Bata" : ""}{datosArticulos[0]["ciudad"] === "013" ? "Ebibeyin" : ""}{datosArticulos[0]["ciudad"] === "014" ? "Mongomo" : ""}{datosArticulos[0]["ciudad"] === "015" ? "Annobon" : ""}{datosArticulos[0]["ciudad"] === "016" ? "Mongomo" : ""}{" - " + datosArticulos[0]["localizacion"]}</p>

                                                :
                                                ""}
                                        </div>
                                    </div>

                                    <div className='articulo-description-info'>
                                        <div className='articulo-description-info-1'>
                                            {datosArticulos[0]["brevedescripcion"] ?
                                                <p className='product-description'>Llamar +240 {datosArticulos[0]["llamar"].slice(0,3)} {datosArticulos[0]["llamar"].slice(3,6)} {datosArticulos[0]["llamar"].slice(6,9)}<br></br> whatsapp +240 {datosArticulos[0]["mensage"].slice(0,3)} {datosArticulos[0]["mensage"].slice(3,6)} {datosArticulos[0]["mensage"].slice(6,9)}</p>
                                                : ""}
                                        </div>

                                    </div>


                                </>
                                : ""}

                        </div>
                    </section>



                </div>
                : ""}
            {/* container pa pantallas grndes superiores a 600px */}

            {datosArticulosRelac[0] && !probar ?
                <>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "40px", marginBottom: "20px" }}>
                        <div style={{ width: "950px" }}><h2 style={{ fontWeight: 500, color: "#b71c1c", textAlign: "center" }}>Relacionados</h2></div>
                    </div>
                    <di className="displayCardBigScreen">
                        <div className='cardVerticalContainer '>

                            <InfiniteScroll
                                dataLength={datosArticulosRelac.length}
                                loader={<div className='cargarDatosmas'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                                hasMore={noMore}
                                next={() => {
                                }}
                                className='cardVerticalContainerResp'
                            >
                                {datosArticulosRelac[0] ?
                                    <>
                                        {datosArticulosRelac.map((x, y) =>
                                            <Link to={`#`}
                                                onClick={() => {
                                                    ProductRelacionados(x._id)

                                                }}
                                                className='cardVertical modal-trigger'
                                                data-target="modalInfoProduct"
                                                key={x["_id"]}
                                            >
                                                <div className='cardVerticalImg'>
                                                    {<img src={x.imagen1} loader='lazy' />}
                                                    {/* <img src ="https://mumbexserver.tech/img/1-928571e3-e98f-40bc-88f6-15fbd6a37094.jpg"/> */}
                                                </div>
                                                <div className='cardVerticalProductName'>
                                                    <h6>{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</h6>
                                                </div>
                                                <div className='cardVerticalProductDescripcion' style={{ display: "none" }}>
                                                    <h6>{x.brevedescripcion}...</h6>
                                                </div>
                                                <div className='cardVerticalProductPrecio'>
                                                    {x["precio"] ?
                                                        <h6>{x.precio.toLocaleString("es-GQ")} xfa</h6>
                                                        :
                                                        <h6>{x["precioString"]}</h6>
                                                    }
                                                </div>
                                                <div className='cardVerticalProductTiempo'>
                                                    <IoMdTime size={20} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                </div>
                                            </Link>
                                        )}

                                    </>
                                    :

                                    <></>

                                }


                            </InfiniteScroll>


                        </div>
                    </di>


                    {/* container pa pantallas pequeñas inferiores a 600px */}
                    <div className="displayCardMobil">
                        <div className='nuevos-productos'>


                            <InfiniteScroll
                                dataLength={datosArticulosRelac.length}
                                loader={<div className='cargarDatosmas'><MoonLoader color={"blue"} loading={true} size={40} /></div>}
                                hasMore={noMore}
                                next={() => {

                                }}
                                className='profilContainerCardResp'
                            >
                                <div className="respon-nuevos-productos ">

                                    <div className="fila-de-targetas-horizontal ">
                                        {datosArticulosRelac[0] ?
                                            <>
                                                {datosArticulosRelac.map((x, y) =>
                                                    <Link to={`#`}
                                                        onClick={() => {
                                                            ProductRelacionados(x._id)
                                                        }}
                                                        className='cardCategoriaHorizotalContainer modal-trigger'
                                                        data-target="modalInfoProduct"
                                                        key={x["_id"]}
                                                    >
                                                        <div className="cardCategoriaHorizotal">
                                                            <div className="cardHorizotalfoto">
                                                                <LazyLoadImage
                                                                    effect="blur"
                                                                    src={x["imagen1"]}
                                                                />
                                                            </div>
                                                            <div className="cardHorizotalText">
                                                                <div className="cardHorizotalDescription">
                                                                    <p className="cardHorizotalDescriptionText">{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</p>
                                                                    {x["precio"] ?
                                                                        <p className="cardHorizotalprecio">{x["precio"].toLocaleString("es-GQ")} xfa</p>
                                                                        :
                                                                        <p className="cardHorizotalprecio">{x["precioString"]}</p>
                                                                    }
                                                                    <div className='cardHorizontalProductTiempo'>
                                                                        <IoMdTime size={18} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </Link>
                                                )}

                                            </>
                                            :

                                            <></>

                                        }

                                    </div>
                                </div>
                            </InfiniteScroll>


                        </div>
                    </div>
                </>
                :
                <>
                </>
            }
            {probar ?
                <div className="spinner-cat">
                    <MoonLoader color={"blue"} loading={true} size={40} />
                    <p style={{ color: "blue" }}>cargando articulo...</p>
                    <div className="spinner-home-altura"></div>
                </div>
                :
                ""

            }




            {probar ?
                ""
                :
                <>

                    <MenuAbajo />
                </>
            }


            <FloatingButton />
        </div>
    )
}






export default Producto;