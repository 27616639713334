import { react } from "react";
import { Link } from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css';
import "./menuIconos.css"

//Menu de iconos home para categorias mas destacadas
const MenuIconos = () => {
    return (
        <section className="categorias-con-imagenes">
            <div className="div-respon-categorias-con-imagenes">
                <div className="navegation-mobil">

                    <div className="navegation-mobil-iconos-1">
                        <div className="icon-titulo-1 ">
                            <Link replace className="imgen-icono" to="/vercatategoria/31">
                               
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" replace to="/vercatategoria/31" >Casas alq..</Link>
                        </div>
                        <div className="icon-titulo-1 ">
                            <Link to="/vercatategoria/32" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png"  className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/32">Casas vent.</Link>
                        </div>

                        <div className="icon-titulo-1 ">
                            <Link to="/vercatategoria/11" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/11">Telefonos</Link>
                        </div>
                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/13" className="imgen-icono">
                               
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206420/1_15_uutaaq.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/13">Ordena..</Link>
                        </div>
                        <div className="icon-titulo-1 ">
                            <Link to="/vercatategoria/5" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/5">Coches</Link>
                        </div>
                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/41" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206421/1_17_gn0xrm.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/41">Clases part.</Link>
                        </div>
                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/69" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206436/1_1_lojfds.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/69">Pelucas</Link>
                        </div>
                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/64" className="imgen-icono">
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_100,h_120,g_face/v1654208346/51_hcwj7m.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/64">Ropas muj.</Link>
                        </div>
                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/65" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_2_ppvxvy.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/65">Ropas hom.</Link>
                        </div>




                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/62" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206425/1_5_wjih4u.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/62">Zapatos m..</Link>
                        </div>
                        
                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/61" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_350,g_face/v1654206434/1_4_pryd0o.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/61">Zapatos h..</Link>
                        </div>


                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/12" className="imgen-icono">
                               
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206418/1_9_xf6dce.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="/vercatategoria/12">Television</Link>
                        </div>



                        <div className="icon-titulo-1">
                            <Link to="/vercatategoria/48" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206422/1_16_eurgzy.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="vercatategoria/48">Limpieza</Link>
                        </div>

                        <div className="icon-titulo-1 ">
                            <Link to="vercatategoria/24" className="imgen-icono">
                               
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="vercatategoria/24">Antenas</Link>
                        </div>
                        <div className="icon-titulo-1 ">
                            <Link to="vercatategoria/23" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206419/1_11_vxhkqp.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="vercatategoria/23">Router</Link>
                        </div>

                        <div className="icon-titulo-1 "> 
                            <Link to="vercatategoria/25" className="imgen-icono">
                                
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206419/1_10_zxcina.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="vercatategoria/25">modem</Link>
                        </div>
                        <div className="icon-titulo-1 " style={{display:"none"}}>
                            <Link to="vercatategoria/17" className="imgen-icono">
                               
                                <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654245194/70_duxjxg.png" className="responsive-img" alt="" />
                            </Link>
                            <Link className="titulo-link-mobil" to="vercatategoria/17">Arduino</Link>
                        </div>


                    </div>

                </div>
            </div>
        </section>
    )
}

export default MenuIconos