import React, { useEffect, useState, useContext } from 'react'
import "./verdInfoProducto.css"
import M from 'materialize-css'
import AppContext from '../../services/contextApp'
import { FaWhatsapp, FaPhone } from "react-icons/fa"
//import OwlCarousel from 'react-owl-carousel';
//import "owl.carousel/dist/assets/owl.carousel.min.css"
//import "owl.carousel/dist/assets/owl.theme.default.min.css"
import { MoonLoader } from "react-spinners";
import { Link } from 'react-router-dom'


export default function VerdInfoProducto() {
    const { info_product } = useContext(AppContext)
    const [imgV, setImgV] = useState("")
    const [datosArticulos, setDatosArticulos] = useState([])
    const [datosArticulosRelac, setDatosArticulosRelac] = useState([])
    const [probar, setProbar] = useState(true)
    const [center, setCenter] = useState(false)
    const [itemNumber, setItemNumber] = useState(3)
    const [itemNumber2, setItemNumber2] = useState(3)
    var options = {
        responsiveClass: false,
        autoplay: false,
        loop: false,
        dots: true,
        center: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1200: {
                items: 1,
            },

        },
    };




    //console.log(info_product)
    useEffect(() => {
        
        const alls = document.querySelector(".modal-info-product")
        M.Modal.init(alls, {
            preventScrolling: false,
            inDuration: 50,
            outDuration: 50,
        })
        console.log('modal')

    }, [])
    return (
        <div id="modalInfoProduct" className="modal modal-info-product">
            <div className='containerGlobal'>
                {info_product[0] ?
                    <div className='container-foto-producto'>
                        <div className='foto-producto-img'>
                            <img src={`${info_product[0]["imagen1"]}`} />
                        </div>
                    </div>
                    :
                    <></>
                }
                <section className="product-info">
                    <div className="product-info-respon">

                        {info_product[0] ?
                            <>
                                <div className='articulo-description-info'>
                                    <div className='articulo-description-info-1'>
                                        {info_product[0]["nombre"] ?
                                            <p className='product-name'>{info_product[0]["nombre"]}</p>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                <div className='articulo-description-info'>
                                    <div className='articulo-description-info-1'>
                                        {info_product[0]["brevedescripcion"] ?
                                            <p className='product-description'>{info_product[0]["brevedescripcion"]}</p>
                                            : ""}
                                    </div>

                                </div>
                                <div className='articulo-description-info'>
                                    
                                    <div className='articulo-description-info-2'>
                                        {info_product[0]["precio"] ?
                                            <p className='product-price' >{info_product[0]["precio"].toLocaleString("es-GQ")} xfa</p>
                                            :
                                            <p className='product-price' >{info_product[0]["precioString"]}</p>

                                        }
                                    </div>
                                </div>
                                <div className='articulo-description-info'>
                                    
                                    <div className='articulo-description-info-2'>
                                        {info_product[0]["localizacion"] ?
                                            <p className='localizacioTextProduct'>Guinea Ecuatorial - {info_product[0]["ciudad"] === "011" ? "Malabo" : ""}{info_product[0]["ciudad"] === "012" ? "Bata" : ""}{info_product[0]["ciudad"] === "013" ? "Ebibeyin" : ""}{info_product[0]["ciudad"] === "014" ? "Mongomo" : ""}{info_product[0]["ciudad"] === "015" ? "Annobon" : ""}{info_product[0]["ciudad"] === "016" ? "Mongomo" : ""}{" - " + info_product[0]["localizacion"]}</p>

                                            :
                                            ""}
                                    </div>
                                </div>

                                <div className="product-info-contacto">
                                    
                                    {info_product[0]["mensage"] ?
                                        <>
                                            <div className="product-info-contacto-link">
                                                <Link to ='#' className="whatsapp" onClick={() => { window.open(`//wa.me/+240${info_product[0]["mensage"]}`) }}><FaWhatsapp size={31} />
                                                    <span>
                                                        {"+240" + " " + info_product[0]["mensage"].slice(0, 3) + " " + info_product[0]["mensage"].slice(3, 6) + " " + info_product[0]["mensage"].slice(6, 9)}
                                                    </span>
                                                </Link>
                                                <a className="telefono" href={`tel:+240${info_product[0]["mensage"]}`}><FaPhone size={24} />
                                                    <span>
                                                        {"+240" + " " + info_product[0]["mensage"].slice(0, 3) + " " + info_product[0]["mensage"].slice(3, 6) + " " + info_product[0]["mensage"].slice(6, 9)}
                                                    </span>
                                                </a>
                                            </div>
                                        </>
                                        : ""}

                                </div>

                            </>
                            : ""}

                    </div>
                </section>
                {info_product[0] ?
                    <div className='container-foto-producto'>
                        {info_product[0]["imagen2"] ?
                            <div className='foto-producto-img'>
                                <img src={`${info_product[0]["imagen2"]}`} />
                            </div>
                            :
                            <></>
                        }
                        {info_product[0]["imagen3"] ?
                            <div className='foto-producto-img'>
                                <img src={`${info_product[0]["imagen3"]}`} />
                            </div>
                            :
                            <></>
                        }
                        {info_product[0]["imagen4"] ?
                            <div className='foto-producto-img'>
                                <img src={`${info_product[0]["imagen4"]}`} />
                            </div>
                            :
                            <></>
                        }
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

