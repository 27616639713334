import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./agenciasDescripcion.css"
import { FaWhatsapp, FaPhone, FaAddressBook } from "react-icons/fa"
import M from 'materialize-css'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'


function AgenciasDescripcion({ nombre, descripcion, numeroW, numeroT, servicios, categoria }) {
    var serviceList = []
    const [abrir, setAbrir] = useState(false)
    if (servicios) {
        serviceList = servicios.split(",")
    } else {

    }
    return (
        <div className='container-info-profil-agence'>
            <div className='container-info-profil-agence-resp'>

                <div className='info-profil-agence-brevedescrip-container'>
                    <h5>{nombre}</h5>
                    {abrir ?
                        <p>{descripcion}</p>
                        :
                        <></>
                    }

                </div>

                {abrir?
                    <div className='info-profil-agence-contacts-container'>
                        <Link className='link-contact-profil-agence'><span className='icono-profil-agence'><FaPhone size={27} color="blue" /></span><span className='contact-text-profil-agence'>{"+240" + " " + numeroT.slice(0, 3) + " " + numeroT.slice(3, 6) + " " + numeroT.slice(6, 9)}</span> </Link>
                        <Link className='link-contact-profil-agence'><span className='icono-profil-agence'><FaWhatsapp color='blue' size={30} /></span><span className='contact-text-profil-agence'>{"+240" + " " + numeroW.slice(0, 3) + " " + numeroW.slice(3, 6) + " " + numeroW.slice(6, 9)}</span> </Link>
                        <Link className='link-contact-profil-agence'><span className='icono-profil-agence'><FaAddressBook color='blue' size={30} /></span><span className='contact-text-profil-agence'>m@gmail.com</span> </Link>
                    </div>
                    :
                    <></>
                }
                <div className='container-icono-abrir-mas'>
                    <Link to="#" onClick={()=>{setAbrir(!abrir)}}>{abrir?"Ocultar informacion":"Click para mas informacion"}{abrir?<FiChevronUp size={30}/>:<FiChevronDown size={30}/>}</Link>
                    
                </div>

            </div>
        </div>
    )
}

export default AgenciasDescripcion
