import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
//import '../../styles/BarradeNavegacion.css'
import { Link, NavLink, useNavigate } from "react-router-dom"
import AppContext from '../../services/contextApp'
import Side from "./sideNav"
import { BsListUl } from "react-icons/bs";
import { FiUser, FiPlus, FiSearch } from "react-icons/fi";
import { SERVER_URL } from '../../contexts/constantesVar'


const BarradeNavegacion = ({ history }) => {
  const navigate = useNavigate();

  const { pais_post, ciudad_post, validar_user, user_id } = useContext(AppContext)
  const [buscar, setBuscar] = useState([])
  const [abrir, setAbrir] = useState(false)


  // funcion para realizar busquedas
  /*
  const Buscar = async (clave) => {
    if (clave) {
      const datos = await axios({ method: 'post', data: { 'clave': clave, "pais": pais_post, "ciudad": ciudad_post }, url: `${SERVER_URL}/buscar` })
      if (datos.data) {
        setBuscar(datos.data.docs)
      }
      console.log(datos.data)
    } else {
      setBuscar("")
    }
    Validar()

  }
  */




  // funcion para activar la realizacion de busquedas
  const Validar = () => {
    if (buscar) {
      return true
    } else {
      return false
    }
  }


  // funcion para traer resultados de busquedas a trvez de una lista mientras que se va escribiendo en en input
  const ResultadoBusqueda = () => {
    if (buscar) {
      return (
        <div className="collection collection-para-buscar">
          {buscar.map((clave) =>
            <Link to={`/vercatategoria/${clave["subcategoria"]}`} className="collection-item  " replace>{clave["brevedescripcion"]}</Link>
          )}
        </div>
      )


    } else {
      return (
        <div></div>
      )
    }
  }


  // funcion para buscar desde el input
  const BuscarForm = async (e) => {
    e.preventDefault()
    if (e.target.buscar.value) {
      navigate(`/vercatategoria/${e.target.buscar.value}`)
    } else {

    }

  }

  //funcion para llamar a los submenus

  const CallSubMenu = (sub) => {

    for (let i = 1; i < 12; i++) {
      if (i === sub) {
        if (document.querySelector(`.subMenu${i}`)) {
          document.querySelector(`.subMenu${i}`).style.display = "block"
        } else {

        }
      } else {
        if (document.querySelector(`.subMenu${i}`)) {
          document.querySelector(`.subMenu${i}`).style.display = "none"
        } else {

        }
      }

    }
  }
  return (
    <div>

      {/* menu vertical que aparece en dispositivos moviles */}
      <Side></Side>

      {/* fin del menu vertical  */}

      {/* navegacion horizontal */}

      <div className="contenedor-sticky  container-fluid  barra-de-nav-scrollTop ">
        <div className="menu-sticky container" id="menu">
          <div className=" contenedor-enlaces-nav-sticky">
            <div className="logotipo-sticky">
              <Link to="/#" ><img src={"https://res.cloudinary.com/mumbex/image/upload/v1664622527/logo512_xmwnqy.png"} replace alt="" /></Link>
            </div>
            <div className="btn-departamentos-sticky esconde-menu-iniciar-idiomas">
              <a
                onMouseLeave={
                  () => {
                    CallSubMenu(9)

                  }}
              ><span>categorias</span> <BsListUl size={20} color="#fff" /></a>

              <div className="dropppdown-content-sticky"
                onMouseLeave={
                  () => {
                    CallSubMenu(9)

                  }}
              >
                <div className="collection collection-1 col col4 bordes-bottom">
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(1)
                      }}

                    to="#" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Electronica
                  </Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(10)
                      }}

                    to="#" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/v1667469676/hogar_vlmpoe.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Hogar
                  </Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(6)
                      }}
                    to="#" className="collection-item droppdown-5 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Telecom.</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(5)
                      }}
                    to="#" className="collection-item droppdown-4 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Coches</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(2)

                      }}


                    to="#" className="collection-item droppdown-3 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654240227/56_xucqe2.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Casas en alquiler</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(3)

                      }}


                    to="#" className="collection-item droppdown-3 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Casas en venta</Link>



                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(7)
                      }}
                    to="#" className="collection-item droppdown-4 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243221/67_zulzib.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Vestimenta</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(4)
                      }}
                    to="#" className="collection-item droppdown-5 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243124/66_gmotgv.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Servicios</Link>


                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(9)
                      }}

                    to="/vercatategoria/7" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243449/68_lxeaxc.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Terrenos
                  </Link>

                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(9)
                      }}

                    to="/vercatategoria/16" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243798/69_qvvhqu.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Electrodomesticos
                  </Link>

                </div>

                {/* submemus */}
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu1">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/1" replace className="collection-item bodes-boton-none "> Todo</Link>
                    <Link to="/vercatategoria/11" replace className="collection-item  bodes-boton-none ">Telefonos</Link>
                    <Link to="/vercatategoria/12" replace className="collection-item  bodes-boton-none ">Televisiones</Link>
                    <Link to="/vercatategoria/13" replace className="collection-item bodes-boton-none ">Ordenadores</Link>
                    <Link to="/vercatategoria/14" replace className="collection-item bodes-boton-none "> Material informatico</Link>
                    <Link to="/vercatategoria/15" replace className="collection-item bodes-boton-none "> Videojuegos</Link>
                    <Link to="/vercatategoria/17" replace className="collection-item bodes-boton-none "> Arduino</Link>
                    <Link to="/vercatategoria/18" replace className="collection-item bodes-boton-none "> Equipos de audiovision</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu10">
                  <div className="collection collection-1-2">
                    <NavLink to="/vercatategoria/8" replace className="collection-item bodes-boton-none "> Todo</NavLink>
                    <NavLink to="/vercatategoria/81" replace className="collection-item  bodes-boton-none ">Material de construccion</NavLink>
                    <NavLink to="/vercatategoria/82" replace className="collection-item  bodes-boton-none ">Material para decorrar el hogar</NavLink>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu2">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/31" replace className="collection-item  bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/311" replace className="collection-item  bodes-boton-none ">Alquilar casa de una habitacion</Link>
                    <Link to="/vercatategoria/312" replace className="collection-item  bodes-boton-none ">Alquilar casa de dos habitaciones</Link>
                    <Link to="/vercatategoria/313" replace className="collection-item bodes-boton-none ">Alquilar casa de tres habitaciones</Link>
                    <Link to="/vercatategoria/314" replace className="collection-item bodes-boton-none ">Alquilar casa de cuatro habitaciones</Link>
                    <Link to="/vercatategoria/315" replace className="collection-item bodes-boton-none ">Alquilar casa de cinco habitaciones</Link>
                    <Link to="/vercatategoria/316" replace className="collection-item bodes-boton-none ">Alquilar casa de mas de cinco habitaciones</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu3">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/32" className="collection-item  bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/321" className="collection-item  bodes-boton-none ">Vender casa una habitacion</Link>
                    <Link to="/vercatategoria/322" className="collection-item  bodes-boton-none ">Vender casa dos habitaciones</Link>
                    <Link to="/vercatategoria/323" className="collection-item bodes-boton-none ">Vender casa tres habitaciones</Link>
                    <Link to="/vercatategoria/324" className="collection-item bodes-boton-none ">Vender casa cuatro habitaciones</Link>
                    <Link to="/vercatategoria/325" className="collection-item bodes-boton-none ">Vender casa cinco habitaciones</Link>
                    <Link to="/vercatategoria/326" className="collection-item bodes-boton-none ">Vender casa mas de cinco habitaciones</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu7">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/6" replace className="collection-item bodes-boton-none ">todo</Link>
                    <Link to="/vercatategoria/61" replace className="collection-item  bodes-boton-none ">Zapatos para hombres</Link>
                    <Link to="/vercatategoria/62" replace className="collection-item  bodes-boton-none ">Zapatos para mujeres</Link>
                    <Link to="/vercatategoria/63" replace className="collection-item  bodes-boton-none ">Zapatos para niños</Link>
                    <Link to="/vercatategoria/64" replace className="collection-item bodes-boton-none ">Ropas para mujeres</Link>
                    <Link to="/vercatategoria/65" replace className="collection-item bodes-boton-none ">Ropas para hombres</Link>
                    <Link to="/vercatategoria/66" replace className="collection-item bodes-boton-none ">Ropas para niños</Link>
                    <Link to="/vercatategoria/67" replace className="collection-item bodes-boton-none ">Venta de pelo para mujeres</Link>
                    <Link to="/vercatategoria/68" replace className="collection-item bodes-boton-none ">Aguacate</Link>
                    <Link to="/vercatategoria/69" replace className="collection-item bodes-boton-none ">Pelucas</Link>
                    <Link to="/vercatategoria/601" replace className="collection-item bodes-boton-none ">Joyas</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu4">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/4" replace className="collection-item bodes-boton-none "> Todo</Link>
                    <Link to="/vercatategoria/41" replace className="collection-item  bodes-boton-none ">Clases particulares</Link>
                    <Link to="/vercatategoria/46" replace className="collection-item bodes-boton-none ">Buscar empleo</Link>
                    <Link to="/vercatategoria/47" replace className="collection-item bodes-boton-none ">Ofrecer empleo</Link>
                    <Link to="/vercatategoria/48" replace className="collection-item  bodes-boton-none ">limpiesa en hogar</Link>
                    <Link to="/vercatategoria/404" replace className="collection-item bodes-boton-none ">venta de agua a domicilio</Link>
                    <Link to="/vercatategoria/405" replace className="collection-item bodes-boton-none ">lavado de coches a domicilio</Link>
                    <Link to="/vercatategoria/403" replace className="collection-item bodes-boton-none ">coche grua</Link>
                    <Link to="/vercatategoria/401" replace className="collection-item bodes-boton-none ">reparacion de aparatos</Link>
                    <Link to="/vercatategoria/49" replace className="collection-item bodes-boton-none ">mecanicos de coches</Link>

                  </div>
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/406" replace className="collection-item bodes-boton-none ">instaciones electricas</Link>
                    <Link to="/vercatategoria/44" replace className="collection-item bodes-boton-none ">publicidad</Link>
                    <Link to="/vercatategoria/43" replace className="collection-item bodes-boton-none ">informatica</Link>
                    <Link to="/vercatategoria/42" replace className="collection-item bodes-boton-none ">construccion</Link>
                    <Link to="/vercatategoria/402" replace className="collection-item bodes-boton-none ">fontaneria</Link>
                    <Link to="/vercatategoria/45" replace className="collection-item bodes-boton-none ">sanidad</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu5">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/5" className="collection-item bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/51" className="collection-item  bodes-boton-none ">coches en alquiler</Link>
                    <Link to="/vercatategoria/52" className="collection-item  bodes-boton-none ">coches en venta</Link>
                    <Link to="/vercatategoria/53" className="collection-item bodes-boton-none ">taxis en elquiler</Link>
                  </div>
                </div>

                <div className="collection collection-1-1 col col4 bordes-bottom subMenu6">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/2" className="collection-item bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/21" className="collection-item  bodes-boton-none ">conectores rg45</Link>
                    <Link to="/vercatategoria/22" className="collection-item  bodes-boton-none ">cables ethernet</Link>
                    <Link to="/vercatategoria/23" className="collection-item bodes-boton-none ">routers</Link>
                    <Link to="/vercatategoria/24" className="collection-item bodes-boton-none ">antenas</Link>
                    <Link to="/vercatategoria/25" className="collection-item bodes-boton-none ">modems</Link>
                    <Link to="/vercatategoria/26" className="collection-item bodes-boton-none ">Switch</Link>
                  </div>
                </div>
              </div>




            </div>
            {/* fin de los submemus */}

            {/* input para hacer busquedas*/}
            <div className="logotipo-input-sticky">
              <form onSubmit={BuscarForm} className="row esconder-input-seach esconder-input-seach">

                <div className="caja-buscar">
                  {/*
                  <div className=" ">
                    <input id="icon_prefix" onChange={e => {
                      try {
                        if (e.target.value.trim()) {
                          setAbrir(true)
                          console.log(e.target.value.trim())
                          Buscar(e.target.value.trim());
                        } else {
                          setAbrir(false)
                          Buscar("");
                        }
                      } catch (error) {
                        setAbrir(false)
                        Buscar("")
                      }


                    }} name="buscar" type="text" className="validate" placeholder="Que buscas ?" />
                  </div>
 */}
                  <div className=" ">
                    <input id="icon_prefix"
                      name="buscar" type="text" className="validate" placeholder="Que buscas ?" />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button style={{ marginTop: "17px", marginLeft: "15px", cursor: "pointer", display: "flex", alignItems: "center", backgroundColor: "#fff",borderRadius:"10%",height:"31px", width: "50px" }} type="submit" className='btn-small'><FiSearch size={20} color="#212121" /></button>
                </div>
              </form>
            </div>
            {/* fin del input para hacer busquedas*/}

            {/* links de navegacion*/}
            <div className="contenedor-link-sticky esconde-menu-iniciar-idiomas">
              <div className="logotipo-link-sticky ">
                <Link to={validar_user ? `/profil/${user_id}` : "/iniciar"}><FiUser size={20} color="#fff" /><span>{validar_user ? "Cuenta" : "Cuenta"}</span></Link>
                <Link to="/agregar"><FiPlus size={20} color="#fff" /><span>Publicar</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* resultados de la busqueda
      {abrir ?//si hay resultados que los deje aoarecer
        <div>
          {ResultadoBusqueda()}
        </div>
        :
        ""
      }
      */}
    </div>
  )
}

export default BarradeNavegacion;                              