import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsListUl } from "react-icons/bs";
import { FiHome, FiUser, FiSliders } from "react-icons/fi";
import M from "materialize-css"
import AppContext from '../services/contextApp';

//TabButom general para mostrar navegacion en telefonos
const MenuAbajo = () => {
    const { validar_user, user_id } = useContext(AppContext)
    return (
        <div className=" contenedor-fluido-abajo">
            <div className="contenedor-abajo ">
                <div className="contenedor-abajo-link">
                    <Link to="/#" className="link-menu-abajo">
                        <FiHome size={20} color={"#000000"} />
                        <span>Inicio</span>
                    </Link>
                </div>
                <div className="contenedor-abajo-link">
                    <a href="#" data-target="slide-out-nav" className="sidenav-trigger link-menu-abajo mostra">
                        <BsListUl size={20} color={"#000000"} />
                        <span>Categorias</span>
                    </a>
                </div>
                <div className="contenedor-abajo-link">
                    <Link to={validar_user ? `/profil/${user_id}` : "/iniciar"} className="link-menu-abajo">
                        <FiUser size={20} color={"#000000"} />
                        <span>{validar_user ? "Tu cuenta" : "Cuenta"}</span>
                    </Link>
                </div>
                
                <div className="contenedor-abajo-link">
                    <Link to = "#" onClick={() => {
                        var toastHTML = '<span>' + "Para filtrar tienes que elegir una categoria" + '</span>';
                        M.toast({ html: toastHTML });
                    }}
                        className="sidenav-trigger link-menu-abajo mostra">
                        <FiSliders size={20} color={"#000000"} />
                        <span>Filtrar</span>
                    </Link>
                </div>
                
            </div>
        </div>

    )
}

export default MenuAbajo;