import {DATA_CATEGO, NO_MORE_VER_CAT, PAGINA_SIGUIENTE, SERVER_URL,} from "../contexts/constantesVar"
import axios from "axios"





export const PaginationInfiniScroll = async (catParaPage,datosArticulos,dispatch,categoria, paginaSiguiente,pais_post,ciudad_post,precio1,precio2) => {
        console.log(catParaPage)


        if (!Number(catParaPage)) {
            dispatch({
                type: NO_MORE_VER_CAT,
                payload: true
            })
            //console.log(catParaPage, "palabra")
            const datos = await axios({
                method: 'post',
                data: {
                    'page': paginaSiguiente,
                    "pais": pais_post,
                    "ciudad": ciudad_post,
                    "precio1": precio1,
                    "precio2": precio2,
                    "indicator": "clave"
                },
                url: `${SERVER_URL}/paginacion_categorias/${catParaPage}`
            })
            //console.log(datos.data.docs)
            if (paginaSiguiente !== null) {
                //console.log(datos.data.docs.length)

                dispatch({
                    type: DATA_CATEGO,
                    payload: [...datosArticulos, ...datos.data.docs]
                })
                if ((datos.data.docs != "")) {
                    dispatch({
                        type: DATA_CATEGO,
                        payload: [...datosArticulos, ...datos.data.docs]
                    })
                }

                dispatch({
                    type: PAGINA_SIGUIENTE,
                    payload: datos.data.nextPage
                })
                dispatch({
                    type: NO_MORE_VER_CAT,
                    payload: false
                })
            } else {
                dispatch({
                    type: NO_MORE_VER_CAT,
                    payload: false
                })
            }
        } else {
            if (catParaPage.length === 1) {
                //console.log(categoria, "categoria")
                dispatch({
                    type: NO_MORE_VER_CAT,
                    payload: true
                })
                const datos = await axios({
                    method: 'post',
                    data: {
                        'page': paginaSiguiente,
                        "pais": pais_post,
                        "ciudad": ciudad_post,
                        "precio1": precio1,
                        "precio2": precio2,
                        "indicator": "cat"
                    },
                    url: `${SERVER_URL}/paginacion_categorias/${categoria}`
                })
                //console.log(datos.data.docs)
                if (paginaSiguiente !== null) {
                    //console.log(datos.data.docs.length)
                    dispatch({
                        type: DATA_CATEGO,
                        payload: [...datosArticulos, ...datos.data.docs]
                    })
                    if ((datos.data.docs != "")) {
                        dispatch({
                            type: DATA_CATEGO,
                            payload: [...datosArticulos, ...datos.data.docs]
                        })
                    }
                    dispatch({
                        type: PAGINA_SIGUIENTE,
                        payload: datos.data.nextPage
                    })
                    dispatch({
                        type: NO_MORE_VER_CAT,
                        payload: false
                    })
                } else {
                    //console.log("esta bien")
                    dispatch({
                        type: NO_MORE_VER_CAT,
                        payload: false
                    })
                }
            }

            if (catParaPage.length === 2) {
                //console.log(catParaPage, "subcategoria", pais_post)
                dispatch({
                    type: NO_MORE_VER_CAT,
                    payload: true
                })
                const datos = await axios({
                    method: 'post',
                    data: {
                        'page': paginaSiguiente,
                        "pais": pais_post,
                        "ciudad": ciudad_post,
                        "precio1": precio1,
                        "precio2": precio2,
                        "indicator": "catsub"
                    },
                    url: `${SERVER_URL}/paginacion_categorias/${categoria}`
                })
                //console.log(datos.data.docs)
                if (paginaSiguiente !== null) {
                    //console.log(datos.data.docs.length)
                    dispatch({
                        type: DATA_CATEGO,
                        payload: [...datosArticulos, ...datos.data.docs]
                    })
                  
                    if ((datos.data.docs != "")) {
                        dispatch({
                            type: DATA_CATEGO,
                            payload: [...datosArticulos, ...datos.data.docs]
                        })
                    
                    }

                    dispatch({
                        type: PAGINA_SIGUIENTE,
                        payload: datos.data.nextPage
                    })
                    dispatch({
                        type: NO_MORE_VER_CAT,
                        payload: false
                    })
                } else {
                    //console.log("esta bien")
                    dispatch({
                        type: NO_MORE_VER_CAT,
                        payload: false
                    })
                }
            }
            if (catParaPage.length === 3) {
                dispatch({
                    type: NO_MORE_VER_CAT,
                    payload: true
                })
                //console.log(catParaPage, "subsubcategoria", pais_post, categoria)
                const datos = await axios({
                    method: 'post',
                    data: {
                        'page': paginaSiguiente,
                        "pais": pais_post,
                        "ciudad": ciudad_post,
                        "precio1": precio1,
                        "precio2": precio2,
                        "indicator": "catsubsub"
                    },
                    url: `${SERVER_URL}/paginacion_categorias/${categoria}`
                })
                console.log(datos.data.docs)
                if (paginaSiguiente !== null) {
                    //console.log(datos.data.docs.length)
                    dispatch({
                        type: DATA_CATEGO,
                        payload: [...datosArticulos, ...datos.data.docs]
                    })
                    //setDatosArticulos([...datosArticulos, ...datos.data.docs])
                    if ((datos.data.docs != "")/*&&(datos.data.docs[0]["categoria"]!=datosArticulosCateg[0]["categoria"])*/) {
                        dispatch({
                            type: DATA_CATEGO,
                            payload: [...datosArticulos, ...datos.data.docs]
                        })
                        //setDatosArticulosCateg(datos.data.docs)
                    }

                    dispatch({
                        type: PAGINA_SIGUIENTE,
                        payload: datos.data.nextPage
                    })
                    //setPaginaSiguiente(datos.data.nextPage)
                    dispatch({
                        type: NO_MORE_VER_CAT,
                        payload: false
                    })

                } else {
                    //console.log("esta bien")
                    dispatch({
                        type: NO_MORE_VER_CAT,
                        payload: false
                    })
                }
            }

        }

    }