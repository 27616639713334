import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
//import '../../styles/BarradeNavegacion.css'
import { Link } from "react-router-dom"
import AppContext from '../../services/contextApp'
import { BsListUl } from "react-icons/bs";
import { FiUser, FiPlus, FiSearch } from "react-icons/fi";
import SinavCat from './sinavCat';
import { FiltrarPorPrecio } from '../filtrarPorPrecio';
import FiltraComponente from '../filtraComponente';
import { SERVER_URL } from '../../contexts/constantesVar';


const BarradeNavegacionCat = ({ history }) => {
  const { pais_post, ciudad_post, validar_user, user_id, BuscarConUnLink, dispatch, BuscarConFormInput } = useContext(AppContext)
  const [buscar, setBuscar] = useState([])
  const [abrir, setAbrir] = useState(false)


  // funcion para realizar busquedas
  /*
  const Buscar = async (clave) => {
    if (clave) {
      const datos = await axios({ method: 'post', data: { 'clave': clave, "pais": pais_post, "ciudad": ciudad_post }, url: `${SERVER_URL}/buscar` })
      if (datos.data) {
        setBuscar(datos.data.docs)
      }
      console.log(datos.data)
    } else {
      setBuscar([])
    }
    Validar()


  }
*/




  // funcion para traer resultados de busquedas a trvez de una lista mientras que se va escribiendo en en input
  /*
  const ResultadoBusqueda = () => {

    if (buscar) {
      return (
        <div className="collection collection-para-buscar">
          {buscar.map((clave) =>
            <Link onClick={() => { BuscarLink(clave["subcategoria"]) }} className="collection-item  ">{clave["brevedescripcion"]}</Link>
          )}
        </div>
      )


    } else {
      return (
        <div></div>
      )
    }
  }
  */

  //buscar con link
  
  const BuscarLink = (linkId) => {
    BuscarConUnLink(linkId, dispatch, pais_post, ciudad_post)

    CallSubMenu(9)
    setBuscar([])
    Validar()
    document.getElementById("icon_prefix").value = ""
    document.getElementById("ciudadB").selectedIndex = 0
    document.getElementById("precioB").selectedIndex = 0
  }

  // funcion para activar la realizacion de busquedas
  
  const Validar = () => {
    if (buscar) {
      return true
    } else {
      return false
    }
  }
  
  // funcion para buscar desde el input
  const BuscarForm = async (e) => {
    e.preventDefault()
    if (e.target.buscar.value) {
      BuscarConFormInput(e.target.buscar.value, dispatch, pais_post)
      CallSubMenu(9)
      document.getElementById("icon_prefix").value = ""
    } else {
      
    }

    //history.push(`/vercatategoria/${e.target.buscar.value}`)
  }

  //funcion para llamar a los submenus


  const CallSubMenu = (sub) => {

    for (let i = 1; i < 12; i++) {
      if (i === sub) {
        if (document.querySelector(`.subMenu${i}`)) {
          document.querySelector(`.subMenu${i}`).style.display = "block"
        } else {

        }
      } else {
        if (document.querySelector(`.subMenu${i}`)) {
          document.querySelector(`.subMenu${i}`).style.display = "none"
        } else {

        }
      }

    }
  }
  return (
    <div>
      <div className="contenedor-sticky  container-fluid  barra-de-nav-scrollTop ">
        <div className="menu-sticky container" id="menu">
          <div className=" contenedor-enlaces-nav-sticky">
            <div className="logotipo-sticky">
              <Link to="/#" ><img src={"https://res.cloudinary.com/mumbex/image/upload/v1664622527/logo512_xmwnqy.png"} alt="" /></Link>
            </div>
            <div className="btn-departamentos-sticky esconde-menu-iniciar-idiomas">
              <a
                onMouseLeave={
                  () => {
                    CallSubMenu(9)

                  }}
              ><span>categorias</span> <BsListUl size={20} color="#fff" /></a>

              <div className="dropppdown-content-sticky"
                onMouseLeave={
                  () => {
                    CallSubMenu(9)

                  }}
              >
                <div className="collection collection-1 col col4 bordes-bottom">
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(1)
                      }}

                    to="#" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292114/49_aqbsj7.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Electronica
                  </Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(10)
                      }}

                    to="#" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/v1667469676/hogar_vlmpoe.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Hogar
                  </Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(6)
                      }}
                    to="#" className="collection-item droppdown-5 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654242329/63_fmbck9.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Telecom.</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(5)
                      }}
                    to="#" className="collection-item droppdown-4 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654292087/62_kaf2cu.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Coches</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(2)

                      }}


                    to="#" className="collection-item droppdown-3 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654240227/56_xucqe2.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Casas en alquiler</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(3)

                      }}


                    to="#" className="collection-item droppdown-3 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654206427/1_7_ivmkau.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Casas en venta</Link>



                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(7)
                      }}
                    to="#" className="collection-item droppdown-4 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243221/67_zulzib.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Vestimenta</Link>
                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(4)
                      }}
                    to="#" className="collection-item droppdown-5 bodes-boton-none" >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243124/66_gmotgv.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Servicios</Link>


                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(9)
                      }}

                    to="/vercatategoria/7" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243449/68_lxeaxc.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Terrenos
                  </Link>

                  <Link
                    onMouseEnter={
                      () => {
                        CallSubMenu(9)
                      }}

                    to="/vercatategoria/16" id='d1' className="collection-item mostrar  bodes-boton-none droppdown-2 electronica " >
                    <img src="https://res.cloudinary.com/mumbex/image/upload/c_thumb,w_200,g_face/v1654243798/69_qvvhqu.png" className="responsive-img imagen-menu-grandes-dis" alt="" />
                    Electrodomesticos
                  </Link>

                </div>

                {/* submemus */}

                <div className="collection collection-1-1 col col4 bordes-bottom subMenu1">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/1" onClick={() => { BuscarLink("1") }} className="collection-item bodes-boton-none "> Todo</Link>
                    <Link to="/vercatategoria/11" onClick={() => { BuscarLink("11") }} className="collection-item  bodes-boton-none ">Telefonos</Link>
                    <Link to="/vercatategoria/12" onClick={() => { BuscarLink("12") }} className="collection-item  bodes-boton-none ">Televisiones</Link>
                    <Link to="/vercatategoria/13" onClick={() => { BuscarLink("13") }} className="collection-item bodes-boton-none ">Ordenadores</Link>
                    <Link to="/vercatategoria/14" onClick={() => { BuscarLink("14") }} className="collection-item bodes-boton-none "> Material informatico</Link>
                    <Link to="/vercatategoria/15" onClick={() => { BuscarLink("15") }} className="collection-item bodes-boton-none "> Videojuegos</Link>
                    <Link to="/vercatategoria/17" onClick={() => { BuscarLink("17") }} className="collection-item bodes-boton-none "> Arduino</Link>
                    <Link to="/vercatategoria/18" onClick={() => { BuscarLink("18") }} className="collection-item bodes-boton-none "> Equipos de audiovision</Link>
                  </div>
                </div>

                <div className="collection collection-1-1 col col4 bordes-bottom subMenu10">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/8" onClick={() => { BuscarLink("8") }} className="collection-item bodes-boton-none "> Todo</Link>
                    <Link to="/vercatategoria/81" onClick={() => { BuscarLink("81") }} className="collection-item  bodes-boton-none ">Material de construccion</Link>
                    <Link to="/vercatategoria/82" onClick={() => { BuscarLink("82") }} className="collection-item  bodes-boton-none ">Material para decorrar el hogar</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu2">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/31" onClick={() => { BuscarLink("31") }} className="collection-item  bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/311" onClick={() => { BuscarLink("311") }} className="collection-item  bodes-boton-none ">Alquilar casa de una habitacion</Link>
                    <Link to="/vercatategoria/312" onClick={() => { BuscarLink("312") }} className="collection-item  bodes-boton-none ">Alquilar casa de dos habitaciones</Link>
                    <Link to="/vercatategoria/313" onClick={() => { BuscarLink("313") }} className="collection-item bodes-boton-none ">Alquilar casa de tres habitaciones</Link>
                    <Link to="/vercatategoria/314" onClick={() => { BuscarLink("314") }} className="collection-item bodes-boton-none ">Alquilar casa de cuatro habitaciones</Link>
                    <Link to="/vercatategoria/315" onClick={() => { BuscarLink("315") }} className="collection-item bodes-boton-none ">Alquilar casa de cinco habitaciones</Link>
                    <Link to="/vercatategoria/316" onClick={() => { BuscarLink("316") }} className="collection-item bodes-boton-none ">Alquilar casa de mas de cinco habitaciones</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu3">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/32" onClick={() => { BuscarLink("32") }} className="collection-item  bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/321" onClick={() => { BuscarLink("321") }} className="collection-item  bodes-boton-none ">Vender casa una habitacion</Link>
                    <Link to="/vercatategoria/322" onClick={() => { BuscarLink("322") }} className="collection-item  bodes-boton-none ">Vender casa dos habitaciones</Link>
                    <Link to="/vercatategoria/323" onClick={() => { BuscarLink("323") }} className="collection-item bodes-boton-none ">Vender casa tres habitaciones</Link>
                    <Link to="/vercatategoria/324" onClick={() => { BuscarLink("324") }} className="collection-item bodes-boton-none ">Vender casa cuatro habitaciones</Link>
                    <Link to="/vercatategoria/325" onClick={() => { BuscarLink("325") }} className="collection-item bodes-boton-none ">Vender casa cinco habitaciones</Link>
                    <Link to="/vercatategoria/326" onClick={() => { BuscarLink("326") }} className="collection-item bodes-boton-none ">Vender casa mas de cinco habitaciones</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu7">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/6" onClick={() => { BuscarLink("6") }} className="collection-item bodes-boton-none ">todo</Link>
                    <Link to="/vercatategoria/61" onClick={() => { BuscarLink("61") }} className="collection-item  bodes-boton-none ">Zapatos para hombres</Link>
                    <Link to="/vercatategoria/62" onClick={() => { BuscarLink("62") }} className="collection-item  bodes-boton-none ">Zapatos para mujeres</Link>
                    <Link to="/vercatategoria/63" onClick={() => { BuscarLink("63") }} className="collection-item  bodes-boton-none ">Zapatos para niños</Link>
                    <Link to="/vercatategoria/64" onClick={() => { BuscarLink("64") }} className="collection-item bodes-boton-none ">Ropas para mujeres</Link>
                    <Link to="/vercatategoria/65" onClick={() => { BuscarLink("65") }} className="collection-item bodes-boton-none ">Ropas para hombres</Link>
                    <Link to="/vercatategoria/66" onClick={() => { BuscarLink("66") }} className="collection-item bodes-boton-none ">Ropas para niños</Link>
                    <Link to="/vercatategoria/67" onClick={() => { BuscarLink("67") }} className="collection-item bodes-boton-none ">Venta de pelo para mujeres</Link>
                    <Link to="/vercatategoria/68" onClick={() => { BuscarLink("68") }} className="collection-item bodes-boton-none ">Aguacate</Link>
                    <Link to="/vercatategoria/69" onClick={() => { BuscarLink("69") }} className="collection-item bodes-boton-none ">Pelucas</Link>
                    <Link to="/vercatategoria/601" onClick={() => { BuscarLink("601") }} className="collection-item bodes-boton-none ">Joyas</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu4">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/4" onClick={() => { BuscarLink("4") }} className="collection-item bodes-boton-none "> Todo</Link>
                    <Link to="/vercatategoria/41" onClick={() => { BuscarLink("41") }} className="collection-item  bodes-boton-none ">Clases particulares</Link>
                    <Link to="/vercatategoria/46" onClick={() => { BuscarLink("46") }} className="collection-item bodes-boton-none ">Buscar empleo</Link>
                    <Link to="/vercatategoria/47" onClick={() => { BuscarLink("47") }} className="collection-item bodes-boton-none ">Ofrecer empleo</Link>
                    <Link to="/vercatategoria/48" onClick={() => { BuscarLink("48") }} className="collection-item  bodes-boton-none ">limpiesa en hogar</Link>
                    <Link to="/vercatategoria/404" onClick={() => { BuscarLink("404") }} className="collection-item bodes-boton-none ">venta de agua a domicilio</Link>
                    <Link to="/vercatategoria/405" onClick={() => { BuscarLink("405") }} className="collection-item bodes-boton-none ">lavado de coches a domicilio</Link>
                    <Link to="/vercatategoria/403" onClick={() => { BuscarLink("403") }} className="collection-item bodes-boton-none ">coche grua</Link>
                    <Link to="/vercatategoria/401" onClick={() => { BuscarLink("401") }} className="collection-item bodes-boton-none ">reparacion de aparatos</Link>
                    <Link to="/vercatategoria/49" onClick={() => { BuscarLink("49") }} className="collection-item bodes-boton-none ">mecanicos de coches</Link>

                  </div>
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/406" onClick={() => { BuscarLink("406") }} className="collection-item bodes-boton-none ">instaciones electricas</Link>
                    <Link to="/vercatategoria/44" onClick={() => { BuscarLink("44") }} className="collection-item bodes-boton-none ">publicidad</Link>
                    <Link to="/vercatategoria/43" onClick={() => { BuscarLink("43") }} className="collection-item bodes-boton-none ">informatica</Link>
                    <Link to="/vercatategoria/42" onClick={() => { BuscarLink("42") }} className="collection-item bodes-boton-none ">construccion</Link>
                    <Link to="/vercatategoria/402" onClick={() => { BuscarLink("402") }} className="collection-item bodes-boton-none ">fontaneria</Link>
                    <Link to="/vercatategoria/45" onClick={() => { BuscarLink("45") }} className="collection-item bodes-boton-none ">sanidad</Link>
                  </div>
                </div>
                <div className="collection collection-1-1 col col4 bordes-bottom subMenu5">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/5" onClick={() => { BuscarLink("5") }} className="collection-item bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/51" onClick={() => { BuscarLink("51") }} className="collection-item  bodes-boton-none ">coches en alquiler</Link>
                    <Link to="/vercatategoria/52" onClick={() => { BuscarLink("52") }} className="collection-item  bodes-boton-none ">coches en venta</Link>
                    <Link to="/vercatategoria/53" onClick={() => { BuscarLink("53") }} className="collection-item bodes-boton-none ">taxis en elquiler</Link>
                  </div>
                </div>

                <div className="collection collection-1-1 col col4 bordes-bottom subMenu6">
                  <div className="collection collection-1-2">
                    <Link to="/vercatategoria/2" onClick={() => { BuscarLink("2") }} className="collection-item bodes-boton-none ">Todo</Link>
                    <Link to="/vercatategoria/21" onClick={() => { BuscarLink("21") }} className="collection-item  bodes-boton-none ">conectores rg45</Link>
                    <Link to="/vercatategoria/22" onClick={() => { BuscarLink("22") }} className="collection-item  bodes-boton-none ">cables ethernet</Link>
                    <Link to="/vercatategoria/23" onClick={() => { BuscarLink("23") }} className="collection-item bodes-boton-none ">routers</Link>
                    <Link to="/vercatategoria/24" onClick={() => { BuscarLink("24") }} className="collection-item bodes-boton-none ">antenas</Link>
                    <Link to="/vercatategoria/25" onClick={() => { BuscarLink("25") }} className="collection-item bodes-boton-none ">modems</Link>
                    <Link to="/vercatategoria/26" onClick={() => { BuscarLink("26") }} className="collection-item bodes-boton-none ">Switch</Link>
                  </div>
                </div>
              </div>





            </div>
            {/* fin de los submemus */}

            {/* input para hacer busquedas*/}
            <div className="logotipo-input-sticky">
              <form onSubmit={BuscarForm} className="row esconder-input-seach esconder-input-seach">

                <div className="caja-buscar">

                  <div className=" ">
                    {/* 
                      <input id="icon_prefix" onChange={e => {
                      try {
                        if (e.target.value.trim()) {
                          setAbrir(true)
                          console.log(e.target.value.trim())
                          Buscar(e.target.value.trim());
                        } else {
                          setAbrir(false)
                          Buscar("");
                        }
                      } catch (error) {
                        setAbrir(false)
                        Buscar("");
                      }



                    }} name="buscar" type="text" className="validate" placeholder="Que buscas ?" />
                     */}
                    <input id="icon_prefix" name="buscar" type="text" className="validate" placeholder="Que buscas ?" />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button style={{ marginTop: "17px", marginLeft: "15px", cursor: "pointer", display: "flex", alignItems: "center", backgroundColor: "#fff",borderRadius:"10%",height:"31px", width: "50px" }} type="submit" className='btn-small'><FiSearch size={20} color="#212121" /></button>
                </div>
              </form>
            </div>
            {/* fin del input para hacer busquedas*/}

            {/* links de navegacion*/}
            <div className="contenedor-link-sticky esconde-menu-iniciar-idiomas">
              <div className="logotipo-link-sticky ">
                <Link to={validar_user ? `/profil/${user_id}` : "/iniciar"}><FiUser size={20} color="#fff" /><span>{validar_user ? "Cuenta" : "Cuenta"}</span></Link>
                <Link to="/agregar"><FiPlus size={20} color="#fff" /><span>Publicar</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* resultados de la busqueda
      {abrir ?//si hay resultados que los deje aoarecer
        <div>
          {ResultadoBusqueda()}
        </div>
        :
        ""
      }*/}
    </div>
  )
}

export default BarradeNavegacionCat;                              