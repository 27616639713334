/*var userValidate = JSON.parse(window.localStorage.getItem("userData"))[3].user_valide
var userName = JSON.parse(window.localStorage.getItem("userData"))[0].user_name
var userId = JSON.parse(window.localStorage.getItem("userData"))[0].user_id*/
export const InitialState = {
    categoria: "",
    subCategoria: "",
    dataCategoria:[],
    dataHome:null,
    spinnerDataHome:false,
    spinnerDataCategoria:null,
    algoVamal:false,
    spinnerDataProduct:false,
    activarBusqueda:true,
    claveParaBuscar:"",
    esconderSubCategoria:true,
    buscar:"",
    probarSiHay:false,
    loginSpinner:false,
    registerSpinner:false,
    catParaPage:"",
    paginaSiguiente:"1",
    userUndefined:false,
    respServerUserUndefined:"",
    spinnerAddProduct:false,
    validar_user:/*window.localStorage.getItem("userDatos")?JSON.parse(window.localStorage.getItem("userDatos"))[3].user_valide*/false,
    user_name :/*window.localStorage.getItem("userDatos")?JSON.parse(window.localStorage.getItem("userDatos"))[0].user_name*/"",   
    user_id:/*window.localStorage.getItem("userDatos")?JSON.parse(window.localStorage.getItem("userDatos"))[1].user_id*/"",
    pais_post:"01",
    ciudad_post:"",
    busquedaClave:"",
    precio1:0,
    precio2:0,
    algoSaliMalHome:false,
    spinnerDatosHome:false,
    verificarDataHome:false,
    arrayDataHome:[],
    siguientePaginaNumero:"",
    array_servicios:[],
    info_product:[],
    noMoreVerCat:true,
    agenciasTiendas:[{st:false,data:[]}],


    //articulosCatScreen:[]
};