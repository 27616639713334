import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ClipLoader, MoonLoader } from "react-spinners";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Auth from '../contexts/Auth'
//import '../styles/formularioAgregar.css'
import BarradeNavegacion from "../components/navegacionBarAll/BarradeNavegacion";
import MenuAbajo from '../components/menuAbajo';
import Footer from '../components/Footer';
import M from "materialize-css"
import AppContext from "../services/contextApp";
import { FaWhatsapp, FaPhone, FaTimes } from "react-icons/fa"

import { SERVER_URL } from "../contexts/constantesVar";

const ForProducto = () => {
    const { Logins, loginSpinner, respServerUserUndefined, user_id, user_name } = useContext(AppContext)
    const [categoria, setCategoria] = useState("")
    const [subCateg, setSubCateg] = useState("")
    const [subCategTele, setSubCategTele] = useState("")
    const [subCategElectro, setSubCategElectro] = useState("")
    const [subCategCasa, setSubCategCasa] = useState("")
    const [subSubCateg, setSubSubCateg] = useState("")
    const [nombreDelArticulo, setNombreDelArticulo] = useState("")
    const [breveDescripcion, setBreveDescripcion] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [pais, setPais] = useState("")
    const [ciudad, setCiudad] = useState("")
    const [localizacion, setLocalizacion] = useState("")
    const [numeroW, setNumeroW] = useState("")
    const [numeroT, setNumeroT] = useState("")
    const [precio, setPrecio] = useState("")
    const [precioString, setPrecioString] = useState("")
    const [imagen1, setImagen1] = useState('')
    const [imagen2, setImagen2] = useState('')
    const [imagen3, setImagen3] = useState('')
    const [imagen4, setImagen4] = useState('')
    const [preview, setPreview] = useState("");
    const [preview1, setPreview1] = useState("");
    const [preview2, setPreview2] = useState("");
    const [preview3, setPreview3] = useState("");
    const [addData, setVal] = useState('')
    const [probar, setProbar] = useState(false)


    const WIDTH = 500

    //obteniendo imagenes en cada input file del formulario
    const handleChangeImagen1 = e => {

        if (e[0]) {
            const jpG = e[0].name.split(".")[e[0].name.split(".").length - 1] == "jpg"
            const pnG = e[0].name.split(".")[e[0].name.split(".").length - 1] == "png"
            const jpeG = e[0].name.split(".")[e[0].name.split(".").length - 1] == "jpeg"

            if (jpG || pnG || jpeG) {
                const reader = new FileReader()
                reader.readAsDataURL(e[0])

                reader.onload = (event) => {
                    let img_url = event.target.result
                    //console.log(img_url)
                    let image = document.createElement("img")
                    image.src = img_url
                    image.onload = async (e) => {
                        //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                        let canvas = document.createElement("canvas")
                        let ratio = WIDTH / e.target.width
                        canvas.width = WIDTH
                        canvas.height = e.target.height * ratio
                        //crear objeto canvas 
                        const context = canvas.getContext("2d")
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                        let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                        //console.log(new_img_url)


                        //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                        const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                        const img_convert_to_blob = await img_fetch.blob("image/png")

                        setImagen1(img_convert_to_blob)
                        //console.log(img_convert_to_blob)

                    }
                }
            } else {
                setImagen1(null)
            }
        } else {
            setImagen1(null)

        }
        if (e[1]) {
            const jpG = e[1].name.split(".")[e[1].name.split(".").length - 1] == "jpg"
            const pnG = e[1].name.split(".")[e[1].name.split(".").length - 1] == "png"
            const jpeG = e[1].name.split(".")[e[1].name.split(".").length - 1] == "jpeg"

            if (jpG || pnG || jpeG) {
                const reader = new FileReader()
                reader.readAsDataURL(e[1])

                reader.onload = (event) => {
                    let img_url = event.target.result
                    //console.log(img_url)
                    let image = document.createElement("img")
                    image.src = img_url
                    image.onload = async (e) => {
                        //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                        let canvas = document.createElement("canvas")
                        let ratio = WIDTH / e.target.width
                        canvas.width = WIDTH
                        canvas.height = e.target.height * ratio
                        //crear objeto canvas 
                        const context = canvas.getContext("2d")
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                        let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                        //console.log(new_img_url)


                        //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                        const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                        const img_convert_to_blob = await img_fetch.blob("image/png")

                        setImagen2(img_convert_to_blob)
                        //console.log(img_convert_to_blob)

                    }
                }
            } else {
                setImagen2(null)
            }
        } else {
            setImagen2(null)
        }
        if (e[2]) {
            //OPTIMIZACION DEL IMAGEN3
            const jpG = e[2].name.split(".")[e[2].name.split(".").length - 1] == "jpg"
            const pnG = e[2].name.split(".")[e[2].name.split(".").length - 1] == "png"
            const jpeG = e[2].name.split(".")[e[2].name.split(".").length - 1] == "jpeg"

            if (jpG || pnG || jpeG) {
                const reader = new FileReader()
                reader.readAsDataURL(e[2])

                reader.onload = (event) => {
                    let img_url = event.target.result
                    //console.log(img_url)
                    let image = document.createElement("img")
                    image.src = img_url
                    image.onload = async (e) => {
                        //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                        let canvas = document.createElement("canvas")
                        let ratio = WIDTH / e.target.width
                        canvas.width = WIDTH
                        canvas.height = e.target.height * ratio
                        //crear objeto canvas 
                        const context = canvas.getContext("2d")
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                        let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                        //console.log(new_img_url)


                        //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                        const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                        const img_convert_to_blob = await img_fetch.blob("image/png")

                        setImagen3(img_convert_to_blob)
                        //console.log(img_convert_to_blob)

                    }
                }
            } else {
                setImagen3(null)
            }
        } else {
            setImagen3(null)
        }
        if (e[3]) {
            //OPTIMIZACION DEL IMAGEN4
            const jpG = e[3].name.split(".")[e[3].name.split(".").length - 1] == "jpg"
            const pnG = e[3].name.split(".")[e[3].name.split(".").length - 1] == "png"
            const jpeG = e[3].name.split(".")[e[3].name.split(".").length - 1] == "jpeg"

            if (jpG || pnG || jpeG) {
                const reader = new FileReader()
                reader.readAsDataURL(e[3])

                reader.onload = (event) => {
                    let img_url = event.target.result
                    //console.log(img_url)
                    let image = document.createElement("img")
                    image.src = img_url
                    image.onload = async (e) => {
                        //COMENZANDO CON LA REDUCCION DEL TAMAÑO DEL IMAGEN
                        let canvas = document.createElement("canvas")
                        let ratio = WIDTH / e.target.width
                        canvas.width = WIDTH
                        canvas.height = e.target.height * ratio
                        //crear objeto canvas 
                        const context = canvas.getContext("2d")
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                        let new_img_url = context.canvas.toDataURL("image/png", 100)//obtencion del imagen en base64
                        //console.log(new_img_url)


                        //VOLVER A CONVERTIR LA IMAGEN EN FORMATO BLOB ES DECIR PASMOS DE "base64 ----> blob" 
                        const img_fetch = await fetch(`data:image/png;base64,${new_img_url.split(",")[1]}`)
                        const img_convert_to_blob = await img_fetch.blob("image/png")

                        setImagen4(img_convert_to_blob)
                        //console.log(img_convert_to_blob)
                    }
                }
            } else {
                setImagen4(null)
            }
        } else {
            setImagen4(null)
        }

        //OPTIMIZACION DEL IMAGEN1


    }


    //funcion para enviar los datos en el api y asi guardarlos
    const GuardarProducto = async e => {
        e.preventDefault();

        //validar el formulario esta bien rellenado
        const precioValidar = (precio || precioString)
        const validarForm1 = breveDescripcion && imagen1 && categoria && subCateg && nombreDelArticulo && numeroW && precioValidar && subSubCateg && pais && ciudad;
        const validarForm2 = breveDescripcion && imagen1 && categoria && numeroW && nombreDelArticulo && precioValidar && subCateg && pais && ciudad;

        if (validarForm1) {
            setProbar(true)
            console.log(categoria, subCateg, subSubCateg)
            console.log(validarForm2, validarForm1)
            const fs = new FormData()
            fs.append("user", user_name)
            fs.append("user_id", user_id)
            fs.append("categoria", categoria)
            fs.append("subcategoria", subCateg)
            fs.append("subsubcategoria", subSubCateg)
            fs.append("breveDescripcion", breveDescripcion)
            fs.append("precio", precio)
            fs.append("paiz", pais)
            fs.append("ciudad", ciudad)
            fs.append("nombreOMarca", nombreDelArticulo)
            fs.append("localizacion", localizacion)
            fs.append("numeroT", numeroT)
            fs.append("precioString", precioString)
            fs.append("numeroW", numeroW)
            fs.append("descripcion", descripcion)
            fs.append("imagen1", imagen1)
            fs.append("imagen2", imagen2)
            fs.append("imagen3", imagen3)
            fs.append("imagen4", imagen4)

            try {
                const respuesta = await axios.post(`${SERVER_URL}/agregarProducto`, fs, { headers: { "Content-Type": "multipart/form-data" } })
                if (respuesta.data === "producto publicado") {
                    setProbar(false)
                    var toastHTML = '<span>' + respuesta.data + '</span>';
                    M.toast({ html: toastHTML });


                    // RESETEAR FORMULARIO 
                    setPreview(null)
                    setImagen1(null)
                    Limpiarfotos()


                    setCategoria("");
                    setSubCateg("");
                    setSubSubCateg("")
                    setPais("");
                    setCiudad("");
                    setLocalizacion("");
                    setNombreDelArticulo("");
                    setBreveDescripcion("");
                    setPrecio("");
                    setNumeroW("");
                    setNumeroT("");


                    document.getElementById("cat1").selectedIndex = 0
                    document.getElementById("cat1").style.borderColor = "red"

                    document.getElementById("pais").selectedIndex = 0
                    document.getElementById("pais").style.borderColor = "red"


                    document.getElementById("input1").value = ""
                    document.getElementById("input1").style.borderColor = "red"

                    document.getElementById("input2").value = ""
                    document.getElementById("input2").style.borderColor = "red"


                    document.getElementById("precio").value = ""
                    document.getElementById("precio").style.borderColor = "red"

                    document.getElementById("descripcion").value = ""
                    document.getElementById("descripcion").style.borderColor = "red"

                    document.getElementById("nombre").value = ""
                    document.getElementById("nombre").style.borderColor = "red"

                    document.getElementById("localizacion").value = ""
                    document.getElementById("localizacion").style.borderColor = "red"


                    //FIN DE  RESETEAR FORMULARIO 





                } else {
                    setProbar(false)
                    var toastHTML = '<span>' + respuesta.data + '</span>';
                    M.toast({ html: toastHTML });
                }
            } catch {
                setProbar(false)
                var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
                M.toast({ html: toastHTML });
            }

        } else {

            if (validarForm2) {
                setProbar(true)
                //console.log(categoria, subCateg)
                const fs = new FormData()
                fs.append("user", user_name)
                fs.append("user_id", user_id)
                fs.append("categoria", categoria)
                fs.append("subcategoria", subCateg)
                fs.append("subsubcategoria", subSubCateg)
                fs.append("descripcion", descripcion)
                fs.append("precio", precio)
                fs.append("paiz", pais)
                fs.append("ciudad", ciudad)
                fs.append("nombreOMarca", nombreDelArticulo)
                fs.append("localizacion", localizacion)
                fs.append("numeroT", numeroT)
                fs.append("precioString", precioString)
                fs.append("numeroW", numeroW)
                fs.append("breveDescripcion", breveDescripcion)
                fs.append("imagen1", imagen1)
                fs.append("imagen2", imagen2)
                fs.append("imagen3", imagen3)
                fs.append("imagen4", imagen4)


                try {
                    const respuesta = await axios.post(`${SERVER_URL}/agregarProducto`, fs, { headers: { "Content-Type": "multipart/form-data" } })
                    if (respuesta.data === "producto publicado") {
                        setProbar(false)
                        var toastHTML = '<span className = "text-red">' + respuesta.data + '</span>';
                        M.toast({ html: toastHTML });

                        // RESETEAR FORMULARIO 
                        setPreview(null)
                        setImagen1(null)
                        Limpiarfotos()




                        setCategoria("");
                        setSubCateg("");
                        setSubSubCateg("")
                        setPais("");
                        setCiudad("");
                        setLocalizacion("");
                        setNombreDelArticulo("");
                        setBreveDescripcion("");
                        setPrecio("");
                        setPrecioString("");
                        setNumeroW("");
                        setNumeroT("");


                        document.getElementById("cat1").selectedIndex = 0
                        document.getElementById("cat1").style.borderColor = "red"

                        document.getElementById("pais").selectedIndex = 0
                        document.getElementById("pais").style.borderColor = "red"


                        document.getElementById("input1").value = ""
                        document.getElementById("input1").style.borderColor = "red"

                        document.getElementById("input2").value = ""
                        document.getElementById("input2").style.borderColor = "red"


                        document.getElementById("precio").value = ""
                        document.getElementById("precio").style.borderColor = "red"

                        document.getElementById("descripcion").value = ""
                        document.getElementById("descripcion").style.borderColor = "red"

                        document.getElementById("nombre").value = ""
                        document.getElementById("nombre").style.borderColor = "red"

                        document.getElementById("localizacion").value = ""
                        document.getElementById("localizacion").style.borderColor = "red"

                        //FIN DE  RESETEAR FORMULARIO 


                    } else {
                        setProbar(false)
                        var toastHTML = '<span>' + respuesta.data + '</span>';
                        M.toast({ html: toastHTML });
                    }
                } catch {
                    setProbar(false)
                    var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
                    M.toast({ html: toastHTML });
                }

            } else {
                //console.log("datos incompletos")
            }

        }




    }

    const Limpiarfotos = () => {
        setPreview1(""); setImagen2("")
        setPreview2(""); setImagen3("")
        setPreview3(""); setImagen4("")

    }

    //FUNCION PARA HACER APARECER EL BOTUN QUE PERMITE ENVIAR LOS DATOS A LA API PARA ASI AGREGAR PRODUCTO

    const ValidarFormulario = () => {
        var validarForm = ""
        const precioValidar = (precio || precioString)
        if (subSubCateg) {
            validarForm = breveDescripcion && imagen1 && categoria && numeroW && nombreDelArticulo && precioValidar && subCateg && subSubCateg;
        } else {
            validarForm = breveDescripcion && imagen1 && categoria && numeroW && nombreDelArticulo && precioValidar && subCateg;
        }
        return validarForm
    }


    useEffect(() => {

        //visualizar las imagenes elegidas
        if (imagen1) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview(reader.result);

            }
            reader.readAsDataURL(imagen1)
        } else {
            setPreview(null)
        }
        if (imagen2) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview1(reader.result);

            }
            reader.readAsDataURL(imagen2)
        } else {
            setPreview1(null)
        }
        if (imagen3) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview2(reader.result);

            }
            reader.readAsDataURL(imagen3)
        } else {
            setPreview2(null)
        }
        if (imagen4) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview3(reader.result);

            }
            reader.readAsDataURL(imagen4)
        } else {
            setPreview3(null)
        }



        //contar carracteres en el input
        const textareas = document.querySelector("textarea.textareaCount")
        M.CharacterCounter.init(textareas, {})

        const input = document.querySelector("input#input1 , input#input2")
        M.CharacterCounter.init(input, {})

    }, [imagen1, imagen2, imagen3, imagen4, categoria, subCategTele])

    return (
        <div className="">
            <BarradeNavegacion />
            <section className="formulario-agregar ">
                <div className="formulario-agregar-respon container ">
                    <form className="formulario-agregar-form z-depth-1" onSubmit={GuardarProducto} >

                        <div className="input-field contenedor-input  col s12">
                            <select className="browser-default" id="cat1" onChange={(e) => {

                                if (e.target.value) {
                                    setCategoria(e.target.value);
                                    setSubCateg("");
                                    setSubSubCateg("");
                                    document.getElementById("cat1").style.borderColor = "#4AC958"
                                } else {
                                    document.getElementById("cat1").style.borderColor = "red"
                                }

                            }} >
                                <option disabled selected>Elige la categoria</option>
                                <option value="1" >Electronica</option>
                                <option value="2">Material de telecomunicacion</option>
                                <option value="3" >Casas</option>
                                <option value="4" >Servicios</option>
                                <option value="5" >Coches</option>
                                <option value="6" >Vestimenta</option>
                                <option value="8" >Hogar</option>
                                <option value="7" >Terrenos</option>
                            </select>
                        </div>
                        {categoria == 1 ?
                            <div>
                                <div className="input-field contenedor-input">
                                    <select className="browser-default subcat" id="cat11" onChange={(e) => {

                                        if (e.target.value) {
                                            setSubCateg(e.target.value);
                                            document.getElementById("cat11").style.borderColor = "#4AC958"
                                        } else {
                                            setSubCateg("");
                                            document.getElementById("cat11").style.borderColor = "red"
                                        }

                                    }} >
                                        <option disabled selected>Elige la subcategorisa</option>
                                        <option value="11" >Telefonos</option>
                                        <option value="12" >Televisiones</option>
                                        <option value="13" >Ordenadores</option>
                                        <option value="14" >Material informatico</option>
                                        <option value="15" >Videojuegos</option>
                                        <option value="16" >Electrodomesticos</option>
                                        <option value="17" >Arduino</option>
                                        <option value="18" >Equipos de audiovision</option>
                                        <option value="19" >Otro</option>

                                    </select>
                                </div>
                                {subCategElectro == 11 ?
                                    <div className="input-field contenedor-input  ">
                                        <select className="browser-default" id="cat111" onChange={(e) => {

                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat111").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");
                                                document.getElementById("cat111").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Elige la marca del telefono</option>
                                            <option value="111" >Hiphone</option>
                                            <option value="112" >Huawei</option>
                                            <option value="113" >Samsung</option>
                                            <option value="114" >Nokia</option>
                                            <option value="115" >Infinix</option>
                                            <option value="116" >Sony ericson</option>
                                            <option value="117" >Alcatel</option>
                                            <option value="118" >Otros</option>
                                        </select>
                                    </div>
                                    : ""
                                }
                                {subCategElectro == 12 ?
                                    <div className="input-field contenedor-input  ">
                                        <select className="browser-default" id="cat112" onChange={(e) => {

                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat112").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");
                                                document.getElementById("cat112").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Elige la marca de la television</option>
                                            <option value="123" >Samsung</option>
                                            <option value="128" >Otros</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                                {subCategElectro == 13 ?
                                    <div className="input-field contenedor-input  ">
                                        <select className="browser-default" id="cat113" onChange={(e) => {

                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat113").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");
                                                document.getElementById("cat113").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Selecciona el modelo del ordenador</option>
                                            <option value="131" >MAC</option>
                                            <option value="132" >hp</option>
                                            <option value="133" >DELL</option>
                                            <option value="134" >Thosiba</option>
                                            <option value="135" >Otros</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                                {subCategElectro == 14 ?
                                    <div className="input-field contenedor-input  ">
                                        <select className="browser-default" id="114" onChange={(e) => {

                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat114").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");
                                                document.getElementById("cat114").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Seleciona el material informatico </option>
                                            <option value="141" >Impresora</option>

                                            <option value="142" >Otros</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                                {subCategElectro == 15 ?
                                    <div className="input-field contenedor-input  ">
                                        <select className="browser-default" id="cat115" onChange={(e) => {

                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat115").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");
                                                document.getElementById("cat115").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Elige el tipo de videojuego</option>
                                            <option value="151" >Play Station</option>
                                            <option value="152" >Xbox</option>
                                            <option value="157" >Otros</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                                {subCategElectro == 16 ?
                                    <div className="input-field contenedor-input ">
                                        <select className="browser-default" id="cat116" onChange={(e) => {
                                            if (e.target.value) {
                                                setSubSubCateg("");
                                                document.getElementById("cat116").style.borderColor = "#4AC958"
                                            } else {
                                                document.getElementById("cat116").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Selecciona tu aparato electrodomestico</option>
                                            <option value="161" >Lavadora</option>
                                            <option value="162" >Batidora</option>
                                            <option value="163" >Otro</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                            </div>
                            : ""
                        }
                        {categoria == 2 ?
                            <div className="input-field contenedor-input subcat2  " >
                                <select className="browser-default" id="cat2" onChange={(e) => {
                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                        document.getElementById("cat2").style.borderColor = "#4AC958"
                                    } else {
                                        setSubCateg("");
                                        document.getElementById("cat2").style.borderColor = "red"
                                    }

                                }
                                } >
                                    <option disabled selected>Elige tu material de telecomunicacion</option>
                                    <option value="21" >conectores rg45</option>
                                    <option value="22" >cable ethernet</option>
                                    <option value="23" >router</option>
                                    <option value="24" >antena</option>
                                    <option value="25" >modem</option>
                                    <option value="26" >switch</option>
                                    <option value="27" >otro</option>
                                </select>
                            </div>
                            : ""
                        }

                        {categoria == 8 ?
                            <div className="input-field contenedor-input subcat2  " >
                                <select className="browser-default" id="cat8" onChange={(e) => {
                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                        document.getElementById("cat8").style.borderColor = "#4AC958"
                                    } else {
                                        setSubCateg("");
                                        document.getElementById("cat8").style.borderColor = "red"
                                    }

                                }
                                } >
                                    <option disabled selected>Elige una subcategoria</option>
                                    <option value="81" >material de construccion</option>
                                    <option value="82" >material para decorrar el hogar</option>
                                    <option value="83" >otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        {categoria == 3 ?
                            <>
                                <div className="input-field contenedor-input  subcat ">
                                    <select className="browser-default" id="cat3" onChange={(e) => {

                                        if (e.target.value) {
                                            setSubCateg(e.target.value);
                                            setSubCategCasa(e.target.value)
                                            setSubSubCateg("");
                                            document.getElementById("cat3").style.borderColor = "#4AC958"
                                        } else {
                                            document.getElementById("cat3").style.borderColor = "red"
                                        }
                                    }} >
                                        <option disabled selected>Elige la subcategoria de tu casa</option>
                                        <option value="31" >Casas en alquiler</option>
                                        <option value="32" >Casas en venta</option>
                                        <option value="33" >Todo</option>
                                    </select>
                                </div>

                                {subCategCasa == "31" ?
                                    <div className="input-field contenedor-input ">
                                        <select className="browser-default" id="cat31" onChange={(e) => {
                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat31").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");

                                                document.getElementById("cat31").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Elige el numero de habitaciones</option>
                                            <option value="311" >una habitacion</option>
                                            <option value="312" >dos habitaciones</option>
                                            <option value="313" >tres habitaciones</option>
                                            <option value="314" >cuatro habitaciones</option>
                                            <option value="315" >cinco habitaciones</option>
                                            <option value="316" >mas de cinco habitaciones</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                                {subCategCasa == "32" ?
                                    <div className="input-field contenedor-input  ">
                                        <select className="browser-default" id="cat32" onChange={(e) => {
                                            if (e.target.value) {
                                                setSubSubCateg(e.target.value);
                                                document.getElementById("cat32").style.borderColor = "#4AC958"
                                            } else {
                                                setSubSubCateg("");
                                                document.getElementById("cat32").style.borderColor = "red"
                                            }
                                        }} >
                                            <option disabled selected>Elige el numero de habitaciones</option>
                                            <option value="321" >una habitacion</option>
                                            <option value="322" >dos habitaciones</option>
                                            <option value="323" >tres habitaciones</option>
                                            <option value="324" >cuatro habitaciones</option>
                                            <option value="325" >cinco habitaciones</option>
                                            <option value="326" >mas de cinco habitaciones</option>
                                        </select>
                                    </div>
                                    : ""
                                }

                            </>
                            : ""
                        }

                        {categoria == 4 ?
                            <div className="input-field contenedor-input subcat ">
                                <select className="browser-default" id="cat4" onChange={(e) => {

                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                        document.getElementById("cat4").style.borderColor = "#4AC958"
                                    } else {
                                        setSubCateg("");
                                        document.getElementById("cat4").style.borderColor = "red"
                                    }
                                }} >
                                    <option disabled selected>Elige la subcategoria de tu servicio</option>
                                    <option value="41" >Clases particulares</option>
                                    <option value="42" >servicios de construccion</option>
                                    <option value="43" >servicios informaticos</option>
                                    <option value="44" >servicios de publicidad</option>
                                    <option value="45" >servicios en el area de sanidad</option>
                                    <option value="46" >Buscar empleo</option>
                                    <option value="47" >Ofrecer empleo</option>
                                    <option value="48" >servicios de limpiesa en hogar</option>
                                    <option value="49" >servicio de mecanico de coche</option>
                                    <option value="401" >servicios de reparacion de aparatos</option>
                                    <option value="402" >servicios de fontaneria</option>
                                    <option value="403" >servicios de coches grua</option>
                                    <option value="404" >servicios de agua a domicilio</option>
                                    <option value="405" >Servicios de lavado de coches a domicilio</option>
                                    <option value="406" >servicios de instalaciones electricas</option>
                                    <option value="407" >otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        {categoria == 5 ?
                            <div className="input-field  subcat ">
                                <select className="browser-default" id="cat5" onChange={(e) => {

                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                        document.getElementById("cat5").style.borderColor = "#4AC958"
                                    } else {
                                        setSubCateg("");
                                        document.getElementById("cat5").style.borderColor = "red"
                                    }
                                }} >
                                    <option disabled selected>Elige la subcategoria de tu coche</option>
                                    <option value="51" >Alquilar coche</option>
                                    <option value="52" >Vender coche</option>
                                    <option value="53" >taxi en alquiler</option>
                                    <option value="54" >Otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        {categoria == 6 ?
                            <div className="input-field  subcat ">
                                <select className="browser-default" id="cat6" onChange={(e) => {

                                    if (e.target.value) {
                                        setSubCateg(e.target.value);
                                        document.getElementById("cat6").style.borderColor = "#4AC958"
                                    } else {
                                        setSubCateg("");
                                        document.getElementById("cat6").style.borderColor = "red"
                                    }
                                }} >
                                    <option disabled selected>Elige la subcategoria de tus articulos</option>
                                    <option value="61" >Zapatos para hombres</option>
                                    <option value="62" >Zapatos para mujeres</option>
                                    <option value="63" >Zapatos para niños</option>
                                    <option value="64" >Ropas para mujeres</option>
                                    <option value="65" >Ropas para hombres</option>
                                    <option value="66" >Ropas para niños</option>
                                    <option value="67" >Venta de pelo para mujeres</option>
                                    <option value="68" >Aguacate</option>
                                    <option value="69" >Pelucas</option>
                                    <option value="601" >Pulceras, relojes, y joyas</option>
                                    <option value="602" >otro</option>
                                </select>
                            </div>
                            : ""
                        }
                        <div className="input-field contenedor-input subcat ">
                            <select className="browser-default" id="pais" onChange={(e) => {

                                if (e.target.value) {
                                    setPais(e.target.value);
                                    document.getElementById("pais").style.borderColor = "#4AC958"
                                } else {
                                    setPais("");
                                    document.getElementById("pais").style.borderColor = "red"
                                }
                            }} >
                                <option disabled selected>Elige tu pais</option>
                                <option value="01" >Guinea Ecuatorial</option>
                                <option disabled value="02" >Cameroun</option>
                                <option disabled value="07" >Costa de Marfil</option>
                                <option disabled value="03" >Senegal</option>
                                <option disabled value="06" >Gabon</option>
                                <option disabled value="05" >Benin</option>
                                <option disabled value="08" >Centro Africa</option>
                                <option disabled value="09" >Congo Kinsaza</option>
                            </select>
                        </div>
                        {pais === "01" ?
                            <div className="input-field contenedor-input subcat ">
                                <select className="browser-default" id="ciudad" onChange={(e) => {
                                    setCiudad(e.target.value);
                                    if (e.target.value) {
                                        document.getElementById("ciudad").style.borderColor = "#4AC958"
                                    } else {
                                        document.getElementById("ciudad").style.borderColor = "red"
                                    }
                                }} >
                                    <option disabled selected>Elige la ciudad</option>
                                    <option value="011" >Malabo</option>
                                    <option value="012" >Bata</option>
                                    <option value="013" >Oyala</option>
                                    <option value="014" >Ebibeyin</option>
                                    <option value="015" >Mongomo</option>
                                    <option value="016" >Luba</option>
                                    <option value="017" >Annobon</option>
                                </select>
                            </div>
                            :
                            ""
                        }

                        {pais === "02" ?
                            <div className="input-field contenedor-input subcat color-select">
                                <select className="browser-default" onChange={(e) => {
                                    setCiudad(e.target.value)
                                }} >
                                    <option disabled selected>Elige la ciudad</option>
                                    <option value="021" >Duoila</option>
                                    <option value="022" >Bamenda</option>
                                </select>
                            </div>
                            :
                            ""
                        }

                        <div className="contenedor-de-input">
                            <div className="input-field ">

                                <textarea className=" materialize-textarea" id="localizacion" data-length="20" placeholder="" onChange={(e) => {
                                    if (e.target.value.trim().length < 21 && e.target.value.trim().length > 1) {
                                        document.getElementById("localizacion").style.borderColor = "#4AC958";
                                        setLocalizacion(e.target.value.trim());
                                    } else {
                                        document.getElementById("localizacion").style.borderColor = "red"
                                    }
                                }}></textarea>
                                <label className="active ">Localizacion (Barrio) "OPCIONAL"</label>
                            </div>
                            <div className="input-field precios">
                                <input id="nombre" data-length="9" onChange={(e) => {

                                    if (e.target.value.trim()) {
                                        document.getElementById("nombre").style.borderColor = "#4AC958";
                                        setNombreDelArticulo(e.target.value.trim());
                                    } else {
                                        setNombreDelArticulo("")
                                        document.getElementById("nombre").style.borderColor = "red"
                                    }
                                }} placeholder="" />
                                <label className="active ">Nombre o marca del producto</label>
                            </div>
                            <div className="input-field ">

                                <textarea id="descripcion" className="textareaCount  materialize-textarea" data-length="200" placeholder="" onChange={(e) => {

                                    if (e.target.value.trim()) {
                                        document.getElementById("descripcion").style.borderColor = "#4AC958";
                                        setBreveDescripcion(e.target.value.trim());
                                    } else {
                                        document.getElementById("descripcion").style.borderColor = "red"
                                    }
                                }}></textarea>
                                <label className="active ">Descripcion</label>
                            </div>
                            <div className="input-field " style={{ display: "none" }}>

                                <textarea className="textareaCount materialize-textarea" data-length="200" placeholder="" onChange={(e) => { setDescripcion(e.target.value) }}></textarea>
                                <label className="active ">Carracteristicas del producto "OPCIONAL"</label>
                            </div>
                            <div className="input-field precios">
                                <input id="precio" onChange={(e) => {
                                    if (e.target.value.trim()) {
                                        if (Number(e.target.value.trim())) {
                                            document.getElementById("precio").style.borderColor = "#4AC958";
                                            setPrecio(e.target.value.trim());
                                            setPrecioString("")
                                        } else {
                                            setPrecio("");
                                            document.getElementById("precio").style.borderColor = "#4AC958";
                                            setPrecioString(e.target.value.trim());
                                        }
                                    } else {
                                        document.getElementById("precio").style.borderColor = "red"
                                        setPrecio("");
                                        setPrecioString("");
                                    }
                                }} placeholder="" />
                                <label className="active ">Precio del articulo</label>
                            </div>

                            <div className="row">
                                <div className="input-field col s6 m6 input-numbre">
                                    <input id="input2" data-length="9" onChange={e => {

                                        if (e.target.value.trim().length === 9 && Number(e.target.value.trim())) {
                                            document.getElementById("input2").style.borderColor = "#4AC958";
                                            setNumeroW(e.target.value.trim());
                                        } else {
                                            document.getElementById("input2").style.borderColor = "red"
                                        }
                                    }} placeholder="" validate />
                                    <label className="active ">Numero whatsapp</label>
                                </div>
                                <div className="input-field col s6 m6 input-numbre">
                                    <input data-length="9" className="input-count" id="input1" onChange={e => {

                                        if (e.target.value.trim().length === 9 && Number(e.target.value.trim())) {
                                            document.getElementById("input1").style.borderColor = "#4AC958";
                                            setNumeroT(e.target.value.trim());
                                        } else {
                                            document.getElementById("input1").style.borderColor = "red"
                                        }
                                    }} placeholder="" validate />
                                    <label className="active ">Numero llamadas</label>
                                </div>
                            </div>
                            <div className="texto-opcional">
                                <p>Selecciona las imagenes de tu publicacion maximo 4 imagenes</p>
                            </div>
                            <div className="file-select btn foto-principal" id="src-file1" >
                                <input multiple className="input-count" type="file" name="src-file1" aria-label="Archivo" onChange={(e) => handleChangeImagen1(e.target.files)} />
                            </div>
                            {/* <div className="form-img-preview" style={{ display: "none" }}>
                                <div className="img-preview">
                                    <img src={preview} alt="" />
                                </div>
                            </div> */}

                        </div>
                        {/* <div className="texto-opcional" style={{ display: "none" }}>
                            <p>foto 1 , foto 2 y foto 3 son opcionales</p>
                        </div> */}
                        <div className="imagenes-elegidas">
                        {imagen1 ? <img src={preview} alt="" /> : ""}
                        {imagen2 ? <img src={preview1} alt="" /> : ""}
                        {imagen3 ? <img src={preview2} alt="" /> : ""}
                        {imagen4 ? <img src={preview3} alt="" /> : ""}
                        </div>
                        {/* <div className="form-img-preview" style={{ display: "none" }}>
                            <div className="img-preview ">
                                {false ?
                                    <div className="eliminar-imagen" >
                                        <Link onClick={() => { setPreview1(""); setImagen2("") }}><FaTimes size={30} color="#d32f2f" /></Link>
                                    </div>
                                    :
                                    <div className="file-select btn" id="src-file2" style={{display:"none"}}>
                                        <input type="file" name="src-file1" aria-label="Archivo" onChange={(e) => handleChangeImagen2(e.target.files[0])} />
                                    </div>
                                } 

                                {imagen2 ? <img src={preview1} alt="" /> : ""}




                            </div>
                            <div className="img-preview">
                                 {false ?
                                    <div className="eliminar-imagen">
                                        <Link onClick={() => { setPreview2(""); setImagen3("") }}><FaTimes size={30} color="#d32f2f" /></Link>
                                    </div>
                                    :
                                    <div className="file-select btn" id="src-file3" >
                                        <input type="file" name="src-file1" aria-label="Archivo" onChange={(e) => handleChangeImagen3(e.target.files[0])} />
                                    </div>
                                } 

                                {imagen3 ? <img src={preview2} alt="" /> : ""}

                            </div>

                            <div className="img-preview">
                                 {false ?
                                    <div className="eliminar-imagen">
                                        <Link onClick={() => { setPreview3(""); setImagen4("") }}><FaTimes size={30} color="#d32f2f" /></Link>
                                    </div>
                                    :
                                    <div className="file-select btn" id="src-file4" >
                                        <input type="file" name="src-file1" aria-label="Archivo" onChange={(e) => handleChangeImagen4(e.target.files[0])} />
                                    </div>
                                } 

                                {imagen4 ? <img src={preview3} alt="" /> : ""}

                            </div>
                        </div> */}
                        {probar ?
                            <div className="contenedor-spinner">
                                <div>
                                    <p>agregando..</p>
                                </div>
                                <div className="spinners">
                                    <MoonLoader color={"blue"} loading={true} size={30} />
                                    { /*                               <Loader
                                        type="TailSpin"
                                        color="#0d47a1"
                                        height={30}
                                        width={30}

                        />*/}
                                </div>
                            </div>
                            : ""
                        }
                        {ValidarFormulario() ?
                            <div className="input-field input-submit-btn ">
                                <button type="submit" className="btn">PUBLICAR PRODUCTO</button>
                            </div>
                            : ""
                        }
                    </form>
                </div>
            </section>
            <div className='imgPubSinoLoUsas'>
                <div className='imgPubSinoLoUsasResp'>
                    {/*<img src={sino} />*/}
                    <LazyLoadImage
                        effect="blur"
                        src={"https://res.cloudinary.com/mumbex/image/upload/v1664622419/sino_pxlmqb.jpg"}
                    />
                </div>
            </div>
            <MenuAbajo />
        </div>
    )
}

export default ForProducto