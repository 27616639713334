import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./añadirImagenesAgencia.css"
import { FiCamera } from 'react-icons/fi'
import { SERVER_URL } from '../../contexts/constantesVar'
import axios from 'axios'
import { PulseLoader } from 'react-spinners'
import M from 'materialize-css'

function AñadirImagenesAgencia({ codeVery, user_id }) {
    const [imagen1, setImagen1] = useState('')
    const [imagen2, setImagen2] = useState('')
    const [imagen3, setImagen3] = useState('')
    const [imagen4, setImagen4] = useState('')
    const [uploadSpinner ,setUploadSpinner] = useState(false)
    const [preview1, setPreview1] = useState("");
    const [preview2, setPreview2] = useState("");
    const [preview3, setPreview3] = useState("");
    const [preview4, setPreview4] = useState("");


    const SubirImagenes = async () => {
        

        if (imagen1 || imagen2 || imagen3 || imagen4 ) {
            setUploadSpinner(true)
            try {
                const fs = new FormData()
                if (imagen1) {
                    fs.append("imagen1", imagen1)
                }
                if (imagen2) {
                    fs.append("imagen2", imagen2)
                }
                if (imagen3) {
                    fs.append("imagen3", imagen3)
                }
                if (imagen4) {
                    fs.append("imagen4", imagen4)
                }
                fs.append("codeVery", codeVery)
                fs.append("user_id", user_id)
                const datos = await axios.post(`${SERVER_URL}/subir-imagenes-user-agencia`, fs, { headers: { "Content-Type": "multipart/form-data" } })
                console.log(datos)
                if (datos.data) {
                    setUploadSpinner(false)
                    var toastHTML = '<span>' + "Publicado con exito" + '</span>';
                    setImagen1(null)
                    setImagen2(null)
                    setImagen3(null)
                    setImagen4(null)
                    setPreview1(null)
                    setPreview2(null)
                    setPreview3(null)
                    setPreview3(null)
                    M.toast({ html: toastHTML });
                } else {
                    setUploadSpinner(false)
                    var toastHTML = '<span>' + "A habido un problema" + '</span>';
                    M.toast({ html: toastHTML });
                }

            } catch (error) {
                setUploadSpinner(false)
                var toastHTML = '<span>' + "Hay un problema" + '</span>';
                M.toast({ html: toastHTML });
            }
        }else{
            var toastHTML = '<span>' + "Tienes que elegir como minimo una imagen" + '</span>';
            M.toast({ html: toastHTML });
        }




    }


    const ObtenerImagenesInput = (e) => {
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            setImagen1(e.target.files[0])
            //console.log(e.target.files[0], "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq")
            PrevisualizarImage1(e.target.files[0])


        } else {
            setImagen1(null)
        }
        if (e.target.files[1]) {
            setImagen2(e.target.files[1])
            PrevisualizarImage2(e.target.files[1])
        } else {
            setImagen2(null)
        }
        if (e.target.files[2]) {
            setImagen3(e.target.files[3])
            PrevisualizarImage3(e.target.files[2])
        } else {
            setImagen3(null)
        }
        if (e.target.files[3]) {
            setImagen4(e.target.files[3])
            PrevisualizarImage4(e.target.files[3])
        } else {
            setImagen4(null)
        }
    }

    const PrevisualizarImage1 = (e) => {
        if (e) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview1(reader.result);
                console.log(reader.result)

            }
            reader.readAsDataURL(e)
        } else {
            setPreview1(null)
        }
    }
    const PrevisualizarImage2 = (e) => {
        if (e) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview2(reader.result);

            }
            reader.readAsDataURL(e)
        } else {
            setPreview2(null)
        }
    }

    const PrevisualizarImage3 = (e) => {
        if (e) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview3(reader.result);

            }
            reader.readAsDataURL(e)
        } else {
            setPreview3(null)
        }
    }
    const PrevisualizarImage4 = (e) => {
        if (e) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview4(reader.result);

            }
            reader.readAsDataURL(e)
        } else {
            setPreview4(null)
        }
    }
    useEffect(() => {
        PrevisualizarImage1()

    }, [])
    return (
        <div className='container-subir-imagenes-agencia'>
            <div className='container-subir-imagenes-agencia-resp'>
                <div className='container-text-indicar-seleccionar-fotos'>
                    <blockquote>
                        <p className='flow-text'>A continuacion puedes publicar las fotos de trabajos ya realizados por tu agencia para dar mayor seguridad y confiensa a los clientes</p>
                    </blockquote>
                </div>
                <div className='subir-imagenes-agencia-lista'>
                    {imagen1 ?
                        <img src={preview1} />
                        :
                        <></>
                    }
                    {imagen2 ?
                        <img src={preview2} />
                        :
                        <></>
                    }
                    {imagen3 ?
                        <img src={preview3} />
                        :
                        <></>
                    }
                    {imagen4 ?
                        <img src={preview4} />
                        :
                        <></>
                    }
                </div>
                <div className='conatainer-seleccionar-imagenes-input'>
                    <div className='selecionar-imagenes-agencia'>
                        <Link id='seleccionar' onClick={() => document.getElementById("input-imagenes-agencia").click()}>
                            <FiCamera color='darkgray' size={40} />
                            <p>Selecciona imagenes. maximo 4</p>
                        </Link>
                    </div>
                    <input type="file" multiple style={{ display: "none" }} id='input-imagenes-agencia' onChange={(e) => { ObtenerImagenesInput(e) }} />
                </div>
                < div className='conatainer-boton-subir-imagenes-agencia'>
                    <button
                        onClick={() => { 
                            if (imagen1) {
                                SubirImagenes()
                            } else {
                                
                            }
                            
                         }}
                        className='boton-subir-imagenes-agencia btn-small'
                    >{uploadSpinner ? <span><PulseLoader className="uploadSpinner" color={"#fff"} loading={true} size={15} /></span> : "iniciar !"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AñadirImagenesAgencia