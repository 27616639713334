import React, { useState, useEffect, useContext } from 'react';
import { } from "react-router-dom"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BarradeNavegacion from '../components/navegacionBarAll/BarradeNavegacion';
import MenuAbajo from '../components/menuAbajo';
import Footer from '../components/Footer';
import MenuIconos from '../components/menuIconosHome/menuIconos';
import FloatingButton from '../components/floatingButton';
import CardFotos from '../components/cardsFotosArticulos/cardFotos';
import CarouseHome from '../components/slickCarousHome/carousHome';
import HorizontalScrollHome from '../components/horizontalScroll/horizontalScroll';
import AppContext from '../services/contextApp';
import axios from 'axios';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css"
import CarrouselServicios from '../components/carroselServicios/carrouselServicios';
import VerdInfoProducto from '../components/verProduct/verdInfoProducto';
import { SERVER_URL, USER_ID, USER_NAME, VALIDAR_USER } from '../contexts/constantesVar';
import M from 'materialize-css';
import CarrouselHome from '../components/carrouselHome/carrouselHome';




const Home = (props) => {
    const { dispatch } = useContext(AppContext)
    const code = window.localStorage.getItem("code")
    const [probar, setProbar] = useState(true)

    const UserConfirm = async () => {

        const datos = await axios({
            method: 'post',
            data: {
                'id': code,
            },
            url: `${SERVER_URL}/user_confirm_init`
        })


        if (datos.data) {
            dispatch({
                type: VALIDAR_USER,
                payload: true
            })
            dispatch({
                type: USER_ID,
                payload: datos.data.id
            })
            dispatch({
                type: USER_NAME,
                payload: datos.data.user
            })
            window.localStorage.setItem("code", datos.data.code)
        } else {
            dispatch({
                type: VALIDAR_USER,
                payload: false
            })
            dispatch({
                type: USER_ID,
                payload: ""
            })
            dispatch({
                type: USER_NAME,
                payload: ""
            })
        }

    }



    useEffect(() => {

        //UserConfirm()
        const alls = document.querySelector(".modal-info-product")
        M.Modal.init(alls, {
            preventScrolling: false,
            inDuration: 50,
            outDuration: 50,
        })

    }, [])

    return (
        <div >
            <BarradeNavegacion />
            {/** <CarouseHome /> */}
            <MenuIconos />
            <HorizontalScrollHome />

            {/* imagen de publicidad */}
            {/*
                <div className='imgPubSinoLoUsas'>
                    <div className='imgPubSinoLoUsasResp'>

                        <img src={"https://res.cloudinary.com/mumbex/image/upload/v1667220434/aq_zvbg1i.jpg"} />


                    </div>
                </div>
    */}
            {/* fin del  imagen de publicidad */}

            {/*<CarrouselServicios />*/}

            <CardFotos />
            <VerdInfoProducto />
            <MenuAbajo />
            <FloatingButton />
        </div>
    )

}




export default Home;