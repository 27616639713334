import React, { useReducer, useState } from "react"
import AppContext from "../services/contextApp";
import { InitialState } from './initialState';
import AppReducer from "./appReducer";
import "./appReducer";
import axios from "axios";
import { ArticulosSingleDB } from "../components/callDataCatePage";
import { PaginationInfiniScroll } from "../components/paginacionScroll";
import { BuscarConLink } from "../components/navegacionBarCate/busacrConLink";
import { BuscarForm } from "../components/navegacionBarCate/buscarForm";
import {
    SPINNER_CATEGORIA, SPINNER_ALGO_VA_MAL,
    SPINNER_CARGAR_ART_CAT, ACTIVAR_BUSQUEDA, CATEGORIA, SUB_CATEGORIA,
    ESCONDER_SUB_CATEGORIAS, LOGIN_SPINNER, REGISTER_SPINNER, ERROR_USER,
    RESP_ERROR_LOGIN, VALIDAR_USER, USER_NAME, USER_ID, PAIS_POST, CIUDAD_POST, ALGO_VA_MAL_HOME, SPINNER_HOME, PROBAR_SI_HAY, SERVER_URL
} from "./constantesVar";



export default (props) => {
    const [state, dispatch] = useReducer(AppReducer, InitialState);
    const [articulosCatScreen, setArticulosCatScreen] = useState([])
    const [articlosDeHome, setArticlosDeHome] = useState([])
    const [paginaSiguiente, setPaginaSiguiente] = useState([])
    const [verificarDataHome, setVerificarDataHome] = useState([])
    const [datosArticulos, setDatosArticulos] = useState([])


    /********CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */
    const CallDataCat = (id, dispatch, pais_post, ciudad_post) => {
        ArticulosSingleDB(id, dispatch, pais_post, ciudad_post)
    }

    /********FIN DE CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */

    /********CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */
    const PaginacionConScroll = (catParaPage, datosArticulos, dispatch, categoria, paginaSiguiente, pais_post, ciudad_post, precio1, precio2) => {
        console.log("ssssssssssssszzzzzz")
        PaginationInfiniScroll(catParaPage, datosArticulos, dispatch, categoria, paginaSiguiente, pais_post, ciudad_post, precio1, precio2)
    }

    /********FIN DE CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */


    /********CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */
    const BuscarConUnLink = (claveLink, dispatch, pais_post, ciudad_post) => {
        BuscarConLink(claveLink, dispatch, pais_post, ciudad_post)
    }

    /********FIN DE CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */


    /********CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */
    const BuscarConFormInput = (texto, dispatch, pais_post) => {
        BuscarForm(texto, dispatch, pais_post)
    }

    /********FIN DE CARGAR ARTICULOS DESPUES DE CARGAR LA PAGINA DE CATEGORIAS ********************* */

    /**** LOGIN PARA INICIO DE SESION */
    const Logins = async (navigate, email, contrasena) => {

        if (email !== "" && contrasena !== "") {
            //console.log(email, contrasena)

            dispatch({
                type: LOGIN_SPINNER,
                payload: true
            })

            dispatch({
                type: ERROR_USER,
                payload: false
            })
            dispatch({
                type: RESP_ERROR_LOGIN,
                payload: ""
            })


            try {
                const user = await axios({
                    method: "post",
                    data: { "email": email, "contrasena": contrasena },
                    url: `${SERVER_URL}/login_post`
                })
                //console.log(user.data)
                if (user.data.code) {
                    //window.sessionStorage.setItem("userInfo",user.data)
                    //console.log(user.data, "gggggggggggg")
                    dispatch({
                        type: LOGIN_SPINNER,
                        payload: false
                    })
                    dispatch({
                        type: ERROR_USER,
                        payload: true
                    })
                    dispatch({
                        type: RESP_ERROR_LOGIN,
                        payload: user.data.mens
                    })
                    dispatch({
                        type: VALIDAR_USER,
                        payload: true
                    })
                    window.localStorage.setItem("code", user.data.code)
                    //const userData = await user.data.user
                    //const keys = await user.data.clave

                    dispatch({
                        type: USER_NAME,
                        payload: user.data.user
                    })
                    dispatch({
                        type: USER_ID,
                        payload: user.data.id
                    })
                    navigate(`/profil/${user.data.id}`)
                    //console.log(user.data)
                } else {
                    dispatch({
                        type: LOGIN_SPINNER,
                        payload: false
                    })
                    dispatch({
                        type: ERROR_USER,
                        payload: true
                    })
                    dispatch({
                        type: RESP_ERROR_LOGIN,
                        payload: user.data.mens
                    })

                    dispatch({
                        type: VALIDAR_USER,
                        payload: false
                    })
                    dispatch({
                        type: USER_NAME,
                        payload: ""
                    })
                    dispatch({
                        type: USER_ID,
                        payload: ""
                    })

                }

            } catch (error) {
                //console.log(error)
                dispatch({
                    type: LOGIN_SPINNER,
                    payload: false
                })
                dispatch({
                    type: ERROR_USER,
                    payload: true
                })
                dispatch({
                    type: RESP_ERROR_LOGIN,
                    payload: "Comprueba tu coneccion a internet"
                })
            }
        } else {
            dispatch({
                type: ERROR_USER,
                payload: true
            })
            dispatch({
                type: RESP_ERROR_LOGIN,
                payload: "Todos los campos son importantes"
            })
        }

    }
    /**************************************** */

    const RegistroPersonal = async (email, contrasena, nombre, selectForm,contact) => {
        if (email !== "" && contrasena !== "" && nombre !== "" && contact !== "") {
            //console.log(email, contrasena, nombre, paiz, genero)

            dispatch({
                type: LOGIN_SPINNER,
                payload: true
            })
            dispatch({
                type: ERROR_USER,
                payload: false
            })
            dispatch({
                type: RESP_ERROR_LOGIN,
                payload: ""
            })

            try {
                const user = await axios({
                    method: "post",
                    data: {
                        "email": email,
                        "contrasena": contrasena,
                        "nombre": nombre,
                        "selectForm": '0101',
                        "contact":contact
                    },
                    url: `${SERVER_URL}/registro_post`
                })
                if (user.data) {
                    console.log(user.data)
                    dispatch({
                        type: LOGIN_SPINNER,
                        payload: false
                    })
                    dispatch({
                        type: ERROR_USER,
                        payload: true
                    })
                    dispatch({
                        type: RESP_ERROR_LOGIN,
                        payload: user.data
                    })
                    dispatch({
                        type: VALIDAR_USER,
                        payload: false
                    })
                    //histo.push("/profil")
                } else {
                    dispatch({
                        type: LOGIN_SPINNER,
                        payload: false
                    })
                    dispatch({
                        type: ERROR_USER,
                        payload: true
                    })
                    dispatch({
                        type: RESP_ERROR_LOGIN,
                        payload: user.data
                    })
                }

            } catch (error) {
                dispatch({
                    type: LOGIN_SPINNER,
                    payload: false
                })
                dispatch({
                    type: ERROR_USER,
                    payload: true
                })
                dispatch({
                    type: RESP_ERROR_LOGIN,
                    payload: "Comprueba tu coneccion a internet"
                })
            }
        } else {
            dispatch({
                type: ERROR_USER,
                payload: true
            })
            dispatch({
                type: RESP_ERROR_LOGIN,
                payload: "Todos los campos deben ser rellenados"
            })
        }
    }

    /************************** */

    /**** LOGIN PARA REGISTRO DE USUARIOS */
    const Registers = async (emailAgenceTienda, passwordAgenceTienda, nombreAgenceTienda, breveDescripcionTiendaCorta, paisTienda, phoneTienda, ciudadTienda, selectForm,imgLogo) => {
        const verificar = emailAgenceTienda && passwordAgenceTienda && nombreAgenceTienda && breveDescripcionTiendaCorta && paisTienda && phoneTienda && ciudadTienda && selectForm && imgLogo
        console.log(selectForm)

        if (verificar) {
            dispatch({
                type: LOGIN_SPINNER,
                payload: true
            })
            dispatch({
                type: ERROR_USER,
                payload: false
            })
            dispatch({
                type: RESP_ERROR_LOGIN,
                payload: ""
            })

            try {
                const fs = new FormData()
                fs.append("email", emailAgenceTienda)
                fs.append("password", passwordAgenceTienda)
                fs.append("usuario", nombreAgenceTienda)
                fs.append("descripcionAgence", breveDescripcionTiendaCorta)
                fs.append("numeroT", phoneTienda)
                fs.append("numeroW", phoneTienda)
                fs.append("paisTienda", paisTienda)
                fs.append("phoneTienda", phoneTienda)
                fs.append("ciudadTienda", ciudadTienda)
                fs.append("selectForm", selectForm)
                fs.append("imagen1", imgLogo)
                const user = await axios.post(`${SERVER_URL}/registro_post`, fs, { headers: { "Content-Type": "multipart/form-data" } })
                if (user.data) {
                    //console.log(user.data)
                    dispatch({
                        type: LOGIN_SPINNER,
                        payload: false
                    })
                    dispatch({
                        type: ERROR_USER,
                        payload: true
                    })
                    dispatch({
                        type: RESP_ERROR_LOGIN,
                        payload: user.data
                    })
                    dispatch({
                        type: VALIDAR_USER,
                        payload: false
                    })
                    //histo.push("/profil")
                } else {
                    dispatch({
                        type: LOGIN_SPINNER,
                        payload: false
                    })
                    dispatch({
                        type: ERROR_USER,
                        payload: true
                    })
                    dispatch({
                        type: RESP_ERROR_LOGIN,
                        payload: user.data
                    })
                }

            } catch (error) {
                console.log(error)
                dispatch({
                    type: LOGIN_SPINNER,
                    payload: false
                })
                dispatch({
                    type: ERROR_USER,
                    payload: true
                })
                dispatch({
                    type: RESP_ERROR_LOGIN,
                    payload: "Comprueba tu coneccion a internet"
                })
            }
        } else {
            dispatch({
                type: ERROR_USER,
                payload: true
            })
            dispatch({
                type: RESP_ERROR_LOGIN,
                payload: "Todos los campos deben ser rellenados"
            })
        }

    }
    /************************** */

    const ArticoloHome = async (paiss, ciudadd) => {
        dispatch({
            type: SPINNER_HOME,
            payload: false
        })

        dispatch({
            type: ALGO_VA_MAL_HOME,
            payload: false
        })
        dispatch({
            type: PAIS_POST,
            payload: paiss
        })
        dispatch({
            type: CIUDAD_POST,
            payload: ciudadd
        })
        //setArticlosDeHome([])
        try {
            const datos = await axios({
                method: "post", data: { "pais": paiss, "ciudad": "" },
                url: `${SERVER_URL}/home_article`
            })

            console.log(datos.data, "hommmmmeeeeeee", datos.data.docs[0].categoria, "wwwwwwwwwwwwwwwwwwwwwwww")
            if (datos.data.docs[0]) {
                //console.log(datos.data.docs)
                const valor = datos.data.docs[0]

                if (datos.data.docs === verificarDataHome) {
                    //console.log("qqqqqqqqqqqqqqqqqqqqqq")
                } else {
                    setArticlosDeHome(datos.data.docs)
                    setVerificarDataHome(datos.data.docs)
                    setPaginaSiguiente(datos.data.nextPage)
                    //console.log("dddddddddddddddaaaaaaaaaaaaaaaaaa")
                }

                /*
                dispatch({
                    type: CATEGORIA,
                    payload: datos.data.docs[0].categoria
                })
                dispatch({
                    type: SUB_CATEGORIA,
                    payload: datos.data.docs[0].subcategoria
                })*/
                dispatch({
                    type: SPINNER_HOME,
                    payload: true
                })
            } else {
                setArticlosDeHome([])

                dispatch({
                    type: SPINNER_HOME,
                    payload: true
                })
            }
        } catch (error) {

            dispatch({
                type: ALGO_VA_MAL_HOME,
                payload: true
            })

            dispatch({
                type: SPINNER_HOME,
                payload: true
            })
            //console.log("sin conneccion")

        }
    }

    return (
        <AppContext.Provider value={{
            categoria: state.categoria,
            subCategoria: state.subCategoria,
            dataHome: state.dataHome,
            dataCatego: state.dataCatego,
            spinnerDataHome: state.spinnerDataHome,
            spinnerDataCategoria: state.spinnerDataCategoria,
            spinnerDataProduct: state.spinnerDataProduct,
            algoVamal: state.algoVamal,
            activarBusqueda: state.activarBusqueda,
            loginSpinner: state.loginSpinner,
            registerSpinner: state.registerSpinner,
            userUndefined: state.userUndefined,

            probarSiHay: state.probarSiHay,

            respServerUserUndefined: state.respServerUserUndefined,
            spinnerAddProduct: state.spinnerAddProduct,
            validar_user: state.validar_user,
            user_id: state.user_id,
            user_name: state.user_name,
            pais_post: state.pais_post,
            ciudad_post: state.ciudad_post,
            algoSaliMalHome: state.algoSaliMalHome,
            spinnerDatosHome: state.spinnerDatosHome,
            isAuthentificated: false,

            catParaPage: state.catParaPage,

            dataCategoria: state.dataCategoria,

            buscar: state.buscar,

            paginaSiguiente: state.paginaSiguiente,

            articlosDeHome: articlosDeHome,
            precio1: state.precio1,
            precio2: state.precio2,
            busquedaClave: state.busquedaClave,
            articulosCatScreen: articulosCatScreen,
            verificarDataHome: state.verificarDataHome,
            arrayDataHome: state.arrayDataHome,
            siguientePaginaNumero: state.siguientePaginaNumero,
            array_servicios: state.array_servicios,
            info_product:state.info_product,
            agenciasTiendas:state.agenciasTiendas,
            noMoreVerCat:state.noMoreVerCat,
            setIsAuthentificated: value => { },
            Logins,
            Registers,
            ArticoloHome,
            dispatch,
            CallDataCat,
            PaginacionConScroll,
            BuscarConUnLink,
            BuscarConFormInput,
            RegistroPersonal,


        }}>
            {props.children}
        </AppContext.Provider>
    )
};

