import axios from 'axios'
import React, { useEffect, useContext, useState } from 'react'
import { IoMdTime } from 'react-icons/io'
import { Link, useParams } from 'react-router-dom'
import BannerProfilAgence from '../components/bannerProfilAgence/bannerProfilAgence'
import FloatingButton from '../components/floatingButton'
import InfoAgence from '../components/infoAgence/infoAgence'
import MenuAbajo from '../components/menuAbajo'
import BarradeNavegacion from '../components/navegacionBarAll/BarradeNavegacion'
import { format, register } from 'timeago.js';
import M from 'materialize-css'
import "../styles/imagenesAgencias.css"
import TextProductosProfilAgencia from '../components/textProductosProfilAgencia/textProductosProfilAgencia'

import { INFO_PRODUCT, SERVER_URL } from '../contexts/constantesVar'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { MoonLoader } from 'react-spinners'
import BannerAgenciaTienda from '../components/bannerAgenciasTiendas/bannerAgenciaTienda'
import AgenciasDescripcion from '../components/agenciasDescrip/agenciasDescripcion'
import VerdInfoProducto from '../components/verProduct/verdInfoProducto'
import AppContext from '../services/contextApp'

function VerAgencias() {
    const { dispatch } = useContext(AppContext)
    const { id } = useParams()
    const [agenciasTiendas, setAgenciasTiendas] = useState([])
    const [imagenesAgencias, setImagenesAgencias] = useState([])
    const [datosArticulos, setDatosArticulos] = useState([])
    const [probar, setProbar] = useState(true)
    const [spinnerTraerImagenes, setSpinnerTraerImagenes] = useState(false)
    const [spinnerTraerAgencias, setSpinnerTraerAgencias] = useState(false)



    register('es_ES', (number, index, total_sec) => [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 dia', 'en 1 dia'],
        ['hace %s dias', 'en %s dias'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['hace 1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años']
    ][index]);

    const timeago = timestamp => format(timestamp, 'es_ES')


    //FUNCION QUE PERMITE TRAER LA AGENCIA O TIENDA SELECCIONADA
    const TraerAgenciasVentaTiendas = async () => {
        setSpinnerTraerAgencias(true)
        try {
            const datos = await axios({ method: "post", data: {}, url: `${SERVER_URL}/obtener_tienda_agencia_venta_simple/${id}` })
            //console.log(datos)
            if (datos.data.docs) {
                setSpinnerTraerAgencias(false)
                setAgenciasTiendas(datos.data.docs)
            } else {
                setSpinnerTraerAgencias(false)
            }
        } catch (error) {
            setSpinnerTraerAgencias(false)
        }

    }

    //FUNCION QUE PERMITE TRAER IMAGENES DE AGENCIAS DE SERVICIOS
    const TraerImagenesAgencia = async () => {
        setSpinnerTraerImagenes(true)
        try {
            const datos = await axios({ method: "post", data: {}, url: `${SERVER_URL}/obtener-imagenes-user-agencia/${id}` })

            if (datos.data.docs) {
                //console.log(datos, "imagenessssss")
                setImagenesAgencias(datos.data.docs)
                setSpinnerTraerImagenes(false)
            } else {
                setSpinnerTraerImagenes(false)
            }
        } catch (error) {
            setSpinnerTraerImagenes(false)
        }

    }


    //FUNCION QUE PERMITE TRAER LOS ARTICULOS DE TIENDA O AGENCIAS DE GESTION DE COMPRA-VENTA
    const ArticulosSingleDB = async () => {
        //console.log(id)
        setProbar(false)
        try {
            const datos = await axios({
                method: 'post',
                url: `${SERVER_URL}/articulos_user/${id}`
            })

            if (datos.data) {
                setProbar(true)
                setDatosArticulos(datos.data.docs)
                //console.log(datos.data.docs, "qqqqqqqqqqqqqqqqqqqqqqqqqq")
            }

        } catch {
            setProbar(true)
            var toastHTML = '<span>' + "hay un problema intenta de nuevo" + '</span>';
            M.toast({ html: toastHTML });
        }
    }


    useEffect(() => {
        TraerAgenciasVentaTiendas()
        ArticulosSingleDB()
        TraerImagenesAgencia()
    }, [])
    return (
        <div className='container-global-agencias-tiendas' style={{display:"none"}}>
            <BarradeNavegacion />
            {!spinnerTraerAgencias ?
                <>
                    {agenciasTiendas[0] ?
                        <>
                            <BannerAgenciaTienda imgAgencia={agenciasTiendas[0]["imgLogo"]} />
                            <AgenciasDescripcion
                                descripcion={agenciasTiendas[0]["descripcionAgence"]}
                                nombre={agenciasTiendas[0]["nombre"]}
                                numeroT={agenciasTiendas[0]["numeroT"]}
                                numeroW={agenciasTiendas[0]["numeroW"]}
                                servicios={agenciasTiendas[0]["servicios"]}
                                categoria={agenciasTiendas[0]["categoria"]}
                            />
                        </>
                        :
                        <></>
                    }


                    {agenciasTiendas[0] ?
                        <>
                            <>
                                <TextProductosProfilAgencia texto={""} />

                                <div className="displayCardBigScreen">
                                    <div className='cardVerticalContainer '>
                                        <div className="cardVerticalContainerResp">
                                            {probar ?
                                                <>
                                                    {datosArticulos.map((x, y) =>
                                                        <Link to={/*`/vercatategoria/verProducto/${x["_id"]}`*/"#!"}
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: INFO_PRODUCT,
                                                                    payload: [x]
                                                                })
                                                            }}
                                                            className="cardVertical modal-trigger"
                                                            data-target="modalInfoProduct"
                                                            key={x["_id"]}
                                                        >
                                                            <div className='cardVerticalImg'>
                                                                {<img src={x.imagen1} />}
                                                                {/* <img src ="https://mumbexserver.tech/img/1-928571e3-e98f-40bc-88f6-15fbd6a37094.jpg"/> */}
                                                            </div>
                                                            <div className='cardVerticalProductName'>
                                                                <h6>{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</h6>
                                                            </div>
                                                            <div className='cardVerticalProductDescripcion' style={{ display: "none" }}>
                                                                <h6>{x.brevedescripcion}...</h6>
                                                            </div>
                                                            <div className='cardVerticalProductPrecio'>
                                                                {x.precio ?
                                                                    <h6>{x.precio.toLocaleString("es-GQ")} xfa</h6>
                                                                    :
                                                                    <h6>{x.precioString}</h6>
                                                                }
                                                            </div>
                                                            <div className='cardVerticalProductTiempo'>
                                                                <IoMdTime size={20} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                            </div>
                                                        </Link>
                                                    )}

                                                </>
                                                :

                                                <></>

                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="displayCardMobil">
                                    <div className='nuevos-productos'>
                                        <div className="respon-nuevos-productos ">

                                            <div className="fila-de-targetas-horizontal ">
                                                {probar ?
                                                    <>
                                                        {datosArticulos.map((x, y) =>
                                                            <Link to={/*`/vercatategoria/verProducto/${x["_id"]}`*/"#!"}
                                                                onClick={() => {
                                                                    dispatch({
                                                                        type: INFO_PRODUCT,
                                                                        payload: [x]
                                                                    })
                                                                }}
                                                                className="cardCategoriaHorizotalContainer modal-trigger"
                                                                data-target="modalInfoProduct"
                                                                key={x["_id"]}
                                                            >
                                                                <div className="cardCategoriaHorizotal">
                                                                    <div className="cardHorizotalfoto">
                                                                        <LazyLoadImage
                                                                            effect="blur"
                                                                            src={x["imagen1"]}
                                                                        />
                                                                    </div>
                                                                    <div className="cardHorizotalText">
                                                                        <div className="cardHorizotalDescription">
                                                                            <p className="cardHorizotalDescriptionText">{x.nombre ? x.nombre.slice(0, 47) + "..." : ""}</p>
                                                                            {x.precio ?
                                                                                <p className="cardHorizotalprecio">{x["precio"].toLocaleString("es-GQ")} xfa</p>
                                                                                :
                                                                                <p className="cardHorizotalprecio">{x["precioString"]}</p>

                                                                            }
                                                                            <div className='cardHorizontalProductTiempo'>
                                                                                <IoMdTime size={18} className='iconRelog' /><h6><span>{timeago(`${x["fecha"]}`)}</span></h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </Link>
                                                        )}

                                                    </>
                                                    :

                                                    <></>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        </>
                        :
                        <></>
                    }
                </>
                :
                <>
                    <div className="spinner-cat">
                        <MoonLoader color={"blue"} loading={true} size={40} />
                        <p style={{ color: "blue" }}>cargando datos...</p>
                        <div className="spinner-home-altura"></div>
                    </div>
                </>
            }
            <VerdInfoProducto />
            <FloatingButton />
            <MenuAbajo />
        </div>
    )
}


export default VerAgencias