import React from 'react';
import { Link } from 'react-router-dom';






const Footer = () =>{
  return(
        <section className ="contenedor-foorter" >
          <footer className ="page-footer">
              <div className ="redes-sociles-footer">
                  <div className ="barra-de-redes-sociales-footer">
                    <div className  = "contenedor-de-sociales-footer">
                      <Link className ="btn-floating btn-social-footer btn-smal waves-effect waves-light  "><i class="icon-whatsapp"></i></Link>
                      <Link className ="btn-floating btn-social-footer btn-smal waves-effect waves-light "><i class="icon-facebook"></i></Link>
                      <Link className ="btn-floating btn-social-footer btn-smal waves-effect waves-light "><i class="icon-twitter"></i></Link>
                      <Link  to ="" target="blank" ><span class="separacion-footer">||</span></Link>
                      <Link className ="btn-floating btn-social-footer btn-smal waves-effect waves-light"><i class="icon-phone"></i></Link>
                      <Link className ="btn-floating btn-social-footer btn-smal waves-effect waves-light"><i class="icon-envelop"></i></Link>
                      <Link className ="btn-floating btn-social-footer btn-smal waves-effect waves-light"><i class="icon-location2"></i></Link>
                    </div>
                  </div>
              </div>
              <div className ="informacion-footer-1">
                  <Link to ="#"><span>contactar soporte</span></Link>
                  <span className ="barra-de-separacion-footer">|</span>				
                  <Link to ="#"><span>ayuda</span></Link>
                  <span className ="barra-de-separacion-footer">|</span>
                  <Link to ="#"><span>servicios</span></Link>
                  <span className ="barra-de-separacion-footer">|</span>	
                  <Link to ="#"><span>Hola EG</span></Link>
              </div>
              <div className ="informacion-footer-2">
                  <p>copyright @ 2020</p>
              </div>
            </footer>
        </section>
  )
}

export default Footer ;